import React from 'react'
import notEligible from 'assets/events/icons/notEligible.png'


function Index({ description, handleClose }) {
    console.log('description', description);

    return (
        <div className='relative flex flex-col items-center justify-center bg-white rounded-md shadow-md text-black px-5'>
            <button
                className='absolute top-2 right-2 cursor-pointer text-black rounded-full px-2 py-1'
                onClick={handleClose}
            >
                X
            </button>
            <img src={notEligible} alt="Not Eligible" className='mt-6' />
            <h1 className='mt-8 mb-10 font-semibold text-lg'>Oops!! You're not Eligible</h1>
            <div className="px-5 mb-10 text-subText">{description}</div>
        </div>

    )
}

export default Index