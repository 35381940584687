import { lazy, Suspense, useCallback, useEffect, useState } from 'react'
import { ConnectionRequestReceivedCard, ConnectionRequestSendCard } from 'components/Cards/Connection'
import avatar from "../../../assets/profileEdit/basic/avaatar.png"
import { acceptConnectionRequest, getAllReceivedRequest, getAllSendRequest, rejectConnectionRequest, removeSendConnection } from '../../../apis/connections'
import { CONNECTIONS } from 'shared/router'
import { encodeToBase64 } from 'utils/encodeDecode'
import { useNavigate } from 'react-router-dom'
import { ConnectionTab } from 'components/NavbarAndTabs'
const NoConnectionsFound = lazy(() => import("components/Cards/emptyCards").then(module => ({ default: module.NoConnectionsFound })))

const LocationError = lazy(() => import("components/popUps").then(module => ({ default: module.LocationTurnoff })))
const LimitReached = lazy(() => import("components/popUps").then(module => ({ default: module.LimitReached })))
const InsufficientCode = lazy(() => import("components/popUps").then(module => ({ default: module.InsufficientCoins })))
const NoAccess = lazy(() => import("components/popUps").then(module => ({ default: module.NodataAccess })))
const SomethingWentWrong = lazy(() => import("components/popUps").then(module => ({ default: module.SomethingWentWrong })))

function Index() {

    const navigate = useNavigate()

    const [requestState, setRequestState] = useState('send');
    const [connectionData, setConnectionData] = useState([]);
    const [errorType, setErrorType] = useState(null);

    const fetchData = useCallback(async (type) => {
        const response = type === 'send' ? await getAllSendRequest() : await getAllReceivedRequest();
        setConnectionData(response?.data);
    }, []);

    useEffect(() => {
        fetchData(requestState);
    }, [fetchData, requestState]);

    const handleChangeRequestState = (value) => {
        setRequestState(value);
        fetchData(value);
    };

    const ErrorComponent = errorType === 'Please update your location to continue' ? LocationError
        : (errorType === 'DAILY_CONNECTION_LIMIT' ? LimitReached
            : (errorType === 'INSUFFICIENT_COINS' ? InsufficientCode
                : (errorType === 'NO_ACCESS' ? NoAccess
                    : SomethingWentWrong)))

    const handleAction = async (id, action) => {
        let res
        if (action === 'accept') {
            res = await acceptConnectionRequest(id)
        } else if (action === 'reject') {
            res = await rejectConnectionRequest(id)
        } else if (action === 'remove') {
            res = await removeSendConnection(id)
        } else if (action === 'profilePrev') {
            navigate(CONNECTIONS.REQUESTED_CONNECTION_PROFILE_PREVIEW.replace(':id', encodeToBase64(id)));
        }
        // const apiCall = action === 'accept' ? acceptConnectionRequest : action === 'reject' ? rejectConnectionRequest : removeSendConnection;
        // const response = await apiCall(id);
        // if (action !== 'profilePrev') {
        if (res?.response?.data) {
            setErrorType(res?.response?.data?.type)
        }
        await fetchData(requestState);
        // }
    };

    const CardComponent = requestState === 'received' ? ConnectionRequestReceivedCard : ConnectionRequestSendCard;

    return (
        <div className="flex flex-col h-full bg-[#f9f9f9]">

            {errorType && (
                <div className="fixed top-0 bottom-0 left-0 right-0 z-50 flex items-center justify-center bg-black bg-opacity-75">
                    <Suspense fallback={<div>Loading...</div>}>
                        <ErrorComponent closeBtn={() => setErrorType(null)} />
                    </Suspense>
                </div>
            )}

            {/* Tabs */}
            <div className="flex flex-col items-center w-full bg-[#F9F9F9] mt-5">
                <div className='w-10/12 h-full mt-3'>
                    <ConnectionTab count={connectionData?.length} />
                </div>
            </div>

            {/* Dropdown */}
            <div className="flex flex-col w-full bg-[#F9F9F9] items-center">
                <div className='flex justify-end w-10/12 h-full'>
                    <select className='border border-gray-300 p-2 w-fit text-black text-lg font-medium bg-[#F9F9F9]'
                        onChange={(e) => handleChangeRequestState(e.target.value)}
                    >
                        <option value="send">Sent</option>
                        <option value="received">Received</option>
                    </select>
                </div>
            </div>


            <div className="flex flex-col items-center w-full f-full bg-[#f9f9f9] mt-5 mb-20">
                <div className='w-10/12 mt-3'>
                    {connectionData?.length > 0 ?
                        <div className='flex flex-wrap gap-6'>
                            {connectionData?.map((item) => (
                                <div className="w-[300px] h-[400px] my-3">
                                    <CardComponent
                                        key={item?.id}
                                        imgUrl={item?.memberId?.photo ? item?.memberId?.photo : avatar}
                                        name={item?.memberId?.name}
                                        profession={item?.memberId?.personal?.profession || null}
                                        actionHandler={(action) => handleAction(item?.id, action)}
                                        expiry={item?.expiry}
                                    />
                                </div>
                            ))}
                        </div>
                        :
                        <div className='flex flex-wrap justify-center'>
                            <Suspense fallback={<div>Loading...</div>}>
                                <NoConnectionsFound decryption={`Your requests will appear here. Currently, there are no requests for you.`} />
                            </Suspense>
                        </div>
                    }
                </div>
            </div>

        </div>
    )
}

export default Index