import { auth } from "..";


export const fetchAllMessagedFriendsList = async () => {
    const res = await auth({
        method: 'GET',
        url: "/messages/list",
    });
    return res;
}


export const fetchMessagesOfUserById = async (shortlistedUserId, roomId, page, limit) => {
    const res = await auth({
        method: 'GET',
        url: `/messages/history/${shortlistedUserId}?room_id=${roomId}&page=${page}&limit=${limit}`,
    });
    return res;
}


export const searchUsersByName = async (data) => {
    const res = await auth({
        method: 'GET',
        url: `/shortlist/search?query=${data}`,
    });
    return res;
}