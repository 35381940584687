import { createSlice } from '@reduxjs/toolkit';

export const userSelectedLocation = createSlice({
    name: 'userSelectedLocationData',
    initialState: {
        location: null
    },
    reducers: {
        setUserSelectedLocation: (state, action) => {
            state.location = action.payload;
        },
    },
});

export const { setUserSelectedLocation } = userSelectedLocation.actions;

export default userSelectedLocation.reducer;
