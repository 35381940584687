import { auth } from '..';
export const religionData = async () => {
    const res = await auth({
        method: "GET",
        url: `/religions`
    });
    return res?.data;
};

// NEW

export const fetchAllReligions = async () => {
    const res = await auth({
        method: "GET",
        url: "/religions",
    });
    return res
}

export const fetchAllCastsByReligionId = async (id) => {
    const res = await auth({
        method: "GET",
        url: `/castes/${id}`,
    });
    return res
}