import { createSlice } from '@reduxjs/toolkit';

export const userProfile = createSlice({
    name: 'userData',
    initialState: {
        personal: {
            religion_id: null,
            caste_id: null,
            sex: null,
            looking_for: null,
            detailed_bio: null,
            dob: null,
            relationship_status: null,
            marital_status: null,
            timeline: null,
            height: null,
            weight: null,
            physical_status: null,
            education: null,
            college: null,
            profession: null,
        },
        misc: {
            eating: null,
            drinking: null,
            smoking: null,
            interests: [],
        },
        address: {
            country: null,
            state: null,
            current_city: null,
            citizenship: null,
        },
        languages_known: {
            mother_tongue: null,
            others: [],
        },
        family: {
            parent_name: null,
            relationship: null,
            contact_mode: null,
            contact_number: null,
        },
        verification: {
            status: null
        },
        wallet: {
            current_plan: {
                _id: null,
                name: null
            }
        },
        email: null,
        phone: null,
        contact_mode: [],
        photo: null,
        profile_completion: 0,
        name: null,
        id: null,
        ghost_mode: null,
    },

    reducers: {
        setUserProfileDetails: (state, action) => {
            Object.keys(action?.payload)?.forEach(key => {
                if (state?.hasOwnProperty(key)) {
                    state[key] = action?.payload[key];
                }
            });
        },

        resetUserProfile: state => {
            state.personal = {
                religion_id: null,
                caste_id: null,
                sex: null,
                looking_for: null,
                detailed_bio: null,
                dob: null,
                relationship_status: null,
                marital_status: null,
                timeline: null,
                height: null,
                weight: null,
                physical_status: null,
                education: null,
                college: null,
                profession: null,
            };
            state.misc = {
                eating: null,
                drinking: null,
                smoking: null,
                interests: [],
            };
            state.address = {
                country: null,
                state: null,
                current_city: null,
                citizenship: null,
            };
            state.languages_known = {
                mother_tongue: null,
                others: [],
            };
            state.family = {
                parent_name: null,
                relationship: null,
                contact_mode: null,
                contact_number: null,
            };
            state.verification = {
                status: null
            };
            state.email = null;
            state.phone = null;
            state.contact_mode = [];
            state.photo = null;
            state.profile_completion = 0;
            state.name = null;
            state.id = null;
            state.ghost_mode = null;
        },
    },

});

export const { setUserProfileDetails, resetUserProfile } = userProfile.actions;

export default userProfile.reducer;
