import React, { useState, useEffect } from 'react';
import { AnimatePresence } from 'framer-motion';

import FirstSlide from './firstSlide';
import SecondSlide from './secondSlide';
import ThirdSlide from './thirdSlide';
import FourthSlide from './fourthSlide';

const Index = () => {
    const [currentSlide, setCurrentSlide] = useState(0);

    useEffect(() => {
        const slideInterval = setInterval(() => {
            setCurrentSlide((prevSlide) => (prevSlide + 1) % 4);
        }, 5600)

        return () => clearInterval(slideInterval);
    }, [])

    return (
        <div>
            <AnimatePresence>
                {currentSlide === 0 && <FirstSlide key="firstSlide" />}
                {currentSlide === 1 && <SecondSlide key="secondSlide" />}
                {currentSlide === 2 && <ThirdSlide key="thirdSlide" />}
                {currentSlide === 3 && <FourthSlide key="fourthSlide" />}
            </AnimatePresence>
        </div>
    );
};

export default Index;
