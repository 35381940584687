import cross from 'assets/discover/icons/reject.png'
import mutual from 'assets/discover/icons/mutual.png'
import like from 'assets/discover/icons/like.png'
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setPosition } from '../../../redux/slice/guid';
import { guidPosition } from 'utils/helper';

function Index({ handleSkip, handleConnect, handleMutualConnection }) {
    const skipActionRef = useRef(null);
    const mutualActionRef = useRef(null);
    const connectionActionRef = useRef(null);

    const dispatch = useDispatch();
    const currentStep = useSelector((state) => state.guide.currentStep);
    // skipActionRef

    useEffect(() => {
        const updatePosition = () => {
            const isMobile = window.innerWidth <= 768;

            const mobilePosition = { top: -250, left: 190 };
            const desktopPosition = { top: -350, left: 150, }
            const additionalPosition = isMobile ? mobilePosition : desktopPosition;

            if (skipActionRef.current && currentStep === 4) {
                guidPosition(skipActionRef, dispatch, setPosition, additionalPosition)
            } else if (mutualActionRef.current && currentStep === 5) {
                const mobilePosition = { top: -250, left: 90 };
                const additionalPosition = isMobile ? mobilePosition : desktopPosition;
                guidPosition(mutualActionRef, dispatch, setPosition, additionalPosition)
            } else if (connectionActionRef.current && currentStep === 6) {
                const mobilePosition = { top: -250, left: -20 };
                const additionalPosition = isMobile ? mobilePosition : desktopPosition;
                guidPosition(connectionActionRef, dispatch, setPosition, additionalPosition)
            }
        };

        // Initial calculation and recalculate on window resize
        updatePosition();
        window.addEventListener('resize', updatePosition);
        return () => window.removeEventListener('resize', updatePosition);
    }, [dispatch, currentStep]);

    return (
        <>
            {/* Skip */}
            {handleSkip &&
                <div ref={skipActionRef} className={` ${currentStep === 4 && 'z-[120] bg-white rounded-full'} w-[70px] h-[70px]  rounded-xl flex justify-center items-center cursor-pointer`} onClick={handleSkip}>
                    <img src={cross} alt='coupleSquad' className='w-full h-full' />
                </div>
            }

            {/* Mutual Connection */}
            {handleMutualConnection &&
                <div ref={mutualActionRef} className={` ${currentStep === 5 && 'z-[120] bg-white rounded-full'}  w-[70px] h-[70px] flex justify-center items-center border  rounded-xl cursor-pointer`} onClick={handleMutualConnection}>
                    <img src={mutual} alt='coupleSquad' className='w-full h-full' />
                </div>
            }

            {/* Connect */}
            {handleConnect &&
                <div ref={connectionActionRef} className={`${currentStep === 6 && 'z-[120] bg-white rounded-full'} w-[70px] h-[70px]  flex justify-center items-center rounded-xl cursor-pointer`} onClick={handleConnect}>
                    <img src={like} alt='coupleSquad' className='w-full h-full' />
                </div>
            }
        </>
    );
};

export default Index