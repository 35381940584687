import React from 'react';
import { Link } from 'react-router-dom';

// Assets
import facebookIcon from 'assets/socialMediaIcons/color/facebook.png'
import instagramIcon from 'assets/socialMediaIcons/color/instagram.png'
// import linkedInIcon from 'assets/socialMediaIcons/color/'
import twitterIcon from 'assets/socialMediaIcons/color/twitter.png'
import youTubeIcon from 'assets/socialMediaIcons/color/youtube.png'
import pintrestIcon from 'assets/socialMediaIcons/color/pintrest.png'
import mediumIcon from 'assets/socialMediaIcons/color/medium.png'

const Index = () => {

    const socialMediaIcons = [
        // { icon: linkedInIcon, text: 'Linkedin', link: 'https://www.linkedin.com/company/couplesquad/' },
        { icon: facebookIcon, text: 'Facebook', link: 'https://www.facebook.com/wearecouplesquad/' },
        { icon: instagramIcon, text: 'Instagram', link: 'https://www.instagram.com/wearecouplesquad/' },
        { icon: youTubeIcon, text: 'Youtube', link: 'https://www.youtube.com/@wearecouplesquad/' },
        { icon: twitterIcon, text: 'Twitter', link: 'https://twitter.com/wecouplesquad/' },
        { icon: pintrestIcon, text: 'Pintrest', link: 'https://in.pinterest.com/couplesquad/' },
        { icon: mediumIcon, text: 'Medium', link: 'https://medium.com/@wearecouplesquad/' },
    ]

    return (
        <div className="max-w-xs p-4 border rounded-lg">
            {/* Social Media Icons */}
            <div className="flex flex-wrap items-center justify-center gap-5 mb-4">
                {socialMediaIcons?.map((item, index) => (
                    <Link to={item?.link} key={index} className="flex flex-col items-center w-20 text-center text-black">
                        <img src={item?.icon} alt={item?.text} className="object-contain w-12 h-12" />
                        <span>{item?.text}</span>
                    </Link>
                ))}
            </div>
        </div>

    );
}

export default Index;
