import React, { lazy, Suspense, useEffect } from 'react';
import Banner from "./components/banner";

const AppDownloadWhatIsDatrimony = lazy(() => import("./components/whatIsDatrimony"))
const AppDownloadWhyChooseDatrimony = lazy(() => import("./components/whyChooseDatrimony"))


function Index() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div>
            <div className='bg-white'>


                {/* Main Banners */}
                <div className="h-full min-h-screen">
                    <Banner />
                </div>

                {/* What Is Datrimony */}
                <>
                    <Suspense children={<AppDownloadWhatIsDatrimony />} fallback={<div>loading...</div>} />
                </>

                {/* Why choose Datrimony */}
                <>
                    <Suspense children={<AppDownloadWhyChooseDatrimony />} fallback={<div>loading...</div>} />
                </>

            </div>
        </div>
    );
}

export default Index;