import { auth } from "..";



export const getUserData = async () => {
    const res = await auth({
        method: "GET", url: "/users/profile", options: {
            headers: {
                Authorization: `Bearer ${localStorage?.getItem("session") || ""}`,
            },
        },
    });
    return res
}

export const fetchAllReligions = async () => {
    const res = await auth({
        method: "GET", url: "/religions", options: {
            headers: {
                Authorization: `Bearer ${localStorage?.getItem("session") || ""}`,
            },
        },
    });
    return res
}

export const fetchAllCastsByReligionId = async (id) => {
    const res = await auth({
        method: "GET", url: `/castes/${id}`, options: {
            headers: {
                Authorization: `Bearer ${localStorage?.getItem("session") || ""}`,
            },
        },
    });
    return res
}

export const updateUserProfile = async (data) => {
    const res = await auth({
        method: "PUT", url: "/users/profile",
        data,
        options: {
            headers: {
                Authorization: `Bearer ${localStorage?.getItem("session") || ""}`,
            },
        },
    });
    return res
}