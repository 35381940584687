import NavBar from "../../../components/NavbarAndTabs/mainHeader"
import Footer from "../../../components/Footer/main/index"
import { ChatSideBar } from "components/sidebars"
import OnlineUsers from "../components/onlineUsers"
import { useState } from "react";

// Assets
import cardImg from "../../../assets/meetings/temp/images/Image.png"

const Index = () => {

    const loop = Array.from({ length: 20 })
    const options = ["Upcomming", "Attended", "Canceled", "Requested"]

    const [currentTab, setCurrentTab] = useState("Upcomming")

    return (
        <div className="bg-white">
            <div>
                <NavBar />
            </div>
            <div className="flex flex-col w-full sm:flex-row">
                {/* Chat SideBar */}
                <div className="w-full h-screen bg-white sm:w-[17.5%]">
                    <ChatSideBar />
                </div>


                {/* Mesage Section */}
                <div className="w-full h-screen bg-[#f9f9f9] sm:w-[72.75%]">
                    <div className="m-20 w-[70.7%]">
                        <h1 className="text-3xl font-bold text-black">Meetings</h1>
                        {/* Search Option */}
                        <div className="relative my-6 w-[53.6%] text-black">
                            <svg width="25" height="25" className="absolute w-6 h-6 text-gray-500 top-3 left-7" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.5742 21.1348C16.8549 21.1348 21.1358 16.854 21.1358 11.5733C21.1358 6.29257 16.8549 2.01172 11.5742 2.01172C6.29355 2.01172 2.0127 6.29257 2.0127 11.5733C2.0127 16.854 6.29355 21.1348 11.5742 21.1348Z" stroke="#161616" stroke-width="1.50972" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M22.1428 22.1419L20.1299 20.1289" stroke="#161616" stroke-width="1.50972" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                            <input type="search" placeholder="Search messenger..."
                                className="w-full h-12 text-black bg-white outline-none pl-14" />
                        </div>

                        {/* Tabs */}
                        <div className='flex gap-2 my-10'>
                            {options.map((item, index) => (

                                <div key={index} className={`w-fit p-1 px-3 text-lg cursor-pointer ${currentTab === item ? 'text-red-500 border-red-500 font-bold border-b-2' : 'text-black'}`}
                                    onClick={() => setCurrentTab(item)}
                                >
                                    {item}
                                </div>
                            ))}
                        </div>

                        {/* Cards */}
                        <div className="w-[97%]">
                            <div className="w-[60%] overflow-y-auto max-h-[calc(80vh-200px)]">
                                {loop.map((_, index) => (
                                    <div className="flex my-5" key={index}>
                                        <img src={cardImg} alt="" />
                                        <div className="ms-3">
                                            <h1 className="text-lg font-medium text-black">Modern Relatiosnhips</h1>
                                            <h1 className="text-base text-gray-500">Kochi Court palace</h1>
                                            <h1 className="text-base text-gray-500">10 am to 5pm</h1>
                                            <h1 className="text-base text-brandRed">12-02-2024</h1>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>

                {/* Online Users */}
                <div className="w-full h-screen bg-[#f9f9f9] sm:w-[9.75%]">
                    <OnlineUsers />
                </div>

            </div>
            <Footer />
        </div>
    );
};

export default Index;
