import React from 'react';

import RightSection from './rightSection'
import LeftSection from './leftSection'



function Index({ data }) {

    return (
        <div className="flex flex-wrap w-full h-full p-4 overflow-y-auto bg-white scrollbar-thin scrollbar-thumb-red-300 scrollbar-track-red-100">

            {/* Left Section */}
            <div className="w-full md:w-1/2">
                <LeftSection data={data} />
            </div>

            {/* Right Section */}
            <div className="w-full pl-4 md:w-1/2">
                <RightSection data={data} />
            </div>
        </div>
    );
};

export default Index;
