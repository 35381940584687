import { useEffect, useState } from "react"
import History from "../components/history"

import WalletCard from "../../../assets/wallet/images/WalletCard.png"
import planCard from "../../../assets/wallet/images/planCard.png"
import eyeCloseIcon from "../../../assets/wallet/icons/eyeCloseIcon.png"
import eyeOpenIcon from "../../../assets/wallet/icons/eyeOpenIcon.png"
import scanner from "../../../assets/wallet/images/scanner.png"
import downIcon from "../../../assets/wallet/icons/downIcon.png"
import { fetchWalletDetails, getQrCode, qrHistory } from "../../../apis/wallet"
import { formatToDateMMdd } from "../../../shared/dates"


function Index() {

    const [showSection2, setShowSection2] = useState(true)
    const [showFullCardNumber, setShowFullCardNumber] = useState(false);
    const [qrCode, setQrCode] = useState(null)
    const [cardDetails, setCardDetails] = useState()
    const [historyDetails, setHistoryDetails] = useState()

    const handleShowQr = async () => {
        const res = await getQrCode()
        setQrCode(res?.data?.qrcode)
    }

    const getCardDetails = async () => {
        const res = await fetchWalletDetails()
        setCardDetails(res?.data)
    }

    const getQrConnectionDetails = async () => {
        const res = await qrHistory()
        setHistoryDetails(res?.data)
    }

    useEffect(() => {
        getCardDetails()
        getQrConnectionDetails()
    }, [])

    const displayCardNumber = (cardNumber) => {
        const formattedNumber = cardNumber?.toString()?.replace(/(\d{4})(?=\d)/g, '$1 ');

        if (showFullCardNumber) {
            return formattedNumber;
        } else {
            return formattedNumber.replace(/(\d{4} \d{4}) \d{4} \d{4}/, '$1 **** ****');
        }
    };



    return (
        <div className="flex flex-col h-full bg-[#f9f9f9]">
            <div className="w-full mx-auto border">
                {showSection2 ? (
                    <>
                        {/* WalletCard (Section 1) */}
                        <div className="flex flex-col items-center justify-center mt-10 text-black mb-60">
                            <h1 className="-mb-12 text-3xl font-medium">Your Identity</h1>
                            <img src={WalletCard} alt="WalletCard" className="" />
                            <p className="font-bold text-[20px] -mt-14">View Your Couple squad card </p>
                            <img src={downIcon} alt="downIcon" className="mt-10 cursor-pointer" onClick={() => setShowSection2(false)} />
                        </div>
                    </>
                ) : (

                    <div className="my-20">
                        {/* Plan Card and Scanner (Section 2)*/}
                        <div className="flex flex-wrap items-center justify-center md:justify-between w-[95%] mx-auto mb-24 lg:w-11/12">

                            {/* Card Details Section */}
                            <div className="relative flex flex-col items-center text-center">
                                <img src={planCard} alt="Plan Card" className="" />

                                {/* Name and the card no */}
                                <div className="absolute top-[40%] left-[calc(30%)] transform -translate-x-1/2 -translate-y-1/2 text-white">
                                    <p className="p-2 mb-2 text-lg font-semibold rounded md:text-2xl">{cardDetails?.name}</p>
                                    <div className="flex items-center w-full">
                                        <p className="p-2 text-base rounded md:text-xl">{displayCardNumber(cardDetails?.wallet?.wallet_id)}</p>
                                    </div>
                                </div>

                                <div className="absolute top-[49%] left-[calc(85%)] transform -translate-x-1/2 -translate-y-1/2 text-white">
                                    <img src={showFullCardNumber ? eyeOpenIcon : eyeCloseIcon} alt="Toggle Visibility" className="cursor-pointer w-7 h-7" onClick={() => setShowFullCardNumber(!showFullCardNumber)} />
                                </div>

                                {/* Plan Name */}
                                <div className="absolute top-[90%] left-[calc(12%)] transform -translate-x-1/2 -translate-y-1/2 text-white">
                                    <p>{cardDetails?.wallet?.plan_category === 'FREE' ? "-" : cardDetails?.wallet?.plan_category}</p>
                                </div>

                                {/* Expire */}
                                <div className="absolute top-[90%] left-[calc(85%)] transform -translate-x-1/2 -translate-y-1/2 text-white">
                                    <p>{cardDetails?.wallet?.plan_category === 'FREE' ? "-" : (cardDetails?.wallet?.plan_category === 'CONNECT' ? "-" : formatToDateMMdd(cardDetails?.wallet?.plan_expiry))}</p>
                                </div>
                            </div>

                            <div className="w-[280px] my-10 lg:m-0">
                                <h1 className="text-xl font-semibold text-black">Your Affinity Code</h1>
                                <p className="text-lg text-subText">Scan your Affinity Code to instantly connect with other Couple Squad members and enjoy exclusive events and treats.</p>
                            </div>

                            {/* Scanner Section */}
                            <div className="relative cursor-pointer me-7" onClick={handleShowQr}>
                                {qrCode ?
                                    ''
                                    :

                                    <div className="absolute inset-0 flex flex-col items-center justify-center w-56 h-56 bg-black opacity-75 -top-5 -left-6">
                                        <img src={eyeCloseIcon} alt="eyeCloseIcon" className="z-30 mb-2 cursor-pointer" onClick={handleShowQr} />
                                        <h1 className="text-white font-semibold text-[20px] z-30">QR Code</h1>
                                    </div>
                                }
                                <img src={qrCode ? qrCode : scanner} alt="scanner" className={`${qrCode ? "" : 'blur-sm'}`} />
                            </div>

                        </div>

                        {/* History */}
                        <div className="w-[98%] md:w-10/12 mx-auto border-2 border-gray-300">
                            <History data={historyDetails} />
                        </div>
                    </div>
                )}
            </div>
            {/* </div> */}
        </div >
    )
}

export default Index