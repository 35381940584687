import React, { useState, useEffect } from 'react'
import 'react-toastify/dist/ReactToastify.css';
import SideBar from "../../../components/sidebars/settings"

import { UserBasicDetails, UserBasicDetailsResponsive, UserImgPreview } from 'components/discover';
import { getUserProfilePreview } from '../../../apis/settings';


function Index() {

    const [userData, setUserData] = useState()

    const fetchUserProfilePreview = async () => {
        const res = await getUserProfilePreview()
        if (res?.data) {
            setUserData(res?.data)
        }
    }

    useEffect(() => {
        fetchUserProfilePreview()
    }, [])


    return (
        <div className="flex flex-col h-full min-h-screen bg-[#efefef]">

            <div className='flex flex-col gap-x-10 items-start justify-center flex-grow w-full mt-[3%] lg:flex-row'>

                {/* ImgPreview Component */}
                <div className="h-[510px] w-full md:w-[360px]">
                    <UserImgPreview data={userData} />
                </div>

                <div className="hidden md:block h-[580px] w-[60%] overflow-y-auto">
                    <UserBasicDetails data={userData} />
                </div>
                <div className="block overflow-y-auto md:hidden">
                    <UserBasicDetailsResponsive data={userData} />
                </div>
            </div>

        </div>

    )
}

export default Index