import React from 'react'
import wifi from 'assets/cards/iconoir_wifi.svg'

function Index() {
    return (
        <div className='w-[336px] h-[398px] bg-white flex flex-col justify-center items-center m-4 border '>
            <div className='flex flex-col items-center justify-center mb-2'>
                <img src={wifi} alt='limits' className='w-[120px] h-[120px] mb-2 cursor-pointer' />
            </div>
            <div className='flex flex-col items-center justify-center gap-2'>
                <p className='text-black font-bold text-[18px]'>No Network</p>
                <p className='text-[14px] font-normal text-text_gray text-center w-[243px] h-[96px]'>Your device isn't connected to the internet right now. Connect to Wi-Fi or cellular data.</p>
            </div>
            <div className='flex items-center justify-center'>
                <button className='w-[280px] h-[48px] bg-brandRed text-white cursor-pointer'>
                    <p className='font-normal text-[16px]'>Continue</p>

                </button>
            </div>
        </div>
    )
}

export default React.memo(Index)