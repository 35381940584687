import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { showPopup } from '../../../../redux/slice/paymentCancellation';

const Index = ({ handleDoneBtn, mainText = false, subText = false, isFailed = false, data }) => {
    const dispatch = useDispatch()

    useEffect(() => {
        if (isFailed) {
            dispatch(showPopup({ id: data?.id }));
        }
    }, [isFailed, dispatch, data]);

    return (

        <div className="flex items-center justify-center min-h-screen ">
            <div className="bg-white p-6 rounded-lg shadow-lg w-full lg:w-[490px] h-[350px] flex flex-col justify-between text-center">
                <div>
                    <div className="flex items-center justify-center mt-5 mb-4">
                        {!isFailed ?
                            <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M51.1959 8.78584C45.54 3.11165 37.9988 0 30 0C21.9829 0 14.4417 3.11165 8.78584 8.78584C3.11165 14.4417 0 21.9829 0 30C0 38.0171 3.11165 45.54 8.78584 51.2142C14.4417 56.87 21.9829 60 30 60C38.0171 60 45.54 56.8883 51.2142 51.2142C56.87 45.5583 60 38.0171 60 30C59.9817 21.9829 56.87 14.4417 51.1959 8.78584Z" fill="#3CAF47" />
                                <path d="M26.0613 35.4337L20.1675 29.5399C19.0326 28.405 17.1657 28.405 16.0308 29.5399C14.896 30.6747 14.896 32.5417 16.0308 33.6765L23.993 41.6387C25.1278 42.7736 26.9948 42.7736 28.1297 41.6387L44.6946 25.0737C45.8295 23.9389 45.8295 22.0719 44.6946 20.9371C43.5598 19.8022 41.6928 19.8022 40.558 20.9371L26.0613 35.4337Z" fill="white" />
                            </svg>
                            :

                            <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M30 3.75C15.375 3.75 3.75 15.375 3.75 30C3.75 44.625 15.375 56.25 30 56.25C44.625 56.25 56.25 44.625 56.25 30C56.25 15.375 44.625 3.75 30 3.75ZM40.125 43.125L30 33L19.875 43.125L16.875 40.125L27 30L16.875 19.875L19.875 16.875L30 27L40.125 16.875L43.125 19.875L33 30L43.125 40.125L40.125 43.125Z" fill="#F83758" />
                            </svg>
                        }

                    </div>
                    {mainText ?
                        <h2 className="my-2 text-xl font-semibold text-black mt-7">{mainText}</h2>
                        :
                        <h2 className="my-2 text-xl font-semibold text-black mt-7">{!isFailed ? "Payment Successful" : "Payment Failed"}</h2>
                    }
                    {subText &&
                        <h2 className="my-2 mt-4 text-lg text-black">{subText}</h2>
                    }
                </div>
                <button className="w-full h-[56px] px-4 py-2 bg-brandRed text-white font-semibold hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-400 mt-auto" onClick={handleDoneBtn}>
                    Done
                </button>
            </div>
        </div>
    );
};

export default React.memo(Index)
