import React, { useState, useEffect, lazy, Suspense, useCallback } from 'react'
import SideBar from "../../../components/sidebars/settings"

import { getWalletRechargeDetails, paymentStatus } from '../../../apis/wallet'
import { toast } from 'react-toastify'

// Assets
import bannerImg from "../../../assets/wallet/images/banner.svg"
import avatar from "../../../assets/avatar.png"

import planeDiscoverImg from "../../../assets/wallet/images/planeDiscover.png"
import planConnectImg from "../../../assets/wallet/images/planConnect.png"
import planExploreImg from "../../../assets/wallet/images/planExplore.png"
import planeFreeImg from "../../../assets/wallet/images/planFree.svg"
import { getUserWalletDetails } from '../../../apis/wallet'
import { SETTINGS } from '../../../shared/router'
import { useNavigate } from 'react-router-dom'
import { Recharge } from 'components/Wallet'

const PlanUpgrade = lazy(() => import("components/popUps").then(module => ({ default: module.PlanUpgrade })))
const RazorPay = lazy(() => import("components/payment").then(module => ({ default: module.RazorPay })))
const PaymentSummary = lazy(() => import("components/payment").then(module => ({ default: module.PaymentSummary })))
const PaymentSuccessPopUp = lazy(() => import("components/popUps").then(module => ({ default: module.PaymentSuccess })))

function Index() {

    const navigate = useNavigate()

    const [planUpgrade, setPlanUpgrade] = useState(null)
    const [walletData, setWalletData] = useState({
        name: '',
        photo: '',
        walletBalance: '',
        currentPlan: '',
        lastTransactionAmount: '',
        lastTransactionType: '',
    })

    const [razoorpayPaymentStatus, setRazoorpayPaymentStatus] = useState(false)
    const [paymentStructure, setPaymentStructure] = useState()
    const [paymentData, setPaymentData] = useState({
        orderId: null,
        total: null,
        paymentId: null,
        status: null,
        gst: null,
        coupon: null,
        discounted_amount: 0
    })
    const [visible, setVisible] = useState({
        coin: true,
        paymentSummary: false,
        razorpay: false,
        paymentSuccess: false,
    })

    const handleWalletDetails = async () => {
        const res = await getUserWalletDetails()
        const data = res?.data
        setWalletData({
            name: data?.name,
            photo: data?.photo,
            walletBalance: data?.wallet?.balance,
            currentPlan: data?.wallet?.current_plan,
            lastTransactionAmount: data?.lastTransaction?.amount,
            lastTransactionType: data?.lastTransaction?.type,

        })
    }

    useEffect(() => {
        handleWalletDetails()
    }, [])

    const walletPlan = walletData?.currentPlan === 'DISCOVER' ? planeDiscoverImg : walletData.currentPlan === 'EXPLORE' ? planExploreImg : walletData.currentPlan === 'CONNECT' ? planConnectImg : planeFreeImg
    const lastTransactionType = walletData?.lastTransactionType === 'credit' ? '+' : '-'

    // const [selectedAmount, setSelectedAmount] = useState(null)
    const coins = [1000, 1500, 2000]

    const handleCoinsRecharge = async (amount) => {
        const toastId = toast.loading("please wait a second")
        if (!amount) {
            toast.error("Please select the amount")
        }
        const res = await getWalletRechargeDetails({ amount: amount })

        if (res?.response?.data?.errno === 402) {
            setPlanUpgrade(true)
        } else if (res?.success) {
            const data = { ...res.payment_structure, id: res?.data?.id }
            setPaymentStructure(data)
            handleChangeVisibility('paymentSummary')
        }
        toast.dismiss(toastId)
    }

    // new

    const handleChangeVisibility = useCallback((key) => {
        setVisible(prev => ({
            ...Object.keys(prev).reduce((acc, currentKey) => {
                acc[currentKey] = false;
                return acc;
            }, {}),
            [key]: true
        }));
    }, []);

    const handlePay = (value) => {
        setPaymentData(prev => ({ ...prev, ...value }))
        handleChangeVisibility("razorpay")
    }

    const handleDoneBtn = () => {
        navigate(SETTINGS.SETTINGS)
    }

    const updateThePayment = async (data) => {
        const { total, ...other } = data
        const res = await paymentStatus(other)
        if (res?.success) {
            handleChangeVisibility('paymentSuccess')
        }
    }

    const razorpaySuccess = async (value) => {
        if (value?.status === 'failed') {
            handleChangeVisibility('paymentSuccess')
            setRazoorpayPaymentStatus(true)
        } else {
            const data = {
                ...paymentData,
                paymentId: value.paymentId,
                status: value.status
            }
            updateThePayment(data)
        }
    }

    return (
        <div className="flex flex-col h-full bg-white">
            {planUpgrade &&
                <div className="fixed top-0 bottom-0 left-0 right-0 z-50 flex items-center justify-center bg-black bg-opacity-75">
                    <Suspense children={<PlanUpgrade closeBtn={() => setPlanUpgrade(false)} />} fallback={<div>loading...</div>} />
                </div>
            }


            {/* Content Section */}
            {visible.coin &&
                <div className="flex w-10/12">
                    <div className="mx-auto mt-20 ">
                        {/* Banner Section */}
                        <div className="relative">
                            <img src={bannerImg} alt="" className="max-w-[1180px] md:max-w-full w-full" />
                            {/* left Section */}
                            <div className="absolute lg:top-[35px] lg:left-[23px] md:top-[5px] md:left-[13px] flex items-center p-5 md:p-3">
                                <img src={walletData?.photo ? walletData?.photo : avatar} alt="avatar" className="w-2 h-2 mb-2 rounded-full md:w-16 md:h-16" />
                                <div className="ml-2 text-xl font-medium text-white">
                                    <p className='lg:text-2xl md:text-sm'>Welcome</p>
                                    <p className='lg:text-lg md:text-sm'>{walletData?.name}</p>
                                </div>
                            </div>
                            {/* Right side content */}
                            <div className="text-white absolute lg:top-[65px] lg:right-[150px] md:top-[20px] md:right-[150px] flex flex-col items-start p-5">
                                <h1 className='text-lg font-bold md:text-sm'>Balance</h1>
                                <p className='lg:text-[25px] md:text-sm font-bold flex items-center gap-2'>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12 5C7.031 5 2 6.546 2 9.5C2 12.454 7.031 14 12 14C16.97 14 22 12.454 22 9.5C22 6.546 16.97 5 12 5ZM7 14.938V17.938C8.237 18.237 9.605 18.42 11 18.479V15.479C9.65248 15.4265 8.31305 15.2453 7 14.938ZM13 15.478V18.478C14.3476 18.4266 15.6871 18.2454 17 17.937V14.937C15.6871 15.2454 14.3476 15.4266 13 15.478ZM19 14.297V17.297C20.801 16.542 22 15.44 22 14V11C22 12.44 20.801 13.542 19 14.297ZM5 17.297V14.297C3.2 13.542 2 12.439 2 11V14C2 15.439 3.2 16.542 5 17.297Z" fill="white" />
                                    </svg>
                                    {walletData?.walletBalance}</p>
                            </div>
                            <div className="absolute lg:top-[35px] lg:right-[9px] md:top-[-12px] md:right-[0px] flex flex-col items-end p-5">
                                <div className="flex items-center lg:mb-5 md:mb-1">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12 5C7.031 5 2 6.546 2 9.5C2 12.454 7.031 14 12 14C16.97 14 22 12.454 22 9.5C22 6.546 16.97 5 12 5ZM7 14.938V17.938C8.237 18.237 9.605 18.42 11 18.479V15.479C9.65248 15.4265 8.31305 15.2453 7 14.938ZM13 15.478V18.478C14.3476 18.4266 15.6871 18.2454 17 17.937V14.937C15.6871 15.2454 14.3476 15.4266 13 15.478ZM19 14.297V17.297C20.801 16.542 22 15.44 22 14V11C22 12.44 20.801 13.542 19 14.297ZM5 17.297V14.297C3.2 13.542 2 12.439 2 11V14C2 15.439 3.2 16.542 5 17.297Z" fill="white" />
                                    </svg>

                                    <p className="text-xl text-white md:text-sm ms-2">{lastTransactionType} {walletData?.lastTransactionAmount}</p>
                                </div>
                                <div className='flex'>
                                    <img src={walletPlan} alt="" className="" />
                                </div>
                            </div>
                        </div>


                        <Recharge handleCoinsRecharge={handleCoinsRecharge} />

                    </div>
                </div>
            }

            {visible.paymentSummary &&
                <div className="flex items-center justify-center w-full">
                    <PaymentSummary data={paymentStructure} handlePay={(value) => handlePay(value)} />
                </div>
            }

            {visible.razorpay && (
                <Suspense fallback={<div>Loading...</div>}>
                    <RazorPay amount={paymentData.total} orderId={paymentData.orderId} handleOnclick={(value) => razorpaySuccess(value)} />
                </Suspense>
            )}

            {visible.paymentSuccess && (
                <div className="flex items-center justify-center w-full">
                    <Suspense fallback={<div>Loading...</div>}>
                        <PaymentSuccessPopUp handleDoneBtn={handleDoneBtn} isFailed={razoorpayPaymentStatus} data={paymentStructure} />
                    </Suspense>
                </div>
            )}

        </div>

    )
}

export default Index