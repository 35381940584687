export const avoidOptions = {
    NAVBAR: 'navbar',
    FOOTER: 'footer',
    BOTTOM_NAVBAR_RESPONSIVE: 'bottomNavbarResponsive',
    LAYOUT: 'layout',
    SITE_INTRO_NAV: 'siteIntroNav'
};

export const authenticationProps = {
    AUTH: 'auth',
    UNAUTH: 'unAuth',
    NONE: 'none'
}

export const LAYOUT_TYPES = {
    NONE: 'none',
    UNAUTHENTICATED: 'unAuthenticated',
    AUTHENTICATED: 'authenticated',
    WALLET: 'wallet',
    SETTINGS: 'settings'
}