import React, { useState, memo, useEffect } from 'react';
import icon from '../../../assets/discover/couplesquad.png';
import { Link, useLocation } from 'react-router-dom';
import { AUTH, MAIN_NAV, SITE_INTO } from '../../../shared/router';
import { useSelector } from 'react-redux';

function Index() {
    const userInfo = useSelector((state) => state.userProfile);

    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => setIsOpen(!isOpen);

    const [selectedTab, setSelectedTab] = useState();
    const location = useLocation();

    const tabs = [
        { name: 'home', label: "Home", location: SITE_INTO.LANDING },
        { name: 'aboutUs', label: "About Us", location: SITE_INTO.ABOUT },
        { name: 'datrimony', label: "Datrimony™", location: SITE_INTO.DATRIMONY },
        { name: 'events', label: "Events", location: SITE_INTO.EVENT_HOME },
        { name: 'host with us', label: "Host With Us", location: SITE_INTO.HOST_WITH_US },
        // { name: 'blog', label: "Blog", location: SITE_INTO.BLOG },
    ];

    useEffect(() => {
        const currentSidebarItem = tabs.find(item => item.location === location.pathname);
        if (currentSidebarItem) {
            setSelectedTab(currentSidebarItem.name);
        } else {
            setSelectedTab(undefined);
        }
    }, [location]);

    return (

        <div className="bg-white flex justify-center items-center max-h-[80px]">
            <div className="navbar w-[90%] flex justify-between items-center p-4">
                <div className="navbar-start flex items-center w-[11%] justify-between">
                    {/* Logo */}
                    <Link to={SITE_INTO.LANDING}>
                        <img src={icon} alt='Logo' className="mr-3 min-w-[80px] min-h-[20px] lg:w-auto lg:h-auto py-3" />
                    </Link>
                </div>

                {/* Full-screen Menu for larger devices - initially hidden on smaller screens */}
                <div className="flex-grow hidden lg:flex navbar-end">
                    <ul className={`p-0 text-black text-base flex justify-end space-x-10`}>
                        {tabs?.map((item) => (
                            <li className="relative">
                                <Link to={item?.location} className={`${selectedTab === item?.name ? 'pb-2 mb-3 text-brandRed' : ''}`}>
                                    {item?.label}
                                </Link>
                                {selectedTab === item?.name && (
                                    <div className="absolute left-1/2 transform -translate-x-1/2  w-0 h-0 border-l-[6px] border-r-[6px] border-b-[6px] border-l-transparent border-r-transparent border-b-brandRed"></div>
                                )}
                            </li>
                        ))}
                    </ul>
                </div>

                {/* Join Button - only visible on large screens */}
                {userInfo.photo ?
                    <Link to={MAIN_NAV.DISCOVER} className="hidden gap-1 ml-4 cursor-pointer lg:flex">
                        <img src={userInfo.photo} alt='profile' className='w-[50px] h-[50px] rounded-full' />
                    </Link>
                    :
                    <Link to={MAIN_NAV.DISCOVER} className="hidden lg:flex text-center justify-center items-center outline-none text-white bg-brandRed hover:bg-red-700 w-[120px] h-[40px] ml-4">
                        {localStorage?.getItem('session') ? 'Dashboard' : 'Join'}
                    </Link>
                }

                {/* Mobile Menu Button */}
                <button onClick={toggleDropdown} className="ml-4 btn btn-ghost lg:hidden">
                    <svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 3.5H21" stroke='#070707' stroke-width="2" stroke-linecap="round" />
                        <path d="M1 10.5H21" stroke='#070707' stroke-width="2" stroke-linecap="round" />
                        <path d="M1 17.5H21" stroke='#070707' stroke-width="2" stroke-linecap="round" />
                    </svg>
                </button>

                {/* Mobile Dropdown Menu */}
                {isOpen && (
                    <ul className={`menu menu-compact dropdown-content lg:hidden absolute bg-white rounded-lg shadow-lg w-full h-screen text-black text-lg z-50 right-4 top-16 flex flex-col items-start`}>
                        <div className="flex flex-col w-full py-4 space-y-2">
                            {tabs?.map((item) => (
                                <li key={item?.name} className="w-full border-b border-black">
                                    <Link
                                        to={item?.location}
                                        className={`block py-2 w-full ${selectedTab === item?.name ? 'border-b-2 border-brandRed text-brandRed' : ''} text-left`}
                                    >
                                        {item?.label}
                                    </Link>
                                </li>
                            ))}
                        </div>
                        {/* Adjusted part for the "Join" button */}
                        <div className="w-full py-4 mx-2">
                            {userInfo.photo ? (
                                <li className="flex items-center justify-center">
                                    <Link to={MAIN_NAV.DISCOVER} className="gap-1 cursor-pointer">
                                        <img src={userInfo.photo} alt="profile" className="w-full h-12 rounded-full" />
                                    </Link>
                                </li>
                            ) : (
                                <li className="flex items-center justify-center">
                                    <Link
                                        to={MAIN_NAV.DISCOVER}
                                        className="flex items-center justify-center w-full h-10 text-white outline-none bg-brandRed hover:bg-red-700">
                                        Join
                                    </Link>
                                </li>
                            )}
                        </div>
                    </ul>
                )}
            </div>
        </div>


    );
}

export default memo(Index);
