
import bg_img from "../../../assets/onBoarding/bgImages/pageFourBgImage.svg"
import back_btn_icon from "../../../assets/onBoarding/back-btn-icon.svg"

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from 'yup'
import { entireDataValidation } from "../../../utils/validations/onBoarding"
import { submitOnboardingData } from "../../../apis/onBoarding";
import { setGuideData } from "../../../redux/slice/guid";
import { useDispatch } from "react-redux";


function Index() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [text, setText] = useState('');
    const [wordCount, setWordCount] = useState(0);



    useEffect(() => {
        const serializedObject = localStorage.getItem('userData');
        const userData = JSON.parse(serializedObject);

        if (userData?.data?.about) {
            setText(userData.data.about)
            setWordCount(userData.data.about.length);
        }
    }, [])


    const handleLetsGo = async () => {
        try {
            const serializedObject = localStorage.getItem('userData');
            const currentUserData = JSON.parse(serializedObject) || {};
            const { data } = currentUserData
            const userDetails = {
                name: data.name,
                dob: data.dob,
                gender: data.gender,
                relationship_status: data.relationshipStatus,
                marital_status: data.martialStatus,
                looking_for: data.lookingFor,
                interests: data.intrusts,
                detailed_bio: text
            }
            await entireDataValidation.validate(userDetails, { abortEarly: false });
            const res = await submitOnboardingData(userDetails)
            if (res.success) {
                dispatch(setGuideData());
                navigate('/home')
                localStorage.removeItem('userData');
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                toast.error(error.inner[0].message);
            } else {
                toast.error('Something went wrong');
            }
        }
    };



    const handleTextChange = (e) => {
        const inputText = e.target.value;
        const words = inputText
        const limitedWords = words.slice(0, 500)
        if (words.length <= 500) {
            setText(limitedWords);
            setWordCount(words.length);
        }
    };

    const handleBackBtn = () => {
        const serializedObject = localStorage.getItem('userData');
        const currentUserData = JSON.parse(serializedObject) || {};
        const updatedUserData = {
            ...currentUserData,
            onBoardingSix: false,
        };
        localStorage.setItem('userData', JSON.stringify(updatedUserData));
        navigate('/on-boarding-5')
    }

    return (
        <div className="flex flex-col min-h-screen bg-white lg:flex-row">
            <div className="relative flex-1 hidden overflow-hidden bg-center bg-cover lg:block lg:h-auto h-1/2 lg:w-full">
                <img src={bg_img} alt="" className="object-cover w-full h-screen" />

                <div className="absolute inset-0 flex flex-col items-center justify-center text-white bg-black bg-opacity-50">
                    <h1 className="p-4 font-semibold text-7xl">Invite Your Friends <br />to Explore <br />Couple Squad</h1>
                    <p className="p-4">Feel free to invite your friends to explore Couple Squad, and <br /> we'd appreciate it if you could recommend your best friends <br /> to sign up.</p>
                </div>
            </div>


            <div className="relative flex flex-col items-center justify-center flex-1 w-full h-screen mx-auto my-10 bg-white lg:h-auto lg:w-6/12 lg:my-0 text-brandRed ">

                <img src={back_btn_icon} alt="back" onClick={handleBackBtn} className="absolute top-0 left-0 p-2 mt-5 ml-5 text-sm text-white cursor-pointer" />

                <div className="w-[90%] md:w-7/12 mx-auto mb-5 lg:mb-0">
                    <h1 className="text-2xl font-semibold text-black font-inter">Tell the world about yourself!</h1>

                    <div className="flex items-end w-full pb-2 my-6 space-x-4 border-b-2 border-black md:w-10/12">
                        <input
                            value={text}
                            onChange={handleTextChange}
                            className="w-full p-2 pb-0 text-black bg-white border-0 outline-none md:w-10/12"
                        />
                        <span className="text-sm text-black">{wordCount}/500</span>
                    </div>


                    <p className="text-sm text-gray-600">Let everyone know your best qualities!</p>

                    <button className="w-full p-3 mt-10 text-sm text-white border md:w-10/12 bg-brandRed" onClick={handleLetsGo}>
                        Let's Go
                    </button>
                </div>

                <div className="absolute inset-x-0 bottom-0 lg:bottom-10">
                    <div className="w-full bg-gray-200">
                        <div className="py-1 text-xs leading-none text-center text-white bg-brandRed" style={{ width: "95%" }}></div>
                    </div>
                </div>
            </div>
        </div >

    );
}

export default Index;

