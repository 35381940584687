import React from 'react';
import rightArrow from '../../../assets/discover/rightArrow.svg';

const Index = ({ label, selectedValue, onLabelClick }) => {

    const displayItems = Array.isArray(selectedValue) ? selectedValue : [selectedValue];

    return (
        <div className='w-full h-auto my-5'>
            <p className='text-text_gray font-normal text-lg mb-2'>{label}</p>
            <div className='w-full min-h-[60px] border-2 border-gray-300 rounded flex justify-between items-center p-4 cursor-pointer' onClick={onLabelClick}>
                <div className='text-black font-normal text-[16px] flex flex-wrap items-center'>
                    {displayItems && displayItems.length > 0 ? (
                        displayItems.map((item, index) => (
                            <React.Fragment key={index}>
                                <div className="p-1 flex items-center">
                                    {item || 'Select'}
                                </div>
                                {index < displayItems.length - 1 && <span>,</span>}
                            </React.Fragment>
                        ))
                    ) : (
                        <div className="p-1">Select</div>
                    )}
                </div>
                <img src={rightArrow} alt='right arrow' />
            </div>
        </div>


    );
};

export default React.memo(Index);
