import React from 'react';
import avatar from 'assets/avatar.png'


function Index({ avatarUrl, name, jobTitle, onAccept, onReject, status, requestStatus }) {
    return (
        <div className="w-64 overflow-hidden bg-white border rounded-lg shadow-md border-blue-gray-300">
            <div className="flex flex-col items-center p-6">
                <img
                    src={avatarUrl || avatar}
                    alt={`${name}'s avatar`}
                    className="object-cover w-24 h-24 mb-4 rounded-full"
                />
                <h2 className="text-xl font-semibold text-gray-800">{name}</h2>

                {jobTitle && <p className="mb-6 text-sm text-gray-600">{jobTitle}</p>}

                {status === 'receive' ? (
                    <div className="w-full space-y-2">
                        <button
                            onClick={onReject}
                            className="w-full px-4 py-2 text-blue-500 transition-colors border border-blue-500 rounded-md hover:bg-blue-50"
                        >
                            Reject
                        </button>
                        <button
                            onClick={onAccept}
                            className="w-full px-4 py-2 text-white transition-colors bg-blue-500 rounded-md hover:bg-blue-600"
                        >
                            Accept
                        </button>
                    </div>
                ) : (
                    <div className={`${requestStatus === 'Requested' ? 'text-blue-500' : 'text-red-500'}`}>{requestStatus}</div>
                )}
            </div>
        </div>
    );
};

export default Index;