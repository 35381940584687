export const genderOptions = [
    { value: 'male', label: 'Male' },
    { value: 'female', label: 'Female' }
]

export const relationshipStatusOptions = [
    { value: 'single', label: 'Single' },
    { value: 'couple', label: 'Couple' }
]

export const physicalStatusOptions = [
    { value: 'normal', label: 'Normal' },
    { value: 'differently_abled', label: 'Differently Abled' },
]

export const singlesMartialStatusOPtions = [
    { label: 'Unmarried', value: "unmarried" },
    { label: 'Divorced', value: "divorced" },
    { label: 'Widowed', value: "widowed" }
]

export const couplesMartialStatusOPtions = [
    { label: 'In a relationship', value: "in_a_relationship" },
    { label: 'Engaged', value: "engaged" },
    { label: 'Married', value: "married" }
]

export const lookingForOptions = [
    { value: 'marriage', label: 'Marriage' },
    { value: 'dating', label: 'Dating' },
    { value: 'friendship', label: 'Friendship' },
    { value: 'im_not_sure_yet', label: 'Im not sure yet' },
]
export const lookingForOptionsCouples = [
    { value: 'meetup', label: 'Meetup' },
    { value: 'events', label: 'Events' },
    { value: 'friendship', label: 'Friendship' },
]

export const timeLineOptions = [
    { value: 'less_than_6_months', label: 'Less than 6 months' },
    { value: '1_year', label: '1 Years' },
    { value: '1-2_year', label: '1-2 Years' },
]

// Diet Options
export const dietDrinkingOptions = [
    { value: 'non_drinker', label: 'Non Drinker' },
    { value: 'social_drinker', label: 'Social Drinker' },
]

export const dietSmokingOptions = [
    { value: 'non_smoker', label: 'Non Smoker' },
    { value: 'occasional_smoker', label: 'Occasional Smoker' },
]

export const dietEatingOptions = [
    { value: 'omnivore', label: 'Omnivore' },
    { value: 'Vegetarian/Vegan', label: 'Vegetarian/Vegan' },
    { value: "i'm_flexible", label: "I'm Flexible" },
]

export const workoutOptions = [
    { value: 'regularly', label: 'Regularly' },
    { value: 'weekly', label: 'Weekly' },
    { value: "sometimes", label: "Sometimes" },
    { value: "not_do", label: "Not do" },
]

export const educationOptions = [
    { value: 'under_graduate', label: 'Under Graduate' },
    { value: 'post_graduate', label: 'Post Graduate' },
    { value: "12th", label: "12th" },
]