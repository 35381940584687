import React from 'react'
import Navbar from 'components/NavbarAndTabs/siteIntro';
import serverError from 'assets/cards/error/serverError.png';


function Index() {
    return (
        <div>
            <div className="flex flex-col min-h-screen max-h-screen">
                <Navbar />
                <div className="flex flex-col items-center justify-center flex-grow bg-gray-100 p-4">
                    <div className="text-center text-black">
                        <h1 className="text-4xl font-bold mb-4">OOPS ..</h1>
                        <h2 className="text-2xl mb-2">Something Error</h2>
                        <p className="text-lg text-gray-600 mb-8">
                            The page you are looking for doesn’t exist or any <br /> other error occurred, go back to home page.
                        </p>
                        <img src={serverError} alt="Server Error" className="w-full max-w-md mx-auto mb-8" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default React.memo(Index)