import { useState } from "react"
import SideBar from "../../../components/sidebars/settings"
import { useDispatch } from "react-redux"
import { updateUserProfile } from "../../../apis/editProfile"
import { setUserProfileDetails } from '../../../redux/slice/userProfile';
import { toast } from "react-toastify"
import { educationValidation } from "../../../utils/validations/editProfile"
import * as Yup from 'yup'
import { useDataForProfileEdit } from "../../../hooks/userProfileEdit";
import ProfileEditNav from '../../../components/NavbarAndTabs/profileEdit'

function Index() {

    const userInfo = useDataForProfileEdit()
    const dispatch = useDispatch()
    const [data, setData] = useState({
        education: userInfo?.personal?.education || "",
        college: userInfo?.personal?.college || "",
        profession: userInfo?.personal?.profession || "",
        ctc: userInfo?.personal?.ctc || "",
    })

    const handleSave = async () => {
        try {
            const toastId = toast.loading('Updating profile...');
            await educationValidation.validate(data, { abortEarly: false });

            const updateData = {
                ...userInfo,
                personal: {
                    ...userInfo.personal,
                    education: data.education,
                    college: data.college,
                    profession: data.profession,
                    ctc: data.ctc,
                },
            };

            const res = await updateUserProfile(updateData);
            dispatch(setUserProfileDetails(res?.data));
            toast.dismiss(toastId);
        } catch (error) {
            toast.dismiss()
            if (error instanceof Yup.ValidationError) {
                toast.error(error.inner[0].message);
            } else {
                toast.error('Something went wrong');
            }
        }
    };

    return (
        <div>
            <div className="flex flex-col h-full min-h-screen bg-white">

                <div className="flex flex-1 overflow-hidden bg-white">
                    <div className="bg-[#edf0f7] w-3/12 hidden lg:block">
                        <SideBar />
                    </div>

                    <div className="flex flex-col w-full lg:w-10/12">
                        <div className="w-[98%] ms-2 ">
                            <ProfileEditNav />
                        </div>
                        <div className="flex-1 overflow-auto p-4 lg:w-[28%] md:w-[50%] w-full text-black m-10 ms-0 md:ms-14">

                            <div className="flex mb-2">
                                <svg width="24" height="24" className="mb-2 me-1" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M23 15.1429V9.57143M23 9.57143L12 4L1 9.57143L12 15.1429L17.72 12.5714M23 9.57143L17.72 12.5714M17.72 12.5714V18.5714C17.72 20.2857 15.08 22 12 22C8.92 22 6.28 20.2857 6.28 18.5714V12.5714" stroke="#070707" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                                Education
                            </div>
                            <input
                                type="text"
                                className="block w-full p-3 text-black bg-white border border-gray-300 rounded-sm ps-4"
                                value={data.education}
                                onChange={(e) => setData(prev => ({ ...prev, education: e.target.value }))}
                            />
                            <div className="flex mb-2 mt-7">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_2842_3700)">
                                        <path d="M9.75 16.5C11.4069 16.5 12.75 15.1569 12.75 13.5C12.75 11.8431 11.4069 10.5 9.75 10.5C8.09315 10.5 6.75 11.8431 6.75 13.5C6.75 15.1569 8.09315 16.5 9.75 16.5Z" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M5.00531 19.5C5.4308 18.6017 6.10257 17.8426 6.9425 17.3111C7.78243 16.7795 8.756 16.4973 9.75 16.4973C10.744 16.4973 11.7176 16.7795 12.5575 17.3111C13.3974 17.8426 14.0692 18.6017 14.4947 19.5H20.25C20.4489 19.5 20.6397 19.421 20.7803 19.2803C20.921 19.1397 21 18.9489 21 18.75V5.25C21 5.05109 20.921 4.86032 20.7803 4.71967C20.6397 4.57902 20.4489 4.5 20.25 4.5H3.75C3.55109 4.5 3.36032 4.57902 3.21967 4.71967C3.07902 4.86032 3 5.05109 3 5.25V18.75C3 18.9489 3.07902 19.1397 3.21967 19.2803C3.36032 19.421 3.55109 19.5 3.75 19.5H5.00531Z" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M16.5 16.5H18V7.5H6V9" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_2842_3700">
                                            <rect width="24" height="24" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                                College
                            </div>
                            <input
                                type="text"
                                className="block w-full p-3 bg-white border border-gray-300 rounded-sm ps-4"
                                value={data.college}
                                onChange={(e) => setData(prev => ({ ...prev, college: e.target.value }))}
                            />
                            <div className="flex mb-2 mt-7">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M4.615 20C4.155 20 3.771 19.846 3.463 19.538C3.15433 19.2293 3 18.845 3 18.385V8.615C3 8.155 3.15433 7.771 3.463 7.463C3.771 7.15433 4.155 7 4.615 7H9V5.615C9 5.155 9.15433 4.771 9.463 4.463C9.771 4.15433 10.155 4 10.615 4H13.385C13.845 4 14.2293 4.15433 14.538 4.463C14.846 4.771 15 5.155 15 5.615V7H19.385C19.845 7 20.229 7.15433 20.537 7.463C20.8457 7.771 21 8.155 21 8.615V18.385C21 18.845 20.846 19.229 20.538 19.537C20.2293 19.8457 19.845 20 19.385 20H4.615ZM4.615 19H19.385C19.5383 19 19.6793 18.936 19.808 18.808C19.936 18.6793 20 18.5383 20 18.385V8.615C20 8.46167 19.936 8.32067 19.808 8.192C19.6793 8.064 19.5383 8 19.385 8H4.615C4.46167 8 4.32067 8.064 4.192 8.192C4.064 8.32067 4 8.46167 4 8.615V18.385C4 18.5383 4.064 18.6793 4.192 18.808C4.32067 18.936 4.46167 19 4.615 19ZM10 7H14V5.615C14 5.46167 13.936 5.32067 13.808 5.192C13.6793 5.064 13.5383 5 13.385 5H10.615C10.4617 5 10.3207 5.064 10.192 5.192C10.064 5.32067 10 5.46167 10 5.615V7Z" fill="black" />
                                </svg>
                                Professional
                            </div>
                            <input
                                type="text"
                                className="block w-full p-3 bg-white border border-gray-300 rounded-sm ps-4"
                                value={data.profession}
                                onChange={(e) => setData(prev => ({ ...prev, profession: e.target.value }))}
                            />
                            <div className="flex mb-2 mt-7">CTC</div>
                            <input
                                type="text"
                                className="block w-full p-3 bg-white border border-gray-300 rounded-sm ps-4"
                                value={data.ctc}
                                onChange={(e) => setData(prev => ({ ...prev, ctc: e.target.value }))}
                            />
                            <div className="flex items-start justify-start w-full pt-4 mt-10">
                                <button className="block w-full p-3 text-white border rounded-sm ps-4 bg-brandRed" onClick={handleSave}>
                                    Save
                                </button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Index
