import React, { useEffect, useState } from 'react'
import banner from 'assets/offers/images/banner.png'
import { LazyImage } from 'components/image';
import { getOffers } from 'apis/offers'


import { OFFERS } from 'shared/router'
import { encodeToBase64 } from 'utils/encodeDecode'
import { useNavigate } from 'react-router-dom'


function Index() {
    const navigate = useNavigate()

    const [offers, setOffers] = useState()
    const [pagination, setPagination] = useState({
        current_page: 1,
        next_page: null,
    })

    const fetchOffers = async (page) => {
        const res = await getOffers(page, 10)
        if (res?.success) {
            setOffers(res?.data)
            setPagination({ ...res?.pagination })
        }
    }

    const handleOnClickImage = (id) => {
        const url = OFFERS.OFFER_DETAILS.replace(':id', encodeToBase64(id))
        navigate(url)
    }

    useEffect(() => {
        fetchOffers(1)
    }, [])

    return (
        <div className='min-h-screen bg-white'>

            <div className='flex flex-col w-10/12 mx-auto'>
                <LazyImage src={banner} className={'my-10'} />

                <div className="flex flex-wrap gap-3">
                    {offers?.map((item, index) => (
                        <div key={index} className='cursor-pointer' onClick={() => handleOnClickImage(item?.id)}>
                            <LazyImage
                                src={item?.image}
                                className={`w-full h-auto `}
                            />
                            {/* sm:max-w-[300px] sm:max-h-[150px] sm:min-w-[300px] sm:min-h-[150px]
                                md:max-w-[350px] md:max-h-[175px] md:min-w-[350px] md:min-h-[175px]
                                lg:max-w-[415px] lg:max-h-[200px] lg:min-w-[415px] lg:min-h-[200px] */}
                        </div>
                    ))}
                </div>

                {pagination?.next_page !== null &&
                    <div onClick={() => fetchOffers(pagination?.next_page)}
                        className="flex items-center justify-center cursor-pointer hover:underline">see more</div>
                }
            </div>

        </div>
    )
}

export default Index