import { noAuth } from "apis";


export const fetchAllBlogs = async (page) => {
    const res = await noAuth({
        method: 'GET',
        url: `/blog/blogs?page=${page}&limit=10`
    });
    return res;
}

export const fetchBlogDetailBySlug = async (slug) => {
    const res = await noAuth({
        method: 'GET',
        url: `/blog/blog/${slug}`
    });
    return res;
}

export const fetchLatestBlogs = async (count) => {
    const res = await noAuth({
        method: 'GET',
        url: `/blog/latest/${count}`
    });
    return res;
}