import { createSlice } from '@reduxjs/toolkit';
import { CONNECTIONS, MAIN_NAV } from 'shared/router';

const initialState = {
    isGuide: false,
    guids: [
        {
            navigate: MAIN_NAV.HOME,
            refValue: 'chatButtonRef',
            data: 'All messages from your friends will show up here. Stay connected and chat with them through the Couple Squad message box.',
        },
        {
            navigate: '',
            refValue: 'activityPlanRef',
            data: 'This section displays the plan you selected. We offer Free, Connect, Explore, and Discover plans. Paid plans come with extra features.',
        },
        {
            navigate: '',
            refValue: 'activityCoinsRef',
            data: 'Use coins to send connections and book events. If you run out of coins, recharge your wallet to get more.',
        },
        {
            navigate: '',
            refValue: 'activityCouponRef',
            data: 'If any discount coupons are available, they’ll be listed here. You can use these coupons to upgrade your plan or book events.',
        },
        {
            navigate: MAIN_NAV.DISCOVER,
            refValue: 'skipActionRef',
            data: 'If you’re not interested in a profile, you can use the close button to remove it from your feed.',
        },
        {
            navigate: '',
            refValue: 'mutualActionRef',
            data: 'If any of your friends or their friends are Couple Squad members, you can easily connect through the mutual friend option.',
        },
        {
            navigate: '',
            refValue: 'connectionActionRef',
            data: 'If you like a profile and want to connect with them, use this option to send a request.',
        },
        {
            navigate: CONNECTIONS.HANDPICK,
            refValue: 'handPickRef',
            data: 'When you purchase a premium plan, you’ll receive handpicked matches based on your preferences.',
        },
    ],
    position: { top: 'auto', left: 'auto', right: 'auto', bottom: 'auto' },
    currentStep: 0, // Track the current guide step
};

const guideSlice = createSlice({
    name: 'guide',
    initialState,
    reducers: {
        setGuideData: (state) => {
            state.isGuide = true;
            state.currentStep = 0;
        },
        nextGuideStep: (state) => {
            if (state.currentStep < state.guids.length - 1) {
                state.currentStep += 1;

                state.position = {
                    top: 'auto',
                    left: 'auto',
                    right: 'auto',
                    bottom: 'auto',
                };
            } else {
                state.isGuide = false;
                state.guids = [];
                state.currentStep = 0;
            }
        },
        skipGuide: (state) => {
            state.isGuide = false;
            state.guids = [];
            state.currentStep = 0;
        },
        setPosition: (state, action) => {
            state.position = action.payload;
        },

    },
});

export const { setGuideData, nextGuideStep, skipGuide, setPosition } = guideSlice.actions;
export default guideSlice.reducer;
