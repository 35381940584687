import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import sky from 'assets/siteIntro/datrimony/images/sky.png';
import person from 'assets/siteIntro/datrimony/images/firstSlidePerson.png';
import { AUTH } from 'shared/router';

const Index = () => {
    const [hasMounted, setHasMounted] = useState(false);

    const bezierTransition = {
        duration: 2,
        ease: [1, 0.01, 0, 1],
    };

    useEffect(() => {
        // Set a timeout to trigger the ease-out effect after 4 seconds
        const timer = setTimeout(() => {
            setHasMounted(true);
        }, 4000);

        // Clean up the timer if the component is unmounted
        return () => clearTimeout(timer);
    }, []);

    return (
        <div className="h-screen w-full bg-[#6161f8] relative flex items-center justify-center overflow-hidden">
            {/* Sky images */}
            <motion.img
                src={sky} // sky top left
                alt="Datrimony"
                className="absolute top-[10%] left-1/4 transform translate-x-[-50%] translate-y-[-50%] w-[100px] md:w-[150px]"
                initial={{ opacity: 0, y: -50 }}
                animate={hasMounted ? { opacity: 0, y: -50 } : { opacity: 1, y: 0 }}
                transition={bezierTransition}
            />
            <motion.img
                src={sky}  // sky top right
                alt="Datrimony"
                className="absolute top-1/4 right-[10%] transform translate-x-[50%] translate-y-[-50%] w-[150px] md:w-[300px]"
                initial={{ opacity: 0, y: -50 }}
                animate={hasMounted ? { opacity: 0, y: -50 } : { opacity: 1, y: 0 }}
                transition={bezierTransition}
            />
            <motion.img
                src={sky}  // sky bottom left
                alt="Datrimony"
                className="absolute bottom-1/3 left-[15%] transform translate-x-[-50%] w-[100px] md:w-[200px]"
                initial={{ opacity: 0, y: 50 }}
                animate={hasMounted ? { opacity: 0, y: 50 } : { opacity: 1, y: 0 }}
                transition={bezierTransition}
            />
            <motion.img
                src={sky}  // sky bottom right
                alt="Datrimony"
                className="absolute bottom-1/4 right-[20%] w-[80px] md:w-[100px]"
                initial={{ opacity: 0, y: 50 }}
                animate={hasMounted ? { opacity: 0, y: 50 } : { opacity: 1, y: 0 }}
                transition={bezierTransition}
            />

            {/* Text Section */}
            <motion.div
                className="absolute text-center text-white z-20 flex flex-col items-center mb-[13%]"
                initial={{ opacity: 0 }}
                animate={hasMounted ? { opacity: 0 } : { opacity: 1 }}
                transition={bezierTransition}
            >
                <h1 className="mb-4 text-2xl font-bold md:text-4xl">
                    No More Matrimony. No More <br /> Dating. Let’s Datrimony!
                </h1>
                <p className="mb-4 text-sm md:text-lg">
                    Discover the Modern Way to Connect – Where Dating Meets Matrimony
                </p>
                <motion.a
                    href={AUTH.SIGNUP}
                    className="inline-block px-6 py-2 mt-2 text-white transition-transform transform bg-black rounded-full hover:scale-105"
                    whileHover={{ scale: 1.1 }}
                    transition={bezierTransition}
                >
                    Join the Revolution Now!
                </motion.a>
            </motion.div>

            {/* Middle person image */}
            <motion.img
                src={person}
                alt="Datrimony"
                className="absolute bottom-0 z-10 w-[250px] md:w-[300px]"
                initial={{ scale: 0, opacity: 0 }}
                animate={hasMounted ? { scale: 0, opacity: 0 } : { scale: 1, opacity: 1 }}
                transition={{ ...bezierTransition, delay: 0.2 }}
            />
        </div>
    );
};

export default Index;
