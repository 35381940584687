import { auth } from "..";


export const getInterestBasedMatches = async () => {
    const res = await auth({
        method: "GET",
        url: '/matches/interest-matches',
    });
    return res;
};

export const getLookingForBasedMatches = async (title) => {
    const res = await auth({
        method: "GET",
        url: `/matches/looking-for-matches?looking_for=${title}`,
    });
    return res;
};

export const getHandpickedMatches = async () => {
    const res = await auth({
        method: "GET",
        url: `/matches/handpicked-matches`,
    });
    return res;
};