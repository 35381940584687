import { auth } from "..";



export const razorPayPayment = async (data) => {
    const res = await auth({
        method: "PUT",
        url: "/wallet/payment-status",
        data
    });
    return res
}

export const paymentCancellationReason = async (id, data) => {
    const res = await auth({
        method: "POST",
        url: `/payment/cancel/${id}`,
        data
    });
    return res
};