import React, { useState, memo, useEffect, useRef } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import icon from '../../../assets/discover/couplesquad.png';
import icon2 from '../../../assets/discover/search.svg';
import icon8 from '../../../assets/discover/downarrow.svg';
import FilterComponent from '../../filters/NavBarMainFilter';
import Notification from '../../notification';
import { useDispatch, useSelector } from 'react-redux';
import { EVENTS, MAIN_NAV, SETTINGS, WALLET } from '../../../shared/router';
import { connectSocket, markAsOffline, markAsOnline } from '../../../socket/SocketManager';
import avatar from "../../../assets/avatar.png"
import { encodeToBase64 } from '../../../utils/encodeDecode';
import { getEventLocations } from '../../../apis/eventFilter';
import { capitalize } from 'shared/textFormats';

// Assets
import chatActiveIcon from 'assets/navbar/main/icons/chatActive.svg'
import chatInactiveIcon from 'assets/navbar/main/icons/chatInactive.svg'
import connectionActiveIcon from 'assets/navbar/main/icons/connectionActive.svg'
import connectionInactiveIcon from 'assets/navbar/main/icons/connectionInactive.svg'
import discoverActiveIcon from 'assets/navbar/main/icons/discoverActive.svg'
import discoverInactiveIcon from 'assets/navbar/main/icons/discoverInactive.svg'
import notificationsInactiveIcon from 'assets/navbar/main/icons/notificationsInactive.svg'
import filterIcon from 'assets/navbar/main/icons/filter.svg'
import { setUserSelectedLocation } from '../../../redux/slice/selectedLocation';
import { setPosition } from '../../../redux/slice/guid';
import { guidPosition } from 'utils/helper';

function Index({ isEventFilterPage = false, handleOnChange }) {
  const chatButtonRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isGuide, currentStep } = useSelector((state) => state.guide);
  const userSelectedLocation = useSelector(state => state.userSelectedLocation.location);
  const userInfo = useSelector((state) => state.userProfile);

  const [showFilter, setShowFilter] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [showDropDown, setShowDropDown] = useState(false)

  const [search, setSearch] = useState(false)
  const [locations, setLocations] = useState()

  const [currentPage, setCurrentPage] = useState("discover")


  const dropDownItems = [
    { name: "Settings", location: SETTINGS.SETTINGS },
    { name: "Wallet", location: WALLET.WALLET },
    { name: "Logout", location: SETTINGS.LOGOUT },
  ]

  const sidebarItems = [
    { name: 'Discover', location: MAIN_NAV.DISCOVER },
    { name: 'Connection', location: MAIN_NAV.CONNECTION },
    { name: 'Chat', location: MAIN_NAV.CHAT },
  ];

  const location = useLocation();

  useEffect(() => {
    const currentSidebarItem = sidebarItems.find(item => location.pathname.startsWith(item.location));
    if (currentSidebarItem) {
      setCurrentPage(currentSidebarItem.name);
    } else {
      setCurrentPage(undefined);
    }
  }, [location]);


  useEffect(() => {
    const session = localStorage.getItem('session')

    const goOnline = () => {
      if (session) {
        connectSocket()
        markAsOnline()
      }
    };
    const goOffline = () => {
      markAsOffline();
      sessionStorage.removeItem('onlineSet');
    };
    window.addEventListener('beforeunload', goOffline);
    window.addEventListener('unload', goOffline);
    goOnline();

    return () => {
      window.removeEventListener('beforeunload', goOffline);
      window.removeEventListener('unload', goOffline);
    };

  }, []);


  const handleFilterVisibility = () => {
    setShowFilter(!showFilter);
  };


  const handleSubmit = (type, value) => {
    if (isEventFilterPage) {
      if (type === 'search') {
        handleOnChange("search", search);
      } else if (type === 'city') {
        handleOnChange("city", value);
      }
    } else {
      const filterParams = new URLSearchParams();
      if (type === 'search') {
        filterParams.append('search', encodeToBase64(search));
      } else if (type === 'city') {
        filterParams.append('city', encodeToBase64(value));
      }
      navigate(`${EVENTS.EVENT_FILTER}?${filterParams.toString()}`);
    }
  }

  const fetchEventLocations = async () => {
    const res = await getEventLocations()
    setLocations(res?.data)
  }

  useEffect(() => {
    fetchEventLocations()
  }, [])

  const navbarMenu = [
    { title: 'Discover', img: [discoverActiveIcon, discoverInactiveIcon], action: () => navigate(MAIN_NAV.DISCOVER) },
    { title: 'Connection', img: [connectionActiveIcon, connectionInactiveIcon], action: () => navigate(MAIN_NAV.CONNECTION) },
    { title: 'Chat', img: [chatActiveIcon, chatInactiveIcon], action: () => navigate(MAIN_NAV.CHAT) },
    { title: 'Notification', img: [notificationsInactiveIcon, notificationsInactiveIcon], action: () => setShowNotification(true) },
    { title: 'Filter', img: [filterIcon, filterIcon], action: () => setShowFilter(true) },
  ]


  useEffect(() => {
    if (currentStep === 0) {
      const updatePosition = () => {
        if (chatButtonRef.current) {
          const additionalPosition = { top: 10, left: 70, }
          guidPosition(chatButtonRef, dispatch, setPosition, additionalPosition);
        }
      };

      // Initial calculation and recalculate on window resize
      updatePosition();
      window.addEventListener('resize', updatePosition);
      return () => window.removeEventListener('resize', updatePosition);
    }
  }, [dispatch, currentStep]);


  return (
    <div className='relative bg-white '>

      <div className='w-full h-[80px] bg-white border-b border-div_border'>
        <div className=' md:w-full px-2 lg:px-0 lg:w-[84.5%] h-[76px] flex justify-between items-center mx-auto bg-white'>
          <Link to={MAIN_NAV.HOME}>
            <div className='w-[118px] h-[66px] cursor-pointer'>
              <img src={icon} alt='couplesquad' />
            </div>
          </Link>
          <div className='md:w-[200px] lg:w-[450px] h-[52px] bg-gray-100 flex justify-center items-center gap-2 py-2'>
            <img src={icon2} alt='search' className='w-[28px] h-[28px] cursor-pointer' />
            <input onChange={(e) => setSearch(e.target.value)} type='text' placeholder='Search events' className='md:w-[150px] lg:w-[366px] h-[22px] text-gray-700 border-none bg-gray-100 focus:outline-none'
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleSubmit('search');
                }
              }} />
          </div>

          <select onChange={(e) => {
            dispatch(setUserSelectedLocation(e.target.value));
            handleSubmit('city', e.target.value);
          }}
            value={userSelectedLocation}
            className='p-2 text-black bg-white cursor-pointer rounded-3xl focus:outline-none'
          >
            {locations?.map(location => (
              <option key={location.id} value={location.id}>
                {capitalize(location.name)}
              </option>
            ))}
          </select>

          <div className='flex items-center justify-center gap-3'>

            {/* Navbar Menus */}
            {navbarMenu.map((item, index) => (
              <React.Fragment key={index}>
                <button ref={item?.title === "Chat" ? chatButtonRef : null}

                  className={`md:w-[50px] lg:w-[74px] h-[64px] flex flex-col justify-center items-center cursor-pointer ${(item?.title === "Chat" && currentStep === 0 && isGuide) ? "z-[110] bg-white rounded-full" : ""
                    }`}
                  onClick={item?.action}
                >
                  <img src={currentPage === item?.title ? item?.img[0] : item?.img[1]} alt="" />

                  <p
                    className={`font-normal text-[12px] ${currentPage === item?.title
                      ? "text-[#F83758]"
                      : "text-[#5D6577]"
                      }`}
                  >
                    {item?.title}
                  </p>
                </button>
              </React.Fragment>
            ))}



            {/* DropDown and Pic */}
            <div className='md:w-[50px] lg:w-[78px] h-[50px] z-50'>
              <div className="flex gap-1 cursor-pointer" onClick={() => setShowDropDown(!showDropDown)}>
                <img src={userInfo.photo ? userInfo.photo : avatar} alt='profile' className='w-[50px] h-[50px] rounded-full' />
                <img src={icon8} alt='arrow' className='w-[10px] h-[5px] mt-10' />
              </div>

              {showDropDown &&
                <div className='text-black bg-[#f4f0f0] rounded-md w-36 mt-2 ms-10 border-b border-b-gray-300 z-50'>
                  {dropDownItems.map((item) => (
                    <Link to={item.location}>
                      <p className='w-full p-2 hover:bg-[#d1cfcf]'>{item.name}</p>
                    </Link>

                  ))}
                </div>
              }

            </div>

          </div>
        </div>

        {/* Show Filter */}
        {showFilter && (
          <div className='fixed inset-0 z-50 flex'>
            <div className='flex-grow bg-gray-500 opacity-50'></div>
            <div className='w-[650px] h-full bg-black bg-opacity-50'>
              <FilterComponent handleShowFilter={handleFilterVisibility} />
            </div>
          </div>
        )}

        {/* Show Notification */}
        {showNotification && (
          <div className='fixed inset-0 z-50 flex'>
            <div className='flex-grow bg-gray-500 opacity-50'></div>
            <div className='w-[400px] h-full bg-gray-500 bg-opacity-50'>
              <Notification handleClose={() => setShowNotification(false)} />
            </div>
          </div>
        )}

      </div>
    </div>
  );
}

export default memo(Index);