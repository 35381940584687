import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isVisible: false,
    id: null,
};

const popupSlice = createSlice({
    name: 'popup',
    initialState,
    reducers: {
        showPopup: (state, action) => {
            state.isVisible = true;
            state.id = action.payload.id;
        },
        resetPopup: (state) => {
            state.isVisible = false;
            state.id = null;
        },
    },
});

export const { showPopup, resetPopup } = popupSlice.actions;

export default popupSlice.reducer;