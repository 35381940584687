import { noAuth } from '..';

export const sendOtp = async (data) => {
    const res = await noAuth({
        method: 'POST',
        url: '/auth/send-otp/phone?type=signup',
        data,
    });
    return res;
};

export const signup = async (data) => {
    const res = await noAuth({
        method: 'POST',
        url: '/auth/signup',
        data,
    });

    return res;
}