import { Link } from 'react-router-dom';
import React, { useState } from 'react';
import { SITE_INTO, LICENSE } from '../../../../shared/router';

// Assets
import csLogo from 'assets/footer/images/csLogoWhite.webp'
import phone from 'assets/footer/icons/phone.webp'
import email from 'assets/footer/icons/email.webp'
import location from 'assets/footer/icons/location.webp'

// Social Media icons
import facebookIcon from 'assets/socialMediaIcons/white/facebook.png'
import instagramIcon from 'assets/socialMediaIcons/white/instagram.png'
import linkedInIcon from 'assets/socialMediaIcons/white/linkedIn.png'
import mediumIcon from 'assets/socialMediaIcons/white/medium.png'
import pinterestIcon from 'assets/socialMediaIcons/white/pinterest.png'
import twitterIcon from 'assets/socialMediaIcons/white/twitter.png'
import youTubeIcon from 'assets/socialMediaIcons/white/youTube.png'
import { LazyImage } from 'components/image';

function Index() {
    const [navDropdown, setNavDropdown] = useState(false);
    const [licenseDropdown, setLicenseDropdown] = useState(false);
    const [contactDropdown, setContactDropdown] = useState(false);


    const socialMedia = [
        { icon: linkedInIcon, url: 'https://www.linkedin.com/company/couplesquad/' },
        { icon: facebookIcon, url: 'https://www.facebook.com/wearecouplesquad' },
        { icon: instagramIcon, url: 'https://www.instagram.com/wearecouplesquad/' },
        { icon: youTubeIcon, url: 'https://www.youtube.com/@wearecouplesquad' },
        { icon: twitterIcon, url: 'https://twitter.com/wecouplesquad' },
        { icon: mediumIcon, url: 'https://medium.com/@wearecouplesquad' },
        { icon: pinterestIcon, url: 'https://in.pinterest.com/couplesquad/' },
    ];

    return (
        <div className="flex justify-start w-full">
            <div className="w-full">
                <footer className="flex flex-col items-start p-10 text-white footer">
                    <img src={csLogo} alt="Logo" className="w-auto h-20 mb-2" />
                    <p className='w-full mb-2 leading-6 text-items-start'>Couple Squad is the first event-based social community for singles and couples, connecting Single Indian Professionals through friendly events. Our events range from single mixers to professional networking, ensuring there's something for everyone. We also prioritize mental well-being, offering relationship coaching as a key service.</p>
                    <div className="flex flex-wrap items-center gap-10 mt-5">
                        {socialMedia?.map((item, index) => (
                            <Link key={index} to={item?.url}>
                                <LazyImage src={item?.icon} className={'w-[30px] h-[30px] md:w-auto  md:h-auto cursor-pointer'} />
                            </Link>
                        ))}
                    </div>

                    <div className="flex flex-col items-start w-full text-white">
                        <div className="mb-2">
                            <button onClick={() => setNavDropdown(!navDropdown)} className="flex items-center justify-between w-full text-lg font-semibold text-white">
                                Navigation
                                <svg
                                    className={`fill-current h-5 w-5 ms-3 transform ${navDropdown ? 'rotate-180' : 'rotate-0'}`}
                                    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                    <path d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" />
                                </svg>
                            </button>
                            {navDropdown && (
                                <div className="flex flex-col mt-3 space-y-5">
                                    <Link to={SITE_INTO.LANDING} className="text-sm link link-hover">Home</Link>
                                    <Link to={SITE_INTO.ABOUT} className="text-sm link link-hover">About Us</Link>
                                    <Link to={SITE_INTO.DATRIMONY} className="text-sm link link-hover">Datrimony™</Link>
                                    <Link to={SITE_INTO.EVENT_HOME} className="text-sm link link-hover">Events</Link>
                                    <Link to={SITE_INTO.HOST_WITH_US} className="text-sm link link-hover">Host With Us</Link>
                                </div>
                            )}
                        </div>

                        <div className="mb-2">
                            <button onClick={() => setLicenseDropdown(!licenseDropdown)} className="flex items-center justify-between w-full text-lg font-semibold text-white">
                                Licence
                                <svg
                                    className={`fill-current h-5 w-5 ms-3 transform ${licenseDropdown ? 'rotate-180' : 'rotate-0'}`}
                                    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                    <path d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" />
                                </svg>
                            </button>
                            {licenseDropdown && (
                                <div className="flex flex-col mt-3 space-y-5">
                                    <Link to={LICENSE.PRIVACY_POLICIES} className="text-sm link link-hover">Privacy Policy</Link>
                                    <Link to={LICENSE.TERMS_AND_CONDITIONS} className="text-sm link link-hover">Terms & Conditions</Link>
                                </div>
                            )}
                        </div>
                        <div className="mb-2">
                            <div className="flex items-center w-full text-white">
                                <button onClick={() => setContactDropdown(!contactDropdown)} className="flex items-center justify-start w-full text-lg font-semibold">
                                    Contact
                                    <svg
                                        className={`fill-current h-5 w-5 ms-3 transform ${contactDropdown ? 'rotate-180' : 'rotate-0'}`}
                                        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                        <path d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" />
                                    </svg>
                                </button>
                            </div>


                            {contactDropdown && (
                                <div className="flex flex-col mt-3 space-y-5 text-start">
                                    <button className="flex items-center text-sm">
                                        <img src={phone} alt="" className='me-2' />
                                        Toll-Free: 1800 203 9717
                                    </button>
                                    <button className="flex items-center text-sm">
                                        <img src={email} alt="" className='me-2' />
                                        hello@couplesquad.com</button>
                                    <button className="flex text-sm text-start w-52">
                                        <img src={location} alt="" className='me-2' />
                                        No B28, Centre Space Co-working, Kottayam, Kerala 686601</button>
                                </div>
                            )}
                        </div>
                    </div>
                </footer>
            </div>
        </div>
    )
}

export default React.memo(Index);
