import React from 'react'
const Index = ({ label, isChecked, handleOnChange }) => {


    return (
        <label className={`w-52 inline-flex items-center cursor-pointer p-2 ${isChecked ? 'bg-red-50' : 'bg-gray-100'} transition-colors`}>
            <input
                type="checkbox"
                className="hidden rounded-md"
                checked={isChecked}
                onChange={handleOnChange}
            />
            <div className={`w-6 h-6 flex items-center justify-center rounded-md ${isChecked ? 'bg-red-400' : 'bg-gray-300'} transition-colors shadow-sm`}>
                {isChecked && (
                    <svg className="w-4 h-4 text-white" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={3} d="M5 13l4 4L19 7" />
                    </svg>
                )}
            </div>
            {label && <span className="ml-2 text-sm">{label}</span>}
        </label>

    );
};

export default React.memo(Index);
