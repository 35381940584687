import { Suspense, lazy, useEffect, useState, useCallback } from "react"
import { toast } from "react-toastify"

import PlanCard from "./components/PlanCard"

import tickIcon from "../../../assets/wallet/icons/tick.svg"
import crossIcon from "../../../assets/wallet/icons/crossIcon.svg"
import alertIcon from "../../../assets/wallet/icons/alertIcon.svg"
import { fetchAllPlans, fetchDurationAndPrice, fetchPlanComparison, paymentStatus, upgradePlan } from "../../../apis/wallet"
import { useNavigate } from "react-router-dom"
import { WALLET } from "../../../shared/router"

import arrow from 'assets/wallet/icons/leftArrow.svg'

const RazorPay = lazy(() => import("components/payment").then(module => ({ default: module.RazorPay })))
const PaymentSummary = lazy(() => import("components/payment").then(module => ({ default: module.PaymentSummary })))
const PaymentSuccessPopUp = lazy(() => import("components/popUps").then(module => ({ default: module.PaymentSuccess })))


function Index() {

    const navigate = useNavigate()
    const [plans, setPlans] = useState([]);
    const [index1, setIndex1] = useState(0)
    const [index2, setIndex2] = useState(1)
    const [planComparison, setPlanComparison] = useState()
    const [priceAndDuration, setPriceAndDuration] = useState([])
    const [razoorpayPaymentStatus, setRazoorpayPaymentStatus] = useState(false)
    const [visible, setVisible] = useState({
        planPage: true,
        paymentSummary: false,
        razorpay: false,
        paymentSuccess: false,
    })
    const [paymentStructure, setPaymentStructure] = useState()
    const [selectedPlan, setSelectedPlan] = useState()
    const [paymentData, setPaymentData] = useState({
        orderId: null,
        total: null,
        paymentId: null,
        status: null,
        gst: null,
        coupon: null,
        discounted_amount: 0
    })

    const getAllPlans = async () => {
        const res = await fetchAllPlans();
        // const filteredPlans = res.data.filter(plan => plan.name !== "CONNECT");
        if (res && res.success) {
            setPlans(res.data || []);
            if (res.data.length > 1) {
                setIndex2(1)
            }
        }
    };

    const getMonthsAndAmountOfPlan = async () => {
        const res1 = await fetchDurationAndPrice(plans[index1].id)
        const res2 = await fetchDurationAndPrice(plans[index2].id)
        setPriceAndDuration([res1?.data, res2?.data])
    }

    const handleComparePlans = async (planOne, planTwo) => {
        const res = await fetchPlanComparison(planOne, planTwo)
        setPlanComparison(res?.data)
    }


    // Function to handle left navigation
    const handleLeftNavigation = () => {
        setIndex1((prevIndex1) => (prevIndex1 - 1 + plans.length) % plans.length);
        setIndex2((prevIndex2) => (prevIndex2 - 1 + plans.length) % plans.length);
    };

    // Function to handle right navigation
    const handleRightNavigation = () => {
        setIndex1((prevIndex1) => (prevIndex1 + 1) % plans.length);
        setIndex2((prevIndex2) => (prevIndex2 + 1) % plans.length);
    };


    function formatComparisonValue(value, name) {
        switch (value.type) {
            case 'unlimited':
                return 'Unlimited';
            case 'limit':
                // Add 'Hr' for specific names
                if (name === 'Expiry Time for Connection Requests' || name === 'Expiry Time for Meeting Requests') {
                    return `${value.limit}`;
                }
                return `${value.limit}`;
            case 'coin':
                return `${value.expiry}`;
            default:
                return 'N/A';
        }
    }

    useEffect(() => {
        getAllPlans();
    }, []);

    useEffect(() => {
        if (plans.length > 1) {
            handleComparePlans(plans[index1].id, plans[index2].id);
            getMonthsAndAmountOfPlan()
        }
    }, [index1, index2, plans]);


    const handleChangeVisibility = useCallback((key) => {
        setVisible(prev => ({
            ...Object.keys(prev).reduce((acc, currentKey) => {
                acc[currentKey] = false;
                return acc;
            }, {}),
            [key]: true
        }));
    }, []);

    const onClickHandlePurchase = async (value) => {
        if (value.amount > 0) {
            const res = await upgradePlan({ planId: value.id })
            const data = { ...res.payment_structure, id: res?.data?.id }
            setPaymentData(prev => ({ ...prev, orderId: res?.data?.id }))
            setPaymentStructure(data)
            setSelectedPlan(value)
            handleChangeVisibility("paymentSummary")
        } else {
            toast.error("Select a valid duration")
        }
    }

    const updateThePayment = async (data) => {
        const { total, ...other } = data
        const res = await paymentStatus(other)
        if (res?.success) {
            handleChangeVisibility('paymentSuccess')
        }
    }

    const razorpaySuccess = async (value) => {
        if (value?.status === 'failed') {
            handleChangeVisibility('paymentSuccess')
            setRazoorpayPaymentStatus(true)
        } else {
            const data = {
                ...paymentData,
                paymentId: value.paymentId,
                status: value.status
            }
            updateThePayment(data)
        }
    }

    const handlePay = (value) => {
        setPaymentData(prev => ({ ...prev, ...value }))
        handleChangeVisibility("razorpay")
    }

    const handleDoneBtn = () => {
        navigate(WALLET.WALLET)
    }


    return (
        <div className="flex flex-col h-full bg-[#f9f9f9]">


            <div className="w-full mx-auto border">

                {visible.razorpay && (
                    <Suspense fallback={<div>Loading...</div>}>
                        <RazorPay amount={paymentData.total} orderId={paymentData.orderId} handleOnclick={(value) => razorpaySuccess(value)} />
                    </Suspense>
                )}
                {visible.paymentSuccess && (
                    <Suspense fallback={<div>Loading...</div>}>
                        <PaymentSuccessPopUp handleDoneBtn={handleDoneBtn} isFailed={razoorpayPaymentStatus} data={paymentStructure} />
                    </Suspense>
                )}
                {visible.paymentSummary && (
                    <Suspense fallback={<div>Loading...</div>}>
                        <PaymentSummary data={paymentStructure} handlePay={(value) => handlePay(value)} />
                    </Suspense>
                )}
                {visible.planPage && (
                    <>
                        <div className="flex items-center justify-center">
                            <img src={arrow} alt="" className="w-[2rem] h-[2rem] md:w-auto md:h-auto md:mr-10 -me-3 cursor-pointer" onClick={handleLeftNavigation} />

                            {plans.length > 1 && (
                                <div className="flex h-[237px] w-fit">
                                    <PlanCard name={plans[index1].name} currentPlan={plans[index1].active} data={priceAndDuration[0]} handlePurchase={(value) => onClickHandlePurchase(value)} />
                                    <div className="hidden lg:block">
                                        <PlanCard name={plans[index2].name} currentPlan={plans[index2].active} data={priceAndDuration[1]} handlePurchase={(value) => onClickHandlePurchase(value)} />
                                    </div>
                                </div>
                            )}

                            <img src={arrow} alt="" className="w-[2rem] h-[2rem] md:w-auto md:h-auto rotate-180 md:ml-10 -ms-3 cursor-pointer" onClick={handleRightNavigation} />
                        </div>


                        {/*plan Content Section info */}
                        <div className="flex ms-5">
                            <div className="w-full mt-32">
                                <div className="flex items-center text-black">
                                    <p className="w-32 md:w-[290px] text-sm lg:text-[28px] font-bold">What you get</p>
                                    <p className="w-[5rem] md:w-[290px] text-center text-sm lg:text-2xl font-semibold"></p>
                                    <p className="w-[5rem] md:w-[290px] text-center text-sm lg:text-2xl font-semibold">{plans[index1]?.name}</p>
                                    <p className="w-[5rem] md:w-[290px] text-center text-sm lg:text-2xl font-semibold">{plans[index2]?.name}</p>
                                </div>
                                {planComparison?.access &&
                                    planComparison?.access.map((item, index) => (
                                        <div key={index} className="flex items-center mt-7">
                                            <p className="text-sm font-semibold w-36 lg:text-xl md:w-[290px]">{item?.name}</p>
                                            <p className="w-[5rem] md:w-[290px] text-center flex items-center justify-center">
                                                <span className="flex items-center justify-center"><img src={alertIcon} alt="" title={item?.info} className="cursor-pointer" /></span>
                                            </p>
                                            <p className="w-[5rem] md:w-[290px] text-center flex items-center justify-center">
                                                <img src={item?.first_value === true ? tickIcon : crossIcon} alt="" />
                                            </p>
                                            <p className="w-[5rem] md:w-[290px] text-center flex items-center justify-center">
                                                <img src={item?.second_value === true ? tickIcon : crossIcon} alt="" />
                                            </p>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>




                        <div className="w-full h-[1px] bg-gray-300 mt-10"></div>

                        {/*user Content Section */}
                        <div className="flex mb-20 ms-5">
                            <div className="mt-10">
                                <div className="flex text-black">
                                    <p className="w-40 md:w-[250px] lg:w-[290px] text-lg lg:text-[28px] font-bold">User Limitations</p>
                                </div>
                                {planComparison?.limits &&
                                    planComparison?.limits.map((item, index) => (
                                        <div key={index} className="flex items-center justify-center mt-7">
                                            <p className="w-32 text-base font-semibold lg:text-xl md:w-60 text-start">{item.name}</p>
                                            <p className="w-[5.5rem] md:w-[250px] lg:w-[290px] text-center ">{formatComparisonValue(item.first_value, item.name)}</p>
                                            <p className="w-[5.5rem] md:w-[250px] lg:w-[290px] text-center ">{formatComparisonValue(item.second_value, item.name)}</p>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>

                    </>
                )}
            </div>
            {/* </div> */}
        </div>
    )
}

export default Index