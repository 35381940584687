import React, { Suspense, lazy, useEffect, useState } from 'react'
import allLanguages from 'utils/constant/languages'
import { toast } from 'react-toastify'
import { Switch } from 'antd';
import { updateBasicFilter, GetBasicFilter } from 'apis/filter';
import { SelectAndView, DropDownSelector, RangeSliderDoublePointer } from 'components/UI';
import Interests from "../../userBasicInformation/Interests"

const PlanUpgrade = lazy(() => import("components/popUps").then(module => ({ default: module.PlanUpgrade })))

function Index({ closeSlide = false }) {

  const [userSelectedInfo, setUserSelectedInfo] = useState({
    selectedMotherTongue: '',
    age_from: 18,
    age_to: 50,
    age_expansion: false,
    height_from: 150,
    height_to: 200,
    height_expansion: false,
    distance_from: 2,
    distance_to: 200,
    languages_known: [],
    mother_tongue: '',
    interests: [],
  })

  const [relationShipStatus, setRelationShipStatus] = useState()
  const [showOptions, setShowOptions] = useState({
    interest: false,
    upgradePlan: false,
    planPopUp: false,
  })

  const fetchBasicFilterDetails = (async () => {
    const res = await GetBasicFilter()
    setUserSelectedInfo({ ...res?.data?.response?.basic })
    setRelationShipStatus(res?.data?.response?.userId?.personal?.relationship_status);
    if (res?.data?.basic_preferences_access === false) {
      setShowOptions(prev => ({ ...prev, upgradePlan: true }))
    }
  })


  useEffect(() => {
    fetchBasicFilterDetails();
  }, [showOptions.upgradePlan]);


  const handleSaveBtn = async () => {
    const toastId = toast.loading("Applying the Filter")
    const res = await updateBasicFilter(userSelectedInfo)
    toast.dismiss(toastId);
    if (res?.success) toast.success("Updated Filter")
    if (closeSlide) closeSlide()
  }


  // State Updating
  const updateUserInfo = (field, value) => {
    if (field === 'languages_known' && value?.length === 6) {
      toast.error("You can select only 5 languages.");
    } else {
      if (showOptions.upgradePlan) {
        setShowOptions(prev => ({ ...prev, planPopUp: true }))
      } else {
        setUserSelectedInfo(prev => ({ ...prev, [field]: value }));
      }
    }
  };

  const updateRangeInfo = (fieldFrom, fieldTo, value) => {
    if (showOptions.upgradePlan) {
      setShowOptions(prev => ({ ...prev, planPopUp: true }))
    } else {
      setUserSelectedInfo(prev => ({
        ...prev,
        [fieldFrom]: value[0],
        [fieldTo]: value[1],
      }));
    }
  };

  const updateInterests = (newInterest) => {
    if (showOptions.upgradePlan) {
      setShowOptions(prev => ({ ...prev, planPopUp: true }))
    } else {
      setUserSelectedInfo(prevState => {
        const interests = prevState?.interests;
        if (interests?.includes(newInterest)) {
          return { ...prevState, interests: interests?.filter(i => i !== newInterest) };
        } else {
          return { ...prevState, interests: [...interests, newInterest] };
        }
      });
    }
  };

  const resetFilter = () => {
    setUserSelectedInfo({
      age_from: 18,
      age_to: 50,
      age_expansion: false,
      height_from: 150,
      height_to: 200,
      height_expansion: false,
      distance_from: 2,
      distance_to: 200,
      languages_known: [],
      mother_tongue: '',
      interests: [],
    })
  }


  return (
    <div className='relative w-full'>

      {showOptions.planPopUp &&
        <div className="absolute inset-0 z-50 flex items-center justify-center w-full h-full bg-white bg-opacity-75">
          <Suspense children={<PlanUpgrade closeBtn={() => setShowOptions(prev => ({ ...prev, planPopUp: false }))} />} fallback={<div>loading...</div>} />
        </div>
      }

      {showOptions.interest ? (
        <Interests
          state={userSelectedInfo.interests}
          handleStateUpdate={updateInterests}
          handleLetsGo={() => setShowOptions(prev => ({ ...prev, interest: false }))}
        />
      ) : (
        <>
          <p className='font-semibold text-[21px] text-black'>Basic Filters</p>

          {/* Interest */}
          <SelectAndView
            label={"Interest"}
            selectedValue={userSelectedInfo.interests}
            onLabelClick={() => {
              if (showOptions.upgradePlan) {
                setShowOptions(prev => ({ ...prev, planPopUp: true }))
              } else {
                setShowOptions(prev => ({ ...prev, interest: true }))
              }

            }}
          />


          {/* Age */}
          <div className='w-full my-5'>
            <p className='mb-2 text-lg font-normal text-text_gray'>Age</p>
            <div className='w-full h-32 border-2 border-gray-300 rounded cursor-pointer '>
              <div className='flex flex-col justify-center mt-5'>
                <div className='flex flex-col justify-center px-4'>
                  <p className='text-black text-[16px] font-normal'>between {userSelectedInfo?.age_from} to {userSelectedInfo?.age_to} </p>
                  <RangeSliderDoublePointer
                    minMaxRange={[18, 50]}
                    selectedMinMaxValue={[userSelectedInfo?.age_from, userSelectedInfo?.age_to]}
                    onRangeChange={(value) => updateRangeInfo("age_from", "age_to", value)}
                  />
                </div>
                <div className='flex justify-between px-4'>
                  <p className='text-base font-normal text-text_gray'>
                    See people 2 years either side if I run out
                  </p>
                  <Switch checked={userSelectedInfo?.age_expansion} onChange={() => setUserSelectedInfo(prev => ({ ...prev, age_expansion: !prev?.age_expansion }))} className={userSelectedInfo?.age_expansion ? 'bg-gray-700' : 'bg-gray-400'} />
                </div>
              </div>
            </div>
          </div>

          {/* Height */}
          {relationShipStatus === 'single' &&
            <div className='w-full my-5'>
              <p className='mb-2 text-lg font-normal text-text_gray'>Height</p>
              <div className='w-full h-32 border-2 border-gray-300 rounded cursor-pointer'>
                <div className='flex flex-col justify-center mt-4'>
                  <div className='flex flex-col justify-center px-4'>
                    <p className='text-black text-[16px] font-normal'>Selected Height Range: {userSelectedInfo?.height_from} cm to {userSelectedInfo?.height_to} cm</p>
                    <RangeSliderDoublePointer
                      minMaxRange={[150, 200]}
                      selectedMinMaxValue={[userSelectedInfo?.height_from, userSelectedInfo?.height_to]}
                      onRangeChange={(value) => updateRangeInfo("height_from", "height_to", value)}
                    />
                  </div>
                  <div className='flex justify-between px-4'>
                    <p className='text-base font-normal text-text_gray'>
                      See people 2 cm height either side if I run out
                    </p>
                    <Switch checked={userSelectedInfo?.height_expansion} onChange={() => setUserSelectedInfo(prev => ({ ...prev, height_expansion: !prev?.height_expansion }))} className={userSelectedInfo?.height_expansion ? 'bg-gray-700' : 'bg-gray-400'} />
                  </div>
                </div>
              </div>
            </div>
          }

          {/* Distance */}
          <div className='w-full my-5'>
            <p className='font-semibold text-[21px] text-black'>Distance</p>
            {/* <p className='font-normal text-text_gray'>Make changes to your account</p> */}
          </div>
          <div className='w-full h-24 mb-5 border-2 border-gray-300 rounded'>
            <div className='flex flex-col justify-center px-4'>
              <p className='mt-3 text-base font-normal text-black'>Range: {userSelectedInfo?.distance_from} km to {userSelectedInfo?.distance_to} km</p>
              <RangeSliderDoublePointer
                minMaxRange={[2, 200]}
                selectedMinMaxValue={[userSelectedInfo?.distance_from, userSelectedInfo?.distance_to]}
                onRangeChange={(value) => updateRangeInfo("distance_from", "distance_to", value)}
              />
            </div>
          </div>

          {/* Languages Known */}
          <DropDownSelector
            label={"Languages Known"}
            selectedValue={userSelectedInfo?.languages_known}
            placeholderValue={"Select language"}
            options={allLanguages?.map(language => ({
              label: language?.code,
              name: language?.name
            }))}
            handleOnChange={(value) => updateUserInfo("languages_known", value)}
            mode={'multiple'}
          />

          {/* Mother Tongue */}
          <DropDownSelector
            label={"Mothertongue"}
            selectedValue={userSelectedInfo?.mother_tongue}
            placeholderValue={"Select language"}
            options={allLanguages?.map(language => ({
              label: language?.code,
              name: language?.name
            }))}
            handleOnChange={(value) => updateUserInfo("mother_tongue", value)}
          />

          {/* Save BTN */}
          <div className="h-[70px] w-full mt-5 flex items-center justify-between ">
            <button
              className={`w-[45%] h-[60px] ${showOptions.upgradePlan ? 'bg-[#fed7de] pointer-events-none cursor-not-allowed text-white' : 'cursor-pointer text-black border border-black'}  flex justify-center items-center relative `}
              onClick={resetFilter}
            >
              Reset
            </button>

            <button
              className={`w-[45%] h-[60px] ${showOptions.upgradePlan ? 'bg-[#fed7de] pointer-events-none cursor-not-allowed' : 'bg-brandRed cursor-pointer'} flex justify-center items-center text-white relative`}
              onClick={showOptions.upgradePlan ? undefined : handleSaveBtn}
            >
              Save
            </button>

          </div>
        </>
      )
      }
    </div >
  )
}

export default React.memo(Index)