import React from "react";

function Index({ selectedBtnColor, selectedTextColor, selected, onChange, item }) {
    return (
        <label className="flex items-center space-x-2 cursor-pointer" onClick={onChange}>
            {/* Outer circle */}
            <div
                className={`w-5 h-5 rounded-full flex justify-center items-center border-2 ${selected ? selectedBtnColor : "border-gray-400"
                    }`}
            >
                {/* Inner dot */}
                {selected && (
                    <div className={`w-3 h-3 rounded-full ${selectedBtnColor.replace('border-', 'bg-')}`}></div>
                )}
            </div>
            {/* Label text */}
            <span className={`${selected ? selectedTextColor : "text-gray-700"}`}>
                {item}
            </span>
            {/* Hidden radio input */}
            <input
                type="radio"
                value={item}
                className="hidden"
                checked={selected}
                onChange={onChange}
            />
        </label>
    );
}

export default Index;
