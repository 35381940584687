import React, { useState, lazy, Suspense, useEffect, useCallback } from 'react'
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

import ImgPreview from "../../discover/imgPreview"

// Assets
import { skipTheUser, updateLocation, lookingForBasedConnectionRequest, interestBasedConnectionRequest, typeBasedConnectionRequest, handpickedBasedSkipRequest } from '../../../apis/matches';
import { ConnectionActions, UserBasicDetails, UserBasicDetailsResponsive } from '..';

const NoMatches = lazy(() => import("components/Cards/emptyCards").then(module => ({ default: module.NoMatchesFound })))
const InsufficientCoins = lazy(() => import("components/popUps").then(module => ({ default: module.InsufficientCoins })))
const LocationError = lazy(() => import("components/popUps").then(module => ({ default: module.LocationTurnoff })))
const LimitReached = lazy(() => import("components/popUps").then(module => ({ default: module.LimitReached })))
const NoAccess = lazy(() => import("components/popUps").then(module => ({ default: module.NodataAccess })))
const DefaultError = lazy(() => import("components/popUps").then(module => ({ default: module.SomethingWentWrong })))


function Index({ matches, handleCloseBtn, currentOption }) {
    const [errorType, setErrorType] = useState(null)
    const [currentIndex, setCurrentIndex] = useState(0)



    const getLocation = async () => {
        if (!navigator.geolocation) {
            setErrorType('Please update your location to continue');
            toast('Geolocation is not supported by your browser');
            return;
        }
        navigator.geolocation.getCurrentPosition(
            async (position) => {
                await updateLocation({
                    latitude: position.coords.latitude,
                    longitude: position.coords.longitude
                });
            },
            () => {
                setErrorType('Please update your location to continue');
            }
        );
    };

    useEffect(() => {
        getLocation()
    }, [])


    // Send Connection Request
    const handleConnect = useCallback(async () => {
        try {
            const id = matches[currentIndex].matchId
            const res = currentOption === 'Interests' ? await interestBasedConnectionRequest(id)
                : (currentOption === 'handPicked' ? await typeBasedConnectionRequest(id, 'handpick')
                    : await lookingForBasedConnectionRequest(id, { looking_for: currentOption }))

            setCurrentIndex(prev => prev + 1);
            if (res?.response?.data) {
                setCurrentIndex(prev => prev - 1);
                setErrorType(res?.response?.data?.type)
            }
        } catch (error) {
            toast.error('something went wrong...')
        }

    }, [matches, currentIndex]);


    // Skip the user  
    const handleSkip = useCallback(async () => {
        const id = matches[currentIndex].matchId
        const res = currentOption === 'handPicked' ? await handpickedBasedSkipRequest(id) : await skipTheUser(id)
        setCurrentIndex(prev => prev + 1);
        if (res?.response?.data) {
            setCurrentIndex(prev => prev - 1);
            setErrorType(res?.response?.data?.type)
        }
    }, [matches, currentIndex]);


    const ErrorComponent = errorType === 'Please update your location to continue' ? LocationError
        : (errorType === 'DAILY_CONNECTION_LIMIT' ? LimitReached
            : (errorType === 'INSUFFICIENT_COINS' ? InsufficientCoins
                : (errorType === 'NO_ACCESS' ? NoAccess
                    : DefaultError
                )))

    const handleMutualConnection = () => {
        toast('coming soon...')
    }

    return (
        <div className='w-full bg-[#efefef] lg:h-screen'>
            {errorType && (
                <div className="absolute inset-0 z-50 flex items-center justify-center w-full h-full bg-black bg-opacity-75">
                    <Suspense fallback={<div>Loading...</div>}>
                        <ErrorComponent closeBtn={() => setErrorType(null)} />
                    </Suspense>
                </div>
            )}


            <div className='flex flex-col items-center justify-center h-full'>
                <div onClick={() => handleCloseBtn()} className="items-end justify-end hidden w-full cursor-pointer lg:flex pe-10">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="text-black feather feather-x">
                        <line x1="18" y1="6" x2="6" y2="18"></line>
                        <line x1="6" y1="6" x2="18" y2="18"></line>
                    </svg>
                </div>

                <div className='flex flex-row items-center justify-center w-full mt-20 lg:mt-0'>
                    <div className='flex items-center justify-center w-full gap-10 mx-auto -mt-16 lg:w-10/12 lg:flex-row md:flex-col'>
                        {matches && matches.length > currentIndex ?
                            <div className='flex flex-wrap items-start justify-center w-full gap-0 md:gap-x-10 '>
                                {/* ImgPreview Component */}
                                <div className="flex flex-col items-center">
                                    <div className="h-[510px] w-full md:w-[360px] mx-auto flex items-center justify-center">
                                        <ImgPreview data={matches[currentIndex]} />
                                    </div>
                                    <div className="flex items-center justify-center mt-4 gap-9">
                                        <ConnectionActions
                                            handleSkip={handleSkip}
                                            handleConnect={handleConnect}
                                            handleMutualConnection={handleMutualConnection}
                                        />
                                    </div>
                                </div>


                                <div className="hidden md:block h-[580px] w-[60%] overflow-y-auto">
                                    <UserBasicDetails data={matches[currentIndex]} />
                                </div>
                                <div className="block mt-4 overflow-y-auto md:hidden">
                                    <UserBasicDetailsResponsive data={matches[currentIndex]} />
                                </div>
                            </div>
                            :
                            <Suspense fallback={<div>Loading...</div>}>
                                <NoMatches />
                            </Suspense>
                        }
                    </div>
                </div>
            </div>
        </div>


    )
}

export default React.memo(Index)