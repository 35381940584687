import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { capitalize } from 'shared/textFormats'

function FeatureCard({ icon, title, link, secondaryIcon, secondaryTitle, secondaryLink, textColor, bgColor }) {

    const [isSwiped, setIsSwiped] = useState(true)

    return (
        <div className="relative h-full">
            <div
                className={`${isSwiped ? "" : `${bgColor} ${textColor}`} h-full border flex items-center justify-between p-4 bg-white shadow-lg rounded-lg  transform  hover:shadow-xl w-full transition-colors duration-500`}
            >
                <Link to={isSwiped ? link : secondaryLink} className="cursor-pointer flex flex-col items-start">
                    <div className="p-2 rounded-full bg-gray-200">
                        <img src={isSwiped ? icon : secondaryIcon} alt={title} className="w-[56px] h-[56px]" />
                    </div>
                    <div className="ml-4 text-base font-semibold mt-2">{isSwiped ? title : capitalize(secondaryTitle)}</div>
                </Link>

                {/* Arrow Icon */}
                <div onClick={() => setIsSwiped(!isSwiped)} className={`cursor-pointer absolute top-2 right-2 text-xl font-semibold text-black ${isSwiped ? "" : "rotate-180"} transition-transform duration-500 `}>
                    <span className='font-bold'>↗</span>
                </div>
            </div>
        </div>

    )
}

export default FeatureCard