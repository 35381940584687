import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { nextGuideStep, skipGuide } from "../../../redux/slice/guid";
import { useNavigate } from "react-router-dom";

const GuidePopUp = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { isGuide, guids, currentStep, position } = useSelector((state) => state.guide);

    const currentGuide = guids[currentStep];

    useEffect(() => {
        if (isGuide) {
            document.body.style.overflow = "hidden"; // Prevent scrolling
        } else {
            document.body.style.overflow = ""; // Re-enable scrolling
        }

        return () => {
            document.body.style.overflow = ""; // Cleanup on component unmount
        };
    }, [isGuide]);

    useEffect(() => {
        if (isGuide && position.top !== undefined) {
            window.scrollTo({
                top: position.top - 100, // Offset to account for padding or header
                behavior: "smooth", // Smooth scrolling
            });
        }
    }, [position, isGuide]);

    const handleNext = () => {
        const currentGuide = guids[currentStep + 1];
        // Dispatch next step
        dispatch(nextGuideStep());
        // Navigate if `navigate` is defined
        if (currentGuide?.navigate) {
            navigate(currentGuide?.navigate);
        }
    };

    if (!isGuide || guids.length === 0) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-[100]">
            <div
                className="absolute bg-white rounded-lg shadow-lg p-6 w-[90%] md:max-w-sm md:w-full z-[110] animate-jump"
                style={{
                    top: position.top || 'auto',
                    left: position.left || 'auto',
                    right: position.right || 'auto',
                    bottom: position.bottom || 'auto',
                }}
            >

                {/* Guide Content */}
                <p className="text-sm text-black mt-3">{currentGuide?.data}</p>

                {/* Navigation Buttons */}
                <div className="flex justify-end items-center mt-6">
                    <button
                        className="bg-pink-500 hover:bg-pink-600 hover:underline text-white text-sm font-medium py-2 px-4 rounded-lg"
                        onClick={() => handleNext()}
                    >
                        Next
                    </button>
                    <button
                        className="text-gray-600 hover:text-gray-800 hover:underline text-sm ml-2"
                        onClick={() => dispatch(skipGuide())}
                    >
                        Skip
                    </button>
                </div>
            </div>
        </div>
    );
};

export default GuidePopUp;
