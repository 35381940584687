import { auth } from "..";



export const addImage = async (data) => {
    const res = await auth({
        method: "POST", url: `/users/images`, data, options: {
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage?.getItem("session") || ""}`,
            },
        },
    });
    return res
};


export const getImages = async () => {
    const res = await auth({
        method: "GET", url: `/users/images`, options: {
            headers: {
                Authorization: `Bearer ${localStorage?.getItem("session") || ""}`,
            },
        },
    });
    return res
};


export const deleteImage = async ({ index }) => {
    const res = await auth({
        method: "DELETE", url: `/users/images?index=${index}`, options: {
            headers: {
                Authorization: `Bearer ${localStorage?.getItem("session") || ""}`,
            },
        },
    });
    return res
};


export const submitOnboardingData = async (data) => {
    const res = await auth({
        method: "POST", url: "/users/onboarding", data, options: {
            headers: {
                Authorization: `Bearer ${localStorage?.getItem("session") || ""}`,
            },
        },
    });
    return res
}

export const updateGender = async (data) => {
    const res = await auth({
        method: "PUT",
        url: "/users/profile/gender",
        data,
    });
    return res
}

