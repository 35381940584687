import React, { useState, useEffect } from 'react';
import singlesWhiteIcon from 'assets/onBoarding/whiteIcons/singles_white_icon.svg';
import singlesBlackIcon from 'assets/onBoarding/blackIcons/singles_black_icon.svg';
import couplesWhiteIcon from 'assets/onBoarding/whiteIcons/couples_white_icon.svg';
import couplesBlackIcon from 'assets/onBoarding/blackIcons/couples_black_icon.svg';

function Index({ onChange, option }) {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);

    useEffect(() => {
        if (option) {
            setSelectedOption(option)
        }
    }, [option])

    const options = [
        { value: 'single', label: 'Single', imgSrc: singlesBlackIcon },
        { value: 'couple', label: 'Couple', imgSrc: couplesBlackIcon },
    ];

    const handleOptionClick = (value) => {
        setSelectedOption(value);
        setIsOpen(false);
        onChange(value);
    };

    return (
        <div className="relative mt-5">
            <div
                className={`p-3 flex w-full md:w-10/12 text-gray-700 ${selectedOption ? 'bg-brandRed text-white' : 'bg-white text-black'} border border-gray-300 rounded-md shadow-sm focus:outline-none`}
                onClick={() => setIsOpen(!isOpen)}
            >
                <img src={selectedOption === 'single' ? singlesWhiteIcon : couplesWhiteIcon} alt="" className='me-2' />
                {selectedOption ? options.find(option => option.value === selectedOption).label : 'Select...'}
            </div>
            {isOpen && (
                <div className="absolute mt-1 w-full md:w-10/12 bg-white border border-gray-300 rounded-md shadow-sm">
                    {options.map((option) => (
                        <div
                            key={option.value}
                            className="flex items-center p-2 cursor-pointer hover:bg-gray-100 text-black"
                            onClick={() => handleOptionClick(option.value)}
                        >
                            <img src={option.imgSrc} alt="" className="mr-2" />
                            {option.label}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
}

export default React.memo(Index)