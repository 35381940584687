import React, { useEffect } from 'react';
import ReactPixel from 'react-facebook-pixel';

import { convertToDDMMYYYYHrMin } from '../../../../shared/dates';



const Index = ({ amount, eventName, paymentTime, senderName, booking_id, handleDoneBtn }) => {


    useEffect(() => {
        ReactPixel.track('Event_Booked', {
            value: amount,
            currency: 'RS',
        });
    }, []);


    return (

        <div className="flex items-center justify-center min-h-screen bg-gray-100">
            <div className="bg-white p-6 rounded-lg shadow-lg w-[490px] h-[550px] flex flex-col justify-between text-center">
                <div>
                    <div className="flex items-center justify-center mb-4 -mt-14">
                        <svg width="123" height="105" viewBox="0 0 123 105" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g filter="url(#filter0_d_7028_6096)">
                                <ellipse cx="61.5845" cy="36.9815" rx="38.5845" ry="36.9815" fill="white" />
                            </g>
                            <path d="M60.2899 13.6914C46.8976 13.6914 35.9844 24.1512 35.9844 36.9872C35.9844 49.8231 46.8976 60.2829 60.2899 60.2829C73.6823 60.2829 84.5955 49.8231 84.5955 36.9872C84.5955 24.1512 73.6823 13.6914 60.2899 13.6914ZM71.908 31.6291L58.1267 44.8378C57.7865 45.164 57.3246 45.3503 56.8385 45.3503C56.3524 45.3503 55.8906 45.164 55.5503 44.8378L48.6719 38.2451C47.967 37.5695 47.967 36.4514 48.6719 35.7758C49.3767 35.1002 50.5434 35.1002 51.2483 35.7758L56.8385 41.1338L69.3316 29.1598C70.0365 28.4842 71.2031 28.4842 71.908 29.1598C72.6128 29.8354 72.6128 30.9303 71.908 31.6291Z" fill="#41D195" />
                            <defs>
                                <filter id="filter0_d_7028_6096" x="0.78735" y="-13.8829" width="121.593" height="118.39" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                    <feOffset dy="8.32974" />
                                    <feGaussianBlur stdDeviation="11.1063" />
                                    <feComposite in2="hardAlpha" operator="out" />
                                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0" />
                                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_7028_6096" />
                                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_7028_6096" result="shape" />
                                </filter>
                            </defs>
                        </svg>

                    </div>
                    <h2 className="my-2 text-xl font-semibold text-black mt-7">Event Booked Successfully</h2>
                    <h2 className="my-2 text-base text-gray-500">Your payment has been successfully done.</h2>

                    <h2 className="my-2 mt-20 text-xl font-semibold text-black">Event Name</h2>
                    <h2 className="mb-2 text-lg font-medium text-gray-500">{eventName}</h2>
                </div>

                <div className="flex flex-col items-center justify-center w-full mt-10 gap-x-3">
                    <div className="flex items-center justify-around w-full max-w-md text-start">
                        <div className="">
                            <h1 className="text-[15px] text-gray-500">Booking Id</h1>
                            <h1 className="mt-1 text-base font-medium text-black">{booking_id}</h1>
                        </div>
                        <div className="">
                            <h1 className="text-[15px] text-gray-500">Payment Time</h1>
                            <h1 className="mt-1 text-base font-medium text-black">{convertToDDMMYYYYHrMin(paymentTime)}</h1>
                        </div>
                    </div>
                    <div className="flex items-center w-full max-w-md mt-4 text-start mb-7">
                        <div className="ms-8">
                            <h1 className="text-[15px] text-gray-500">Sender Name</h1>
                            <h1 className="mt-1 text-base font-medium text-black">{senderName}</h1>
                        </div>
                    </div>
                </div>

                <button className="w-full h-[56px] px-4 py-2 bg-brandRed text-white font-semibold hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-400 mt-auto" onClick={handleDoneBtn}>
                    Done
                </button>
            </div>
        </div>


    );
};

export default React.memo(Index)
