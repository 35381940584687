import React, { useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { EVENTS, MAIN_NAV, PROFILE_EDIT, SETTINGS, WALLET } from 'shared/router';
import { useSelector } from 'react-redux';
import Notification from '../../../notification';

// Assets
import icon from 'assets/navbar/main/responsive/img/logo.png';
import icon2 from 'assets/discover/search.svg'; //ddce
// import icon8 from 'assets/discover/downarrow.svg';
import notificationsInactiveIcon from 'assets/navbar/main/icons/notificationsInactive.svg'
import avatar from "assets/avatar.png"

import settingActive from 'assets/settings/icons/settings/active.svg'
import settingInactive from 'assets/settings/icons/settings/inactive.svg'
import profilePrevActive from 'assets/settings/icons/profilePrev/active.svg'
import profilePrevInactive from 'assets/settings/icons/profilePrev/inactive.svg'
import profileEditActive from 'assets/settings/icons/profileEdit/active.svg'
import profileEditInactive from 'assets/settings/icons/profileEdit/inactive.svg'
import partnerPreferenceActive from 'assets/settings/icons/partnerPreference/active.svg'
import partnerPreferenceInactive from 'assets/settings/icons/partnerPreference/inactive.svg'
import privacySettingsActive from 'assets/settings/icons/privacySettings/active.svg'
import privacySettingsInactive from 'assets/settings/icons/privacySettings/inactive.svg'
import accountActive from 'assets/settings/icons/account/active.svg'
import accountInactive from 'assets/settings/icons/account/inactive.svg'
import walletActive from 'assets/settings/icons/wallet/active.svg'
import walletInactive from 'assets/settings/icons/wallet/inactive.svg'
import logoutActive from 'assets/settings/icons/logout/active.svg'
import logoutInactive from 'assets/settings/icons/logout/inactive.svg'
import { encodeToBase64 } from 'utils/encodeDecode';

function Index({ isEventFilterPage = false, handleOnChange }) {
    const location = useLocation();
    const userInfo = useSelector((state) => state.userProfile);
    const navigate = useNavigate()

    const [search, setSearch] = useState(false)
    const [isSearch, setIsSearch] = useState(false)
    const [showDropDown, setShowDropDown] = useState(false)
    const [showNotification, setShowNotification] = useState(false);

    const navigationItems = [
        { title: 'Settings', img: [settingActive, settingInactive], action: SETTINGS.SETTINGS },
        { title: 'Profile Preview', img: [profilePrevActive, profilePrevInactive], action: SETTINGS.PROFILE_PREVIEW },
        { title: 'Profile Edit', img: [profileEditActive, profileEditInactive], action: PROFILE_EDIT.BASIC },
        { title: 'Partner Preference', img: [partnerPreferenceActive, partnerPreferenceInactive], action: SETTINGS.PARTNER_PREFERENCE },
        { title: 'Privacy Settings', img: [privacySettingsActive, privacySettingsInactive], action: SETTINGS.PRIVACY_SETTINGS },
        { title: 'Account Settings', img: [accountActive, accountInactive], action: SETTINGS.ACCOUNT_SETTINGS },
        { title: 'Wallet', img: [walletActive, walletInactive], action: WALLET.WALLET },
        { title: 'Logout', img: [logoutActive, logoutInactive], action: SETTINGS.LOGOUT },
    ];

    const handleSubmit = (type, value) => {
        if (isEventFilterPage) {
            if (type === 'search') {
                handleOnChange("search", search);
            } else if (type === 'city') {
                handleOnChange("city", value);
            }
        } else {
            const filterParams = new URLSearchParams();
            if (type === 'search') {
                filterParams.append('search', encodeToBase64(search));
            } else if (type === 'city') {
                filterParams.append('city', encodeToBase64(value));
            }
            navigate(`${EVENTS.EVENT_FILTER}?${filterParams.toString()}`);
        }
    }

    return (
        <div>
            <nav className="flex items-center justify-between px-4 py-2 text-black bg-white shadow-md">
                {/* Logo */}
                <div className="flex items-center justify-center text-2xl font-bold">
                    <Link to={MAIN_NAV.HOME}>
                        <div className='w-[118px] h-[66px] cursor-pointer'>
                            <img src={icon} alt='couplesquad' />
                        </div>
                    </Link>
                </div>
                <div className="flex items-center space-x-4">
                    {/* Search Area */}
                    <div className="flex items-center cursor-pointer">
                        {isSearch &&
                            <input onChange={(e) => setSearch(e.target.value)} type='text' placeholder='Search events' className='w-[100px] md:w-[150px] lg:w-[366px] h-[22px] text-gray-700 border-none bg-white  focus:outline-none rounded-sm'
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        handleSubmit('search');
                                    }
                                }} />}
                        <img src={icon2} alt="Search" className="w-6 h-6" onClick={() => setIsSearch(!isSearch)} />
                    </div>
                    {/* Notification Icon */}
                    <div className="relative cursor-pointer">
                        {/* Add your notification icon here */}
                        <img src={notificationsInactiveIcon} alt="Notifications" className="w-6 h-6" onClick={() => setShowNotification(true)} />
                    </div>
                    {/* Profile Picture */}
                    <div className="cursor-pointer">
                        <div className="relative">
                            <div className="flex cursor-pointer" onClick={() => setShowDropDown(!showDropDown)}>
                                <img src={userInfo.photo ? userInfo.photo : avatar} alt="Profile" className="rounded-full w-9 h-9" />
                                {/* <img src={icon8} alt="downArrow" /> */}
                            </div>
                            {showDropDown && (
                                <div className="absolute right-0 z-50 w-[18rem] mt-2 bg-white border border-gray-200 rounded-md shadow-lg">
                                    <div className="px-4 py-2 border-b border-gray-200">
                                        <div className="flex items-center space-x-2">
                                            <img src={userInfo.photo ? userInfo.photo : avatar} alt="Profile" className="w-8 h-8 rounded-full" />
                                            <div className="">{userInfo?.name}</div>
                                        </div>
                                    </div>
                                    {navigationItems.map((item, index) => (
                                        <div key={index} className="flex items-center px-4">
                                            <img src={location.pathname === item.action ? item.img[0] : item.img[1]} alt="icon" className="w-6 h-6 mr-2" />
                                            <Link to={item.action} className={`block px-4 py-2 ${location.pathname === item.action ? 'text-brandRed' : ''} hover:bg-gray-100`}>
                                                {item.title}
                                            </Link>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </nav>

            {showNotification && (
                <div className='fixed inset-0 z-50 flex'>
                    <div className='flex-grow bg-gray-500 opacity-50'></div>
                    <div className='w-[400px] h-full bg-gray-500 bg-opacity-50'>
                        <Notification handleClose={() => setShowNotification(false)} />
                    </div>
                </div>
            )}

        </div>

    )
}

export default Index