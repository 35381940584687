import React from 'react';

const Index = () => {
    return (
        <div className="p-10 text-black bg-white">
            <h1 className="text-3xl font-bold text-center">Terms & Conditions</h1>

            <p className='mt-4'>
                Welcome to Couple Squad These Terms of Service (“Terms”) are a binding legal agreement between you (“you” or “User”) and Couple Squad Private Limited (“Couple Squad,” “we,” “us,” or “our”). By accessing or using our website, mobile application (“App”), or any other services provided by Couple Squad (collectively, the “Service” or “Platform”), you agree to be bound by these Terms, our Privacy Policy, and any additional terms, guidelines, or policies we may post.If you do not agree with these Terms, please do not use the Service.
            </p>

            <h1 className="mt-4 text-lg font-semibold">1. Scope of Service</h1>
            <p>
                Couple Squad is an online platform that facilitates personal connections through our unique Datrimony® service. The Platform includes features such as profile creation, browsing other profiles, messaging, organising live meet-ups, attending events, and additional functionalities based on your subscription plan.
            </p>
            <p className='mt-4'>
                <strong>Datrimony®</strong> is a registered trademark of CoupleSquad Private Limited. It represents our unique process that blends dating and matrimonial services to offer a seamless transition from dating to marriage. This trademarked service is exclusive to CoupleSquad and cannot be used or replicated by any other entity without the express written consent of CoupleSquad Private Limited.
            </p>

            <h1 className="mt-4 text-lg font-semibold">2. Eligibility</h1>
            <p>
                To use the Service, you must be at least 18 years old or the age of majority in your jurisdiction if older than 18. By using the Service, you represent and warrant that you have the legal capacity to enter into this Agreement and to comply with all applicable laws and regulations.
            </p>

            <h1 className="mt-4 text-lg font-semibold">3. Account Registration</h1>
            <p>
                To access and use certain features of the Service, you must register for an account. You agree to:
            </p>
            <ul>
                <li>• Provide accurate, current, and complete information during the registration process.</li>
                <li>• Maintain the security of your login credentials.</li>
                <li>• Not share your account with others or use another person’s account without permission.</li>
                <li>• Notify us immediately of any unauthorised use of your account.</li>
            </ul>
            <p>
                We reserve the right to suspend or terminate your account if any information provided during the registration process or thereafter is inaccurate, not current, or incomplete.
            </p>

            <h1 className="mt-4 text-lg font-semibold">4. User Conduct and Responsibilities</h1>
            <h1 className="text-base font-semibold">4.1 Acceptable Use</h1>
            <p>You agree to:</p>
            <ul>
                <li>• Use the Service in a manner that is lawful, respectful, and consistent with the community guidelines that we may establish from time to time.</li>
                <li>• Use your real name, real age, and accurate information when creating your profile.</li>
                <li>• Engage with other users respectfully and avoid conduct that is abusive, harassing, discriminatory, or otherwise harmful.</li>
            </ul>

            <h1 className="mt-2 text-base font-semibold">4.2 Prohibited Activities</h1>
            <p>You agree not to:</p>
            <ul>
                <li>• Impersonate any person or entity, or falsely state or otherwise misrepresent yourself, your age, or your affiliation with any person or entity.</li>
                <li>• Post or transmit any content that is illegal, harmful, defamatory, obscene, or otherwise objectionable.</li>
                <li>• Engage in any activities that are fraudulent, abusive, or harmful to others.</li>
                <li>• Attempt to disrupt, damage, or interfere with the Service, servers, or networks connected to the Service.</li>
                <li>• Use the Service to spam, phish, or otherwise send unauthorised or unwanted communications.</li>
            </ul>

            <h1 className="mt-4 text-lg font-semibold">5. Content and Intellectual Property</h1>
            <h1 className="text-base font-semibold">5.1 Your Content</h1>
            <p>
                You retain ownership of any content you post or transmit on the Platform (“Your Content”). However, by posting or transmitting Your Content, you grant Couple Squad a non-exclusive, royalty-free, perpetual, irrevocable, and fully sublicensable right to use, reproduce, modify, adapt, publish, translate, create derivative works from, distribute, and display Your Content worldwide in any media.You represent and warrant that you have all necessary rights to grant the above licence and that Your Content does not infringe any third-party rights.
            </p>

            <h1 className="mt-2 text-base font-semibold">5.2 Member Content</h1>
            <p>
                Content provided by other users (“Member Content”) is owned by the user who posted it. You may only use Member Content in connection with the Service’s intended purposes of facilitating connections and communication between users. You may not use Member Content for commercial purposes or for any illegal or harmful activities.
            </p>
            <h1 className="mt-2 text-base font-semibold">5.3 Our Content</h1>
            <p>
                All text, images, video, graphics, user interfaces, trademarks, logos, sounds, artwork, and other content provided by Couple Squad (“Our Content”) are protected by copyright, trademark, and other intellectual property laws. We grant you a limited, non-exclusive, non-transferable licence to access and use Our Content solely for personal, non-commercial use in connection with the Service.
            </p>

            <h1 className="mt-4 text-lg font-semibold">6. Virtual Items</h1>
            <p>
                Couple Squad may offer virtual items (e.g., virtual gifts, tokens) that can be purchased through the Service. These virtual items are non-transferable, non-refundable, and have no real-world value. You acknowledge that you do not own these virtual items; rather, you are granted a limited licence to use them within the Service. Couple Squad reserves the right to manage, modify, or eliminate virtual items at its discretion without notice or liability.
            </p>

            <h1 className="mt-4 text-lg font-semibold">7. Subscription Plans and Payment Terms</h1>
            <h1 className="text-base font-semibold">7.1 Subscription Plans</h1>
            <p>
                Couple Squad offers various subscription plans that provide access to different features and functionalities. Details of available plans and pricing can be found on our website or App. By subscribing, you agree to pay the subscription fees and any applicable taxes as specified during the sign-up process.
            </p>

            <h1 className="mt-2 text-base font-semibold">7.2 Automatic Renewal </h1>
            <p>
                Unless you cancel your subscription before the end of the current period, your subscription will automatically renew at the end of the subscription term. You authorise Couple Squad to charge the payment method on file for the renewal fees. You can manage your subscription and payment settings through your account.
            </p>

            <h1 className="mt-2 text-base font-semibold">7.3 Changes to </h1> Subscription Fees
            <p>
                Couple Squad reserves the right to change subscription fees at any time. If we change the fees for a subscription plan to which you are currently subscribed, we will notify you in advance. If you do not agree to the new fees, you may cancel your subscription before the changes take effect.
            </p>

            <h1 className="mt-2 text-base font-semibold">7.4 Payment Methods </h1>
            <p>
                We accept various payment methods, including credit cards, debit cards, and other payment options displayed during the purchase process. By providing payment information, you authorise Couple Squad to charge your chosen payment method for all applicable fees.
            </p>

            <h1 className="mt-2 text-base font-semibold">7.5 Refund Policy </h1>
            <p>
                All subscription fees are non-refundable unless otherwise required by law. If you cancel your subscription, you will continue to have access to the Service until the end of your current billing period, but you will not receive a refund for any unused portion of the subscription term.
            </p>

            <h1 className="mt-4 text-lg font-semibold">8. Live Meet-Ups and Events</h1>
            <h1 className="text-base font-semibold">8.1 Event Participation</h1>
            <p>
                Couple Squad organises live meet-ups and events to help users connect in person. By participating in these events, you agree to:
            </p>
            <ul>
                <li>• Comply with all instructions, rules, and guidelines provided by Couple Squad or event organisers.</li>
                <li>• Conduct yourself in a respectful and appropriate manner.</li>
                <li>• Be responsible for your own conduct and safety during the event.</li>
            </ul>

            <h1 className="mt-2 text-base font-semibold">8.2 Event Registration and Fees</h1>
            <p>
                Some events may require prior registration and payment of a fee. Event details, including fees, will be provided on the registration page. Event fees are non-refundable unless the event is cancelled by Couple Squad.
            </p>

            <h1 className="mt-2 text-base font-semibold">8.3 User Conduct at Events</h1>
            <p>
                Harassment, discrimination, or any form of abusive behavior will not be tolerated. Couple Squad reserves the right to remove any participant from an event for inappropriate conduct. You must also comply with all applicable local laws and regulations during the event.
            </p>

            <h1 className="mt-2 text-base font-semibold">8.4 Liability</h1>
            <p>
                Couple Squad is not responsible for any injuries, losses, or damages resulting from your participation in live meet-ups and events. You agree to indemnify and hold Couple Squad harmless from any claims, damages, or liabilities arising out of your participation in these events.
            </p>

            <h1 className="mt-4 text-lg font-semibold">9. Privacy and Data Security</h1>
            <p>
                Your privacy is important to us. Please review our Privacy Policy to understand how we collect, use, and protect your information. By using the Service, you consent to the collection, use, and sharing of your information as described in the Privacy Policy.
            </p>

            <h1 className="mt-4 text-lg font-semibold">10. Disclaimers and Limitation of Liability</h1>
            <h1 className="text-base font-semibold">10.1 Disclaimer of Warranties</h1>
            <p>
                The Service is provided “as is” and “as available” without any warranties of any kind, either express or implied. We disclaim all warranties, including, but not limited to, warranties of merchantability, fitness for a particular purpose, and non-infringement. We do not guarantee that the Service will be uninterrupted, secure, or error-free.
            </p>

            <h1 className="mt-2 text-base font-semibold">10.2 Limitation of Liability</h1>
            <p>
                To the maximum extent permitted by law, Couple Squad shall not be liable for any indirect, incidental, special, consequential, or punitive damages, including, but not limited to, loss of profits, data, use, goodwill, or other intangible losses arising out of or in connection with your use of the Service.
            </p>

            <h1 className="mt-4 text-lg font-semibold">11. Termination</h1>
            <p>
                We may suspend or terminate your account and access to the Service at any time, for any reason, with or without notice. You may terminate your account at any time through your account settings. Upon termination, your right to use the Service will immediately cease. Provisions of these Terms that by their nature should survive termination shall survive, including, but not limited to, ownership provisions, disclaimers, indemnity, and limitations of liability.
            </p>

            <h1 className="mt-4 text-lg font-semibold">12. Governing Law and Dispute Resolution</h1>
            <h1 className="text-base font-semibold">12.1 Governing Law</h1>
            <p>
                These Terms are governed by and construed in accordance with the laws of India, without regard to its conflict of law principles.
            </p>

            <h1 className="mt-2 text-base font-semibold">12.2 Dispute Resolution</h1>
            <p>
                Any dispute arising out of or relating to these Terms or the Service shall be resolved through binding arbitration in accordance with the Indian Arbitration and Conciliation Act, 1996. The arbitration shall be conducted in Kottayam, Kerala, India, in English. The arbitrator’s decision shall be final and binding on the parties.
            </p>

            <h1 className="mt-4 text-lg font-semibold">13. Modifications to the Terms</h1>
            <p>
                We reserve the right to modify these Terms at any time. Any changes will be effective immediately upon posting the revised Terms on the Service. Your continued use of the Service following the posting of changes constitutes your acceptance of such changes. It is your responsibility to review the Terms periodically for updates.
            </p>

            <h1 className="mt-4 text-lg font-semibold">14. Indemnification</h1>
            <p>
                You agree to indemnify, defend, and hold harmless Couple Squad, its affiliates, officers, directors, employees, and agents from any claims, liabilities, damages, losses, and expenses, including reasonable attorneys’ fees, arising out of or in any way connected with your access to or use of the Service, your violation of these Terms, or your violation of any rights of another.
            </p>

            <h1 className="mt-4 text-lg font-semibold">15. Contact Us</h1>
            <p>
                If you have any questions or concerns about these Terms or the Service, please contact us at info@couplesquad.com
            </p>

            <p className='mt-4'><strong>Effective Date:</strong> 20th August 2024</p>

        </div>
    )
}
export default Index