import React, { useState, useEffect, useCallback, Component } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setUserProfileDetails } from '../../redux/slice/userProfile';
import { AuthenticatedLayout, SettingsLayout, UnAuthenticatedLayout, WalletLayout } from 'components/layout';
import ServerError from 'pages/error/serverError';
import { status } from '../../apis/auth';
import { LAYOUT_TYPES } from 'utils/constant/routeHandler';

// Error boundary to catch rendering errors
class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    componentDidCatch(error, info) {
        this.setState({ hasError: true });
        console.error(`Error caught in route: ${this?.props?.routeName}`);
        console.error('Error details:', error);
        console.error('Component stack info:', info);
    }

    render() {
        if (this.state.hasError) {
            return <ServerError />;
        }
        return this.props.children;
    }
}


const RoutAuthentication = ({ children, auth = 'auth', avoid = [], layoutType = 'authenticated' }) => {

    const navigate = useNavigate()
    const dispatch = useDispatch();
    const userInfo = useSelector((state) => state.userProfile);

    const [checkingAuth, setCheckingAuth] = useState(false)

    const authenticate = useCallback(async () => {
        const token = localStorage?.getItem("session");

        if (auth === 'auth') {
            if (!token) {
                navigate("/welcome")
            }

            let result = await status();
            if (result?.success) {
                if (!userInfo.name) {
                    const updateData = {
                        ...result.data,
                        personal: {
                            ...result?.data?.personal,
                            religion_id: result?.data?.personal?.religion_id?._id,
                            caste_id: result?.data?.personal?.caste_id?._id,
                        },
                    };
                    dispatch(setUserProfileDetails(updateData));
                }
            } else if (result?.response?.data?.success === false) {
                localStorage.removeItem('session');
                navigate("/welcome")
            }
            else {
                localStorage.removeItem('session');
                navigate("/welcome")
            }

        } else if (auth === 'unAuth') {
            if (token) {
                navigate("/home")
            } else {
                navigate("/welcome")
            }
        }

        setCheckingAuth(true)
    }, [auth, dispatch, userInfo.name]);

    useEffect(() => {
        authenticate();
    }, [authenticate]);



    const LayoutToRender = layoutType === LAYOUT_TYPES.AUTHENTICATED ? AuthenticatedLayout
        : layoutType === LAYOUT_TYPES.UNAUTHENTICATED ? UnAuthenticatedLayout
            : layoutType === LAYOUT_TYPES.WALLET ? WalletLayout
                : layoutType === LAYOUT_TYPES.SETTINGS ? SettingsLayout
                    : null

    // Render the component
    return (
        <ErrorBoundary>
            {checkingAuth ?
                <>
                    {layoutType === LAYOUT_TYPES.NONE ? (
                        <>{children}</>
                    ) : (
                        LayoutToRender && <LayoutToRender avoid={avoid}>{children}</LayoutToRender>
                    )}
                </>
                :

                <div>Checking Authentication.....</div>
            }
        </ErrorBoundary>
    );


};

export default RoutAuthentication;
