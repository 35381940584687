import React from 'react';
import { Link } from 'react-router-dom';
import { WALLET } from 'shared/router';
import giftIcon from 'assets/cards/icons/gift.png'

function Index({ close }) {

    const content = [
        'Access all Premium features',
        'Free online events',
        'Free offline Events',
        '4 handpicked matches',
        '999 Rupees Only',
    ]

    return (
        <div className="w-full mx-auto bg-white rounded-3xl shadow-lg h-full relative">
            {/* Curved Top Section */}
            <div className="bg-[#1aa684] flex flex-col items-center justify-center rounded-3xl rounded-b-none">
                <h2 className="text-white text-lg font-semibold mt-6">Exclusive For You</h2>
                <p className="text-white text-sm">Valid for 60 Days</p>
                <div className="bg-[#31af90] rounded-full p-2 mt-2 mb-5">
                    <img src={giftIcon} alt="Gift Icon" className='w-[80px] h-[80px]' />
                </div>
            </div>

            {/* Content Section */}
            <div className="px-6 pt-8 flex flex-col items-center justify-center">
                <ul className="space-y-4">
                    {content.map((item, index) => (
                        <li
                            key={index}
                            className="flex items-center space-x-4 text-gray-700"
                        >
                            <span className="bg-[#1aa684] p-2 rounded-full">
                                <img
                                    src="https://img.icons8.com/material-outlined/24/ffffff/star--v1.png"
                                    alt="Star Icon"
                                />
                            </span>
                            <span className="text-sm">
                                {index === 4 ? (
                                    <span className="text-[#1aa684] font-semibold">{item}</span>
                                ) : (
                                    item
                                )}
                            </span>
                        </li>
                    ))}
                </ul>
            </div>

            {/* Footer Section */}
            <div className="px-6 mb-4 mt-6  flex flex-col items-center justify-center">
                <Link to={WALLET.PLANES}
                    className="text-[#1aa684] text-sm block text-center underline mb-4 w-[90%] md:w-[40%]"
                >
                    View all plans
                </Link>
                <Link to={WALLET.PLANES} className="bg-[#1aa684] text-white text-center py-3 rounded-md shadow-lg mb-5 mx-auto w-[90%] md:w-[40%]">
                    Upgrade Now
                </Link>
            </div>

            {/* Close Icon */}
            <button className="absolute top-4 right-4 text-white cursor-pointer" onClick={close}>
                ✕
            </button>
        </div>
    );
};

export default Index;
