import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import avatar from 'assets/avatar.png'
import { ToggleWithText } from 'components/UI';
import { updateGhostMode } from 'apis/gps';
import { NearByUserCard } from 'components/Cards/Connection';
import { getNearByUserHistory, rejectConnectionRequest } from 'apis/connections';
import { acceptConnectionRequest } from '../../../apis/connections';
import { toast } from 'react-toastify';
import { setUserProfileDetails } from '../../../redux/slice/userProfile';

function Index() {
    const dispatch = useDispatch();
    const userInfo = useSelector((state) => state.userProfile);
    const [ghostMode, setGhostMode] = useState(userInfo?.ghost_mode || false)
    const [activeTab, setActiveTab] = useState('sent');
    const [nearByUserHistory, setNearByUserHistory] = useState([])

    const handleGhostMode = async (value) => {
        const data = { value: value }
        const res = await updateGhostMode(data)
        if (res?.success) {
            setGhostMode(res?.data?.ghost_mode)
            dispatch(setUserProfileDetails({
                ...userInfo,
                ghost_mode: res?.data?.ghost_mode
            }));
        } else {
            console.error('Failed to update ghost mode:', res?.message);
        }
    }

    const handleNearByUserHistory = async (connection_type) => {
        const res = await getNearByUserHistory(connection_type)
        if (res?.success) setNearByUserHistory(res?.data)
    }

    const handleAccept = async (id) => {
        const res = await acceptConnectionRequest(id)
        if (res?.success) {
            toast.success('Accepted Request')
            handleNearByUserHistory(activeTab)
        }
    }

    const handleReject = async (id) => {
        const res = await rejectConnectionRequest(id)
        if (res?.success) {
            toast.success('Rejected Request')
            handleNearByUserHistory(activeTab)
        }
    }

    useEffect(() => {
        handleNearByUserHistory(activeTab)
    }, [activeTab])

    return (
        <div className="min-h-screen bg-white">

            {/* Wrapper for Centering the Content */}
            <div className="flex flex-col items-center justify-center w-full">
                {/* Header Section */}
                <div className="w-[96%] md:w-[90%] ">
                    <div className="bg-[#6161f8] text-white rounded-lg shadow-lg p-6 my-10">
                        <div className='flex items-center justify-between'>
                            <div>
                                <h1 className="text-2xl font-bold">Welcome GPS Nearby Tracking</h1>
                                <p className="mt-2 text-sm">Always stay updated in Couple Squad Profile</p>
                            </div>
                            <img src={userInfo?.photo || avatar} alt='' className="hidden w-16 h-16 bg-gray-300 rounded-full md:block" />
                        </div>

                        <div className="w-full mt-8 border border-white rounded-lg">
                            <div className="p-4">

                                <div className="flex items-center justify-between w-full">
                                    <div className="text-lg font-semibold">Ghost  Mode</div>
                                    <button>
                                        <ToggleWithText isChecked={ghostMode} onToggle={() => handleGhostMode(!ghostMode)} />
                                    </button>
                                </div>

                                <div className="">See who’s can see you around you! Connect with nearby members in real-time as</div>
                            </div>
                        </div>

                    </div>

                    <div className="flex flex-col items-center justify-center mb-10 font-semibold md:items-start">

                        <div className="flex justify-center w-full my-8 border-b border-white md:justify-start">
                            <div className="flex gap-x-20">
                                <button onClick={() => setActiveTab('sent')} className={`px-4 py-2 font-medium ${activeTab === 'sent' ? 'text-blue-500 border-b-2 border-blue-500' : 'text-black hover:text-blue-200'}`}>
                                    Send
                                </button>

                                <button onClick={() => setActiveTab('received')} className={`px-4 py-2 font-medium ${activeTab === 'received' ? 'text-blue-500 border-b-2 border-blue-500' : 'text-black hover:text-blue-200'}`}>
                                    Receive
                                </button>
                            </div>
                        </div>

                        <div className="flex flex-wrap justify-center w-full gap-4 md:justify-start">
                            {nearByUserHistory?.map((item) => (
                                <NearByUserCard
                                    key={item?.id}
                                    avatarUrl={item?.memberId?.photo}
                                    name={item?.memberId?.name}
                                    jobTitle={item?.memberId?.personal?.jobTitle}
                                    status={activeTab}
                                    requestStatus={'Requested'}
                                    onAccept={() => handleAccept(item?.id)}
                                    onReject={() => handleReject(item?.id)}
                                />
                            ))}
                        </div>
                    </div>

                </div>

            </div>

        </div>

    );
};

export default Index;