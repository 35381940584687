import React, { lazy, Suspense, useEffect } from 'react';
import Banner from "./components/banner";
import { Helmet } from 'react-helmet';

const FeatureCard = lazy(() => import("./components/feature"))
const ServicesCard = lazy(() => import("./components/services"))
const DesktopAndMobileApp = lazy(() => import("./components/desktopAndMobileApp"))
const ConnectionsDisappear = lazy(() => import("./components/connectionsDisappear"))
// const TeamMembers = lazy(() => import("./components/teamMembers"))
const Verification = lazy(() => import("./components/verification"))
const PlanCard = lazy(() => import("./components/planCard"))
const Testimonial = lazy(() => import("./components/testimonial"))


function Index() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div>
            <Helmet>
                <meta name='title' content="Couple Squad: India's First Event-Based Social Community" />

                <meta name="description" content="
                   Join Couple Squad, India's first event-based social community. Find curated events, connect with like-minded people, and build meaningful relationships.
                "/>
                <link rel="canonical" href="https://www.couplesquad.com" />

            </Helmet>
            <div className='bg-white'>


                {/* Main Banners */}
                <div className="h-full min-h-screen mt-16">
                    <Banner />
                </div>
                {/* FeatureCard */}
                <>
                    <Suspense children={<FeatureCard />} fallback={<div>loading...</div>} />
                </>
                {/* Services */}
                <>
                    <Suspense children={<ServicesCard />} fallback={<div>loading...</div>} />
                </>
                {/* Desktop and Mobile App */}
                <>
                    <Suspense children={<DesktopAndMobileApp />} fallback={<div>loading...</div>} />
                </>
                {/* Connections Disappear */}
                <>
                    <Suspense children={<ConnectionsDisappear />} fallback={<div>loading...</div>} />
                </>
                {/* Verification  */}
                <>
                    <Suspense children={<Verification />} fallback={<div>loading...</div>} />
                </>
                {/* Team Members */}
                {/* <div className="items-center hidden bg-white sm:flex">
                    <div className="w-full ">
                        <div className="flex flex-col items-center text-black">
                            <Suspense fallback={<div>loading...</div>}>
                                <TeamMembers />
                            </Suspense>
                        </div>
                    </div>
                </div> */}

                {/* Plan Card */}
                <>
                    <Suspense children={<PlanCard />} fallback={<div>loading...</div>} />
                </>
                {/* Testimonial */}
                <>
                    <Suspense children={<Testimonial />} fallback={<div>loading...</div>} />
                </>


            </div>
        </div>
    );
}

export default Index;