import { noAuth } from "..";

export const getEventLocations = async () => {
    const res = await noAuth({
        method: "GET",
        url: `/events/locations`
    });
    return res;
};

export const getFilteredEvents = async (page, data) => {
    const res = await noAuth({
        method: "POST",
        url: `/events/search?page=${page}&limit=10`,
        data
    });
    return res;
};