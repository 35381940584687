import { toast } from "react-toastify";
import SideBar from "../../../components/sidebars/settings"
import { familyDetailsValidation } from "../../../utils/validations/editProfile";
import { updateUserProfile } from "../../../apis/editProfile";
import { setUserProfileDetails } from '../../../redux/slice/userProfile';
import { useDispatch } from "react-redux";
import { useState } from "react";
import * as Yup from 'yup'
import { useDataForProfileEdit } from "../../../hooks/userProfileEdit";
import ProfileEditNav from '../../../components/NavbarAndTabs/profileEdit'

function Index() {

    const dispatch = useDispatch()
    const userInfo = useDataForProfileEdit()
    const [data, setData] = useState({
        parentName: userInfo?.family?.parent_name || '',
        relationShip: userInfo?.family?.relationship || '',
        contact_number: userInfo?.family?.contact_number || '',
    })

    const handleSave = async () => {
        try {
            const toastId = toast.loading('Updating profile...');
            await familyDetailsValidation.validate(data, { abortEarly: false });

            const updateData = {
                ...userInfo,
                family: {
                    ...userInfo.family,
                    parent_name: data?.parentName,
                    relationship: data?.relationShip,
                    // contact_mode: data?.contactMode,
                    contact_number: data?.contact_number,
                },
            };
            const res = await updateUserProfile(updateData);
            dispatch(setUserProfileDetails(res?.data));
            toast.dismiss(toastId);
        } catch (error) {
            toast.dismiss()
            if (error instanceof Yup.ValidationError) {
                toast.error(error.inner[0].message);
            } else {
                toast.error('Something went wrong');
            }
        }
    };

    return (
        <div>
            <div className="flex flex-col h-full min-h-screen bg-white">

                <div className="flex flex-1 overflow-hidden bg-white">
                    <div className="bg-[#edf0f7] w-3/12 hidden lg:block">
                        <SideBar />
                    </div>

                    <div className="flex flex-col w-full lg:w-10/12">
                        <div className="w-[98%] ms-2 ">
                            <ProfileEditNav />
                        </div>
                        <div className="flex-1 overflow-auto p-4 lg:w-[28%] md:w-[50%] w-full text-black m-10 ms-0 md:ms-14">

                            <div className="flex">
                                <svg width="24" height="25" className="mb-2 me-1" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.695 13.5C8.08384 13.5 5 14.046 5 16.2326C5 18.4192 8.06427 18.9848 11.695 18.9848C15.3061 18.9848 18.3891 18.4379 18.3891 16.2522C18.3891 14.0664 15.3257 13.5 11.695 13.5Z" stroke="#070707" stroke-linecap="round" stroke-linejoin="round" />
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.6957 11.8944C14.0655 11.8944 15.9862 9.97281 15.9862 7.60302C15.9862 5.23323 14.0655 3.3125 11.6957 3.3125C9.32594 3.3125 7.40432 5.23323 7.40432 7.60302C7.39632 9.96481 9.3046 11.8864 11.6655 11.8944H11.6957Z" stroke="#070707" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                                Parent Name
                            </div>
                            <input type="text"
                                placeholder="Enter Parent Name"
                                className="p-[10px] ps-4 block w-full border border-gray-300 rounded-sm bg-white"
                                value={data.parentName}
                                onChange={(e) => setData(prev => ({ ...prev, parentName: e.target.value }))}
                            />
                            <div className="flex mt-7">
                                <svg width="24" height="25" className="mb-2 me-1" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.695 13.5C8.08384 13.5 5 14.046 5 16.2326C5 18.4192 8.06427 18.9848 11.695 18.9848C15.3061 18.9848 18.3891 18.4379 18.3891 16.2522C18.3891 14.0664 15.3257 13.5 11.695 13.5Z" stroke="#070707" stroke-linecap="round" stroke-linejoin="round" />
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.6957 11.8944C14.0655 11.8944 15.9862 9.97281 15.9862 7.60302C15.9862 5.23323 14.0655 3.3125 11.6957 3.3125C9.32594 3.3125 7.40432 5.23323 7.40432 7.60302C7.39632 9.96481 9.3046 11.8864 11.6655 11.8944H11.6957Z" stroke="#070707" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                                Relationship
                            </div>
                            <select
                                className="block w-full p-3 bg-white border border-gray-300 rounded-sm ps-4"
                                value={data.relationShip}
                                onChange={(e) => setData(prev => ({ ...prev, relationShip: e.target.value }))}
                            >
                                <option>Choose..</option>
                                <option value="father">Father</option>
                                <option value="mother">Mother</option>
                                <option value="brother">Brother</option>
                                <option value="sister">Sister</option>
                            </select>
                            <div className="flex mt-7">
                                <svg width="24" height="24" className="mb-2 me-1" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M16 18C16 16.9391 15.5786 15.9217 14.8284 15.1716C14.0783 14.4214 13.0609 14 12 14C10.9391 14 9.92172 14.4214 9.17157 15.1716C8.42143 15.9217 8 16.9391 8 18" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M12 14C13.6569 14 15 12.6569 15 11C15 9.34315 13.6569 8 12 8C10.3431 8 9 9.34315 9 11C9 12.6569 10.3431 14 12 14Z" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M19 4H5C3.89543 4 3 4.89543 3 6V20C3 21.1046 3.89543 22 5 22H19C20.1046 22 21 21.1046 21 20V6C21 4.89543 20.1046 4 19 4Z" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M8 2V4M16 2V4" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                                Contact Number
                            </div>
                            <input
                                className="block w-full p-3 bg-white border border-gray-300 rounded-sm ps-4"
                                value={data.contact_number}
                                type="number"
                                onChange={(e) => setData(prev => ({ ...prev, contact_number: e.target.value }))}>
                            </input>
                            <div className="flex items-start justify-start w-full pt-4 mt-10">
                                <button className="block w-full p-3 text-white border rounded-sm ps-4 bg-brandRed" onClick={handleSave}>
                                    Save
                                </button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Index
