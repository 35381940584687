import React from 'react'
import { informationWeCollect, mainPoints } from "utils/constant/PrivacyPolicy"

const PrivacyPolicy = () => {
    return (
        <>
            <div className="flex flex-col items-start justify-center p-10 text-black bg-white">
                <h1 className='w-full text-3xl font-bold text-center'>Privacy Policy</h1>

                <h1 className="mt-4"> <strong>Effective Date: </strong> 20th August 2024</h1>
                <p>
                    Couple Squad Private Limited (“Couple Squad,” “we,” “us,” or “our”) values your privacy and is committed to protecting your personal information. This Privacy Policy (“Policy”) explains how we collect, use, share, and protect your information when you use our website, mobile application (“App”), or any other services provided by Couple Squad (collectively, the “Service” or “Platform”). By accessing or using the Service, you agree to this Policy. If you do not agree with this Policy, please do not use the Service.
                </p>

                <p className="mt-4">
                    <strong>Datrimony®</strong> is a registered trademark of Couple Squad Private Limited. It represents our unique process that blends dating and matrimonial services to offer a seamless transition from dating to marriage. This trademarked service is exclusive to Couple Squad and cannot be used or replicated by any other entity without the express written consent of Couple Squad Private Limited.
                </p>

                <h1 className="mt-4 text-lg font-semibold">1. Information We Collect</h1>
                <h1 className="text-base font-semibold">1.1 Registration Information</h1>
                <p>
                    When you create an account with Couple Squad, we may collect certain personal information (“Registration Information”) from you, such as:
                </p>
                <ul className="font-semibold">
                    <li>• Name</li>
                    <li>• Username</li>
                    <li>• Email address</li>
                    <li>• Phone number</li>
                    <li>• Gender identity</li>
                    <li>• Date of birth</li>
                    <li>• Religion</li>
                    <li>• Nationality</li>
                    <li>• Location</li>
                    <li>• Photographs</li>
                </ul>

                <p className="mt-4">
                    You can update or change your Registration Information at any time by logging into your account. It is your responsibility to keep your account details up to date. Some Registration Information, such as your name, gender identity, and photographs, may be visible to other users on your profile page.
                </p>

                {informationWeCollect?.map((item, index) => (
                    <div className="mt-4" key={index}>
                        <h1 className="text-base font-semibold">{item?.title}</h1>
                        <p dangerouslySetInnerHTML={{ __html: item.description }} />
                    </div>
                ))}


                <h1 className="mt-4 text-lg font-semibold">2. How We Use Your Information</h1>
                <p>
                    Our primary goal is to provide you with a safe, enjoyable, and personalised experience on Couple Squad. Here’s how we use the information we collect:
                </p>

                <ul>
                    <li><strong>• To provide our services and features:</strong> We use your information to create your profile, show you relevant matches, and allow you to participate in Datrimony services and events.</li>
                    <li><strong>• To communicate with you:</strong> We may contact you with information about the Service, updates, and new features. We may also send you marketing messages if you’ve opted in.</li>
                    <li><strong>• To personalise your experience:</strong> We use your information to tailor the content, matches, and events you see based on your preferences and location.</li>
                    <li><strong>• To improve the Service:</strong> We analyse how users interact with the Service to improve our offerings, conduct research, and test new features.</li>
                    <li><strong>• To ensure safety and security:</strong> We use your information to verify your identity, prevent fraud, and monitor for breaches of our Terms of Service.</li>
                    <li><strong>• To process payments:</strong> We use your payment information to process transactions and ensure the security of your financial data.</li>
                    <li><strong>• To serve relevant ads:</strong> We may use your information to show you targeted ads based on your interests and interactions with the Service.</li>
                </ul>

                <h1 className="mt-4 text-lg font-semibold">3. Sharing Your Information</h1>
                <p>
                    We may share your information with third parties in the following circumstances:
                </p>
                <ul>
                    <li><strong>• Service Providers:</strong> We work with trusted third-party service providers to perform functions and provide services to us, such as payment processing, customer support, and data analysis. These providers only have access to the information necessary to perform their functions.</li>
                    <li><strong>• Event Organisers:</strong> If you register for an event, we may share your information with event organisers to facilitate your participation.</li>
                    <li><strong>• Legal and Safety Reasons:</strong> We may disclose your information if required by law or if we believe it is necessary to protect the rights, property, or safety of Couple Squad, our users, or others.</li>
                    <li><strong>• Business Transfers:</strong> In the event of a merger, acquisition, or sale of assets, your information may be transferred to the new owners.</li>
                    <li><strong>• Marketing Partners:</strong> We may share limited information with marketing partners to help us advertise our services and measure the effectiveness of our campaigns.</li>
                    <li><strong>• Aggregated Information:</strong> We may share aggregated data that does not identify you personally for research, analysis, and marketing purposes.</li>
                </ul>

                {mainPoints?.map((item, index) => (
                    <div className="mt-4" key={index}>
                        <h1 className="text-base font-semibold">{item?.title}</h1>
                        <p dangerouslySetInnerHTML={{ __html: item.description }} />
                    </div>
                ))}

            </div>
        </>
    )
}
export default PrivacyPolicy