import { configureStore } from '@reduxjs/toolkit';
import userProfileReducer from './slice/userProfile';
import userSelectedLocationReducer from './slice/selectedLocation';
import guideReducer from './slice/guid';
import paymentCancellation from './slice/paymentCancellation';


export const store = configureStore({
    reducer: {
        userProfile: userProfileReducer,
        userSelectedLocation: userSelectedLocationReducer,
        guide: guideReducer,
        paymentCancellationPopup: paymentCancellation,
    },
});
