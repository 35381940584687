import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

function Index() {
    const userInfo = useSelector((state) => state.userProfile);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const tabs = [
        { name: 'Basic Information', location: '/profile/edit/basic' },
        { name: 'Physical Attributes', location: '/profile/edit/physical' },
        { name: 'Diet Attributes', location: '/profile/edit/diet' },
        { name: 'Location', location: '/profile/edit/location' },
        { name: 'Educational Details', location: '/profile/edit/education' },
        ...(userInfo?.personal?.relationship_status === 'single'
            ? [{ name: 'Family Details', location: '/profile/edit/family-details' }]
            : []),
        { name: 'Photos & Gallery', location: '/profile/edit/photo' },
        { name: 'Languages Known', location: '/profile/edit/languages' },
        { name: 'My Interest', location: '/profile/edit/interest' },
        // { name: 'Connected Accounts', location: '/profile/edit/connected-accounts' }
    ];


    const [selectedTab, setSelectedTab] = useState();
    const location = useLocation();
    const tabRefs = useRef([]);

    useEffect(() => {
        const currentSidebarItem = tabs.find(item => item.location === location.pathname);
        if (currentSidebarItem) {
            setSelectedTab(currentSidebarItem.name);
        } else {
            setSelectedTab(undefined);
        }
    }, [location]);

    useEffect(() => {
        if (selectedTab) {
            const index = tabs.findIndex(tab => tab.name === selectedTab);
            if (tabRefs.current[index]) {
                tabRefs.current[index].scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
            }
        }
    }, [selectedTab, tabs]);

    return (
        <div className='w-full my-5 overflow-x-auto hide-scrollbar'>
            <div className='flex gap-1 flex-nowrap'>
                {tabs.map((item, index) => (
                    <div
                        key={index}
                        className={`p-1 px-3 text-[16px] cursor-pointer whitespace-nowrap ${selectedTab === item.name ? 'w-fit rounded-full bg-brandRed text-white' : 'text-[#5d6577]'}`}
                        onClick={() => setSelectedTab(item.name)}
                        ref={el => tabRefs.current[index] = el}
                    >
                        <Link to={item.location} onClick={(e) => !item.location && e.preventDefault()}>
                            {item.name}
                        </Link>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default React.memo(Index);
