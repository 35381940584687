import { data } from 'autoprefixer';
import io from 'socket.io-client';
let socket;

const connectSocket = () => {
  if (!socket || !socket.connected) {
    const token = localStorage.getItem('session');
    const headers = token ? { Authorization: `Bearer ${token}` } : {};
    socket = io(process.env.REACT_APP_API_URL, {
      extraHeaders: headers,
    });
    socket.connect();
    socket.emit('join');
  }
};

const disconnectSocket = () => {
  if (socket && socket.connected) {
    socket.disconnect();
  }
};

// Match events
const skipUser = (userId) => {
  socket.emit('skipUser', userId);
};

const connectUser = (connect_id) => {
  socket.emit('connectUser', connect_id);
};

//Message events
const newMessage = ({ room_id, message }) => {
  socket.emit('newMessage', { room_id, message });
}

const getMessages = (room_id) => {
  socket.emit('getMessages', { room_id })
}

const updateOnlineStatus = () => {
  socket.emit('markAsOnline');
}

// const getOnlineUsers = () => {
//   socket.emit('getOnlineUsers');
// }



const updateEndUserBalance = (requested_user) => {
  socket.emit('updateEndUserCoin', { requested_user });
}
export { socket, connectSocket, disconnectSocket, skipUser, connectUser, newMessage, getMessages, updateOnlineStatus, updateEndUserBalance };


// NEW Vertion

// SendNewMessages
export const sendNewMessage = (room_id, message) => {
  socket.emit("newMessage", { room_id, message })
}

// Mark Message As Read
export const markMessageAsRead = (message_id) => {
  socket.emit('markAsRead', { message_id });
}

// Mark User As Online
export const markAsOnline = () => {
  socket.emit('markAsOnline');
}

// Mark User As Offline
export const markAsOffline = () => {
  socket.emit('markAsOffline');
}

// Mark User As Offline
export const getOnlineUsers = () => {
  socket.emit('getOnlineUsers');
}

// ******* GPS ******
// Emitter
export const socketEmitter = (event, data) => {
  socket.emit(event, data || null);
}