import { useState, useCallback, useEffect, useMemo } from "react";
import { State, City } from 'country-state-city';

import SideBar from "../../../components/sidebars/settings"
import FlagIcon from '../../../assets/profileEdit/groomsLocation/Flag.svg'
import GlobeIcon from '../../../assets/profileEdit/groomsLocation/GlobeIcon.svg'
import MapIconIcon from '../../../assets/profileEdit/groomsLocation/MapIcon.svg'
import homeIcon from '../../../assets/profileEdit/groomsLocation/homeIcon.svg'
import countrys from '../../../assets/json/countrys.json'
import { toast } from "react-toastify";
import { locationValidation } from "../../../utils/validations/editProfile";
import { updateUserProfile } from "../../../apis/editProfile";
import { setUserProfileDetails } from '../../../redux/slice/userProfile';
import * as Yup from 'yup'
import { useDispatch } from "react-redux";
import { useDataForProfileEdit } from "../../../hooks/userProfileEdit";
import ProfileEditNav from '../../../components/NavbarAndTabs/profileEdit'

function Index() {

    const dispatch = useDispatch()
    const userInfo = useDataForProfileEdit()
    const [location, setLocation] = useState({
        countryName: userInfo?.address?.country || '',
        countryCode: '',
        stateName: userInfo?.address?.state || '',
        stateCode: '',
        city: userInfo?.address?.current_city || '',
        citizenship: userInfo?.address?.citizenship || '',
    });

    const countryOption = useMemo(() => countrys.find(country => country.name === location.countryName) || {}, [location.countryName]);
    const stateOptions = useMemo(() => countryOption.code ? State.getStatesOfCountry(countryOption.code.toUpperCase()) : [], [countryOption.code]);
    const stateCode = useMemo(() => stateOptions.find(state => state.name === location.stateName) || {}, [stateOptions, location.stateName]);
    const cityOptions = useMemo(() => {
        if (countryOption.code && stateCode.isoCode) {
            return City.getCitiesOfState(countryOption.code.toUpperCase(), stateCode.isoCode.toUpperCase());
        }
        return [];
    }, [countryOption.code, stateCode.isoCode]);


    const handleCitizenshipChange = useCallback((e) => {
        setLocation(prev => ({ ...prev, citizenship: e.target.value }));
    }, []);

    const handleCountryChange = useCallback((e) => {
        const name = e.target.value;
        const code = countrys.find(country => country.name === name)?.code.toUpperCase() || '';
        setLocation(prev => ({ ...prev, countryName: name, countryCode: code }));
    }, []);

    const handleStateChange = useCallback((e) => {
        const name = e.target.value;
        const code = stateOptions.find(state => state.name === name)?.isoCode.toUpperCase() || '';
        setLocation(prev => ({ ...prev, stateName: name, stateCode: code }));
    }, [stateOptions]);

    const handleCityChange = useCallback((e) => {
        setLocation(prev => ({ ...prev, city: e.target.value }));
    }, []);

    useEffect(() => {
        if (countryOption) {
            const countryCode = countryOption.code ? countryOption.code.toUpperCase() : '';
            setLocation(prev => ({ ...prev, countryCode }));
        }
    }, [countryOption]);

    const handleSave = async () => {
        try {
            const toastId = toast.loading('Updating profile...');
            await locationValidation.validate(location, { abortEarly: false });

            const updateData = {
                ...userInfo,
                address: {
                    country: location.countryName,
                    state: location.stateName,
                    current_city: location.city,
                    citizenship: location.citizenship,
                },
            };

            const res = await updateUserProfile(updateData);
            dispatch(setUserProfileDetails(res?.data));
            toast.dismiss(toastId);
        } catch (error) {
            toast.dismiss();
            console.error('Error in save:', error);
            if (error instanceof Yup.ValidationError) {
                toast.error(error.inner[0].message);
            } else {
                toast.error('Something went wrong');
            }
        }
    };

    return (
        <div>
            <div className="flex flex-col h-full min-h-screen bg-white">

                <div className="flex flex-1 overflow-hidden bg-white">
                    <div className="bg-[#edf0f7] w-3/12 hidden lg:block">
                        <SideBar />
                    </div>

                    <div className="flex flex-col w-full lg:w-10/12">
                        <div className="w-[98%] ms-2 ">
                            <ProfileEditNav />
                        </div>
                        <div className="flex-1 overflow-auto p-4 lg:w-[28%] md:w-[50%] w-full text-black m-10 ms-0 md:ms-14">
                            {/* Citizenship */}
                            <>
                                <div className='flex mt-7'>
                                    <img src={FlagIcon} alt="icon" className="mb-3 me-1" />
                                    <span>Citizenship</span>
                                </div>

                                <div className="relative">
                                    <select
                                        className="block w-full p-3 pr-10 font-medium bg-white border border-gray-300 rounded-sm appearance-none"
                                        name="select-dropdown"
                                        onChange={handleCitizenshipChange}
                                        value={location?.citizenship}
                                    >
                                        <option>Choose...</option>
                                        {countrys.map((value) => (
                                            <option key={value.name} value={value.name}>
                                                {value.name}
                                            </option>
                                        ))}
                                    </select>
                                    <div className="absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 pointer-events-none">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M18 9L12 15L6 9" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                    </div>
                                </div>
                            </>
                            {/* County */}
                            <>
                                <div className='flex mt-7'>
                                    <img src={GlobeIcon} alt="icon" className="mb-3 me-1" />
                                    <span>County</span>
                                </div>

                                <div className="relative">
                                    <select
                                        className="block w-full p-3 pr-10 font-medium bg-white border border-gray-300 rounded-sm appearance-none"
                                        name="select-dropdown"
                                        onChange={handleCountryChange}
                                        value={location?.countryName}
                                    >
                                        <option>Choose...</option>
                                        {countrys.map((value) => (
                                            <option key={value.name} value={value.name}>
                                                {value.name}
                                            </option>
                                        ))}
                                    </select>
                                    <div className="absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 pointer-events-none">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M18 9L12 15L6 9" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                    </div>
                                </div>
                            </>

                            {/* State */}
                            <>
                                <div className='flex mt-7'>
                                    <img src={MapIconIcon} alt="icon" className="mb-3 me-1" />
                                    <span>State</span>
                                </div>

                                <div className="relative">
                                    <select
                                        className="block w-full p-3 pr-10 font-medium bg-white border border-gray-300 rounded-sm appearance-none"
                                        name="select-dropdown"
                                        onChange={handleStateChange}
                                        value={location?.stateName}
                                    >
                                        <option>Choose...</option>
                                        {stateOptions.map((value) => (
                                            <option key={value.name} value={value.name}>
                                                {value.name}
                                            </option>
                                        ))}
                                    </select>
                                    <div className="absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 pointer-events-none">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M18 9L12 15L6 9" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                    </div>
                                </div>
                            </>
                            {/* Current City */}
                            <>
                                <div className='flex mt-7'>
                                    <img src={homeIcon} alt="icon" className="mb-3 me-1" />
                                    <span>Current City</span>
                                </div>

                                <div className="relative">
                                    <select
                                        className="block w-full p-3 pr-10 font-medium bg-white border border-gray-300 rounded-sm appearance-none"
                                        name="select-dropdown"
                                        onChange={handleCityChange}
                                        value={location?.city}
                                    >
                                        <option>Choose...</option>
                                        {cityOptions.map((value) => (
                                            <option key={value.name} value={value.name}>
                                                {value.name}
                                            </option>
                                        ))}
                                    </select>
                                    <div className="absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 pointer-events-none">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M18 9L12 15L6 9" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                    </div>
                                </div>
                            </>


                            <div className="flex items-start justify-start w-full pt-4 mt-10">
                                <button className="block w-full p-3 text-white border rounded-sm ps-4 bg-brandRed" onClick={handleSave}>
                                    Save
                                </button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div >
    )
}

export default Index
