import { useState } from "react";
import SideBar from "../../../components/sidebars/settings"
import BasicFilter from "../../../components/filters/basicFilter"
import AdvanceFilter from "../../../components/filters/advanceFilter"


function Index() {
    const [selectedOption, setSelectedOption] = useState("basic")


    return (
        <div>
            <div className="flex flex-col h-full">


                <div className="flex flex-col w-full bg-white rounded-md">
                    <div className="flex-1 w-full p-4 overflow-auto text-black md:m-10 md:ms-14">

                        <div className="flex items-start justify-start w-full pt-4 mt-10">
                            <button className={`p-3 ps-4 block w-[50%] md:w-[30%] lg:w-[15%] border  ${selectedOption === 'basic' ? 'bg-brandRed text-white' : 'bg-white text-gray-500'}  rounded-sm`} onClick={() => setSelectedOption('basic')}>
                                Basic
                            </button>
                            <button className={`p-3 ps-4 block w-[50%] md:w-[30%] lg:w-[15%] border ${selectedOption === 'advance' ? 'bg-brandRed text-white' : 'bg-white text-gray-500'} rounded-sm`} onClick={() => setSelectedOption('advance')}>
                                Advance
                            </button>
                        </div>

                        {selectedOption === 'basic' ?
                            <div className='flex '>
                                <div className='w-full md:w-[60%] lg:w-[45%] flex flex-col justify-start p-4'>
                                    <BasicFilter />
                                </div>
                            </div>
                            :
                            <div className='flex '>
                                <div className='w-full md:w-[60%] lg:w-[45%] flex flex-col justify-start p-4'>
                                    <AdvanceFilter />
                                </div>
                            </div>
                        }
                    </div>

                </div>
            </div>
        </div>

    )
}

export default Index
