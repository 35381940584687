import { auth } from "..";

export const GetBasicFilter = async () => {
    const res = await auth({
        method: "GET",
        url: `/users/basic-preferences`
    });

    return res
};

export const updateBasicFilter = async (data) => {
    const res = await auth({
        method: "PUT",
        url: `/users/basic-preferences`,
        data
    });

    return res
};

export const getAdvanceFilter = async () => {
    const res = await auth({
        method: "GET",
        url: `/users/advanced-preferences`
    })
    return res
};

export const updateAdvanceFilter = async (data) => {
    const res = await auth({
        method: "PUT",
        url: `/users/advanced-preferences`,
        data
    });
    return res
};

export const getAllNotifications = async (page) => {
    const res = await auth({
        method: "GET",
        url: `/notifications?page=${page}&limit=10`
    })
    return res
};