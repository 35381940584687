import React from 'react';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';

import bgImage from 'assets/siteIntro/aboutUs/images/bannerBg.png';

function Index() {

    const countsAndContent = [
        { count: 3000, text: "Online & Offline Events" },
        { count: 10000, text: "DailyVisitors" },
        { count: 20, text: "Applications Received", suffix: 'k' },
        { count: 20, text: "Event Categories" },
        { count: 25, text: "Mini Dates" },
        { count: 3, text: "Cities" },
    ]

    return (
        <div
            className="relative flex flex-col items-center justify-center h-screen text-white bg-center bg-cover"
            style={{
                backgroundImage: `url(${bgImage})`,
            }}
        >

            <div className="flex flex-wrap mb-7 space-x-2 w-[70%] justify-around items-center bg-black opacity-70 rounded-lg lg:mt-44">
                {countsAndContent.map((item) => (
                    <div className="my-8 text-center">
                        <CountUp end={item.count} duration={10} redraw={true}>
                            {({ countUpRef, start }) => (
                                <VisibilitySensor onChange={start} delayedCall>
                                    <div className="text-xl font-semibold lg:text-4xl">
                                        <span ref={countUpRef} />{item?.suffix} +
                                    </div>
                                </VisibilitySensor>
                            )}
                        </CountUp>
                        <p className="text-base lg:text-xl">{item.text}</p>
                    </div>
                ))}
            </div>

            {/* Content */}
            <div className="relative z-10 max-w-3xl p-8 text-center bg-black opacity-70">
                <h2 className="mb-2 font-medium text-md md:text-lg">
                    Connect with Urban Indians
                </h2>
                <h1 className="mb-4 text-xl font-bold md:text-5xl">
                    Couple <span className="text-red-500">S</span>quad
                </h1>
                <p className="mb-6 text-sm md:text-lg">
                    Couple Squad is a social community that connects Indian Professionals through events to build genuine relationships.
                </p>
            </div>

        </div>
    );
};


export default React.memo(Index);
