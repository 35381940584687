import { blockUser, reportUser, unBlockUser } from 'apis/users';
import React from 'react';
import { toast } from 'react-toastify';
import { capitalize } from 'shared/textFormats';

const Index = ({ data, closeBtn, type, onSuccess }) => {


    const handleConfirm = async () => {
        let res
        if (type === 'block') {
            res = await blockUser({ blockedUser: data?.id })
        } else if (type === 'report') {
            res = await reportUser({ reportedId: data?.id })
        } else if (type === 'unBlock') {
            res = await unBlockUser({ blockedUser: data?.id })
        }

        if (res?.success) {
            let message
            if (type === 'block') {
                message = 'Blocked the user'
            } else if (type === 'report') {
                message = 'Reported the user'
            } else if (type === 'unBlock') {
                message = 'UnBlocked the user'
            }
            toast.success(message)
            closeBtn()
            onSuccess()
        }
    }


    return (

        <div className="flex items-center justify-center">
            <div className="bg-white p-6 rounded-lg shadow-lg w-[95%] md:w-[480px] h-[530px] flex flex-col items-center justify-center text-center">

                <h1 className='font-semibold text-[30px] text-black'>{`${capitalize(type)} user`}</h1>

                <img src={data?.photo} alt="" className=' w-[150px] md:w-[200px]  h-[150px] md:h-[200px] rounded-full mt-10 border-[4px] border-brandRed' />

                <p className='text-[20px]  max-w-[300px] text-black my-5'>{`Are you sure about to ${capitalize(type)} ${data?.name} ?`}</p>

                <div className="flex items-center justify-around w-full text-center">
                    <button className="w-[45%] md:w-[180px] h-[56px] px-4 py-2 text-black font-semibold border border-black" onClick={closeBtn}>
                        Cancel</button>
                    <button className="w-[45%] md:w-[180px] h-[56px] px-4 py-2 bg-brandRed text-white font-semibold hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-400" onClick={handleConfirm}>
                        Yes Confirm</button>
                </div>
            </div>
        </div>
    );
};

export default React.memo(Index)
