import React, { useState } from 'react'

import {
    // Active
    peopleActive, eventActive, offerActive, hotspotActive,
    // Inactive
    peopleInactive, eventInactive, offerInactive, hotspotInactive,
} from 'assets/gps/icons'
import { Link } from 'react-router-dom'
import { GPS } from 'shared/router'
import { toast } from 'react-toastify'

function Index({ handleScan }) {

    const [selectedModel, setSelectedModel] = useState()

    const content = [
        { icons: [peopleActive, peopleInactive], title: 'People', description: "See who's around you! Connect with nearby." },
        { icons: [eventActive, eventInactive], title: 'Events', description: "Discover exciting events happening nearby. Join and meet like-minded people." },
        { icons: [offerActive, offerInactive], title: 'Offers', description: "Check out special offers at locations near." },
        { icons: [hotspotActive, hotspotInactive], title: 'Hotspot', description: "Explore active hotspots in the city where singles are mingling right now." },
    ]


    return (
        <div className="flex flex-col w-full text-black ">
            {/* Title */}
            <div className="my-5 text-2xl font-semibold text-left">Select Mode</div>

            {/* Options Grid */}
            <div className="grid self-center w-full grid-cols-1 gap-5 mb-10 lg:grid-cols-2">
                {content?.map((item, index) => (
                    <div
                        key={index}
                        onClick={() => item?.title === 'People' ? setSelectedModel('People') : toast('coming soon')}
                        className={`bg-white  flex items-center w-full p-6 rounded-lg shadow-sm  transition-all border hover:shadow-lg cursor-pointer ${item?.title === 'People' ? '' : 'opacity-35'} ${selectedModel === item?.title ? 'border-indigo-500 text-indigo-500' : 'border-gray-300'}`}
                    >
                        <img
                            src={selectedModel === item?.title ? item?.icons[0] : item?.icons[1]}
                            alt="models icon"
                            className="w-10 h-10 mr-4"
                        />
                        <div className="flex flex-col">
                            <h2 className="mb-2 text-lg font-semibold">{item?.title}</h2>
                            <p className={`text-sm ${selectedModel === item?.title ? 'text-indigo-500' : 'text-gray-600'}`}>{item?.description}</p>
                        </div>
                    </div>
                ))}
            </div>

            <div className="grid self-center w-full grid-cols-1 gap-5 mb-10 lg:grid-cols-2">
                {/* History Button */}
                <Link to={GPS.HISTORY} className="self-center w-full px-8 py-4 font-semibold text-center border border-gray-300 rounded-md">
                    History
                </Link>

                {/* Start Scan Button */}
                <button onClick={handleScan} className="self-center w-full px-8 py-4 font-semibold text-white transition-all bg-indigo-500 rounded-md shadow-md hover:bg-indigo-600">
                    Start Scan
                </button>
            </div>


        </div>


    )
}

export default Index