import moment from 'moment';

export function convert12HourTo24Hour(time12) {  // 02:30 PM
    const [time, period] = time12.split(' ');
    let [hours, minutes] = time.split(':');
    hours = parseInt(hours, 10);
    if (period === 'PM' && hours !== 12) {
        hours += 12;
    } else if (period === 'AM' && hours === 12) {
        hours = 0;
    }
    hours = String(hours).padStart(2, '0');
    minutes = String(minutes).padStart(2, '0');
    return `${hours}:${minutes}`;  // 14:30
}


export function calculateTotalHours(startDate, startTime, endDate, endTime) {
    const startTime24 = convert12HourTo24Hour(startTime);
    const endTime24 = convert12HourTo24Hour(endTime);
    const startDateTimeString = `${startDate.slice(0, 10)}T${startTime24}:00.000Z`;
    const endDateTimeString = `${endDate.slice(0, 10)}T${endTime24}:00.000Z`;
    const startDateTime = new Date(startDateTimeString);
    const endDateTime = new Date(endDateTimeString);
    if (isNaN(startDateTime.getTime()) || isNaN(endDateTime.getTime())) {
        throw new Error('Invalid date or time format');
    }
    const diffMilliseconds = endDateTime.getTime() - startDateTime.getTime();
    const diffHours = diffMilliseconds / (1000 * 60 * 60);
    return Math.floor(diffHours);
}

export const formatDate = (data) => {    //  2024-05-10T00:00:00.000Z
    return moment(data).format('DD-MM-YYYY');    //  10-05-2024
}