import * as yup from 'yup';

const objectIdRegex = /^[0-9a-fA-F]{24}$/;
export const basicInformation = yup.object().shape({
    personal: yup.object().shape({
        religion_id: yup.string().matches(objectIdRegex, 'Invalid Religion'),
        caste_id: yup.string().matches(objectIdRegex, 'Invalid Caste'),
        sex: yup.string().oneOf(['male', 'female']).required(),
        detailed_bio: yup.string().max(500, "Bio must not exceed 300 characters.").required('Detailed bio is required'),
        dob: yup.string()
            .test('isValidDate', 'Date of Birth is invalid', (value) => {
                if (!value) return false;
                const parts = value.split('-');
                if (parts.length !== 3) return false;
                const year = parseInt(parts[0], 10);
                const month = parseInt(parts[1], 10);
                const day = parseInt(parts[2], 10);
                if (year < 1980) return false;
                if (month < 1 || month > 12) return false;
                const date = new Date(year, month - 1, day);
                if (date.getFullYear() !== year || date.getMonth() + 1 !== month || date.getDate() !== day) return false;
                return true;
            })
            .test('is18OrOlder', 'You must be 18 years or older', (value) => {
                if (!value) return false;
                const today = new Date();
                const birthDate = new Date(value);
                let age = today.getFullYear() - birthDate.getFullYear();
                const m = today.getMonth() - birthDate.getMonth();
                if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                    age--;
                }
                return age >= 18;
            }),
        relationship_status: yup.string().oneOf(['single', 'couple']),
        marital_status: yup.string()
            .test(
                'martialStatus-conditional-validation',
                'Invalid marital status for the selected relationship status',
                function (value) {
                    const { relationship_status } = this.parent;
                    if (relationship_status === 'single') {
                        return ['unmarried', 'divorced', 'widowed'].includes(value);
                    }
                    if (relationship_status === 'couple') {
                        return ['in_a_relationship', 'engaged', 'married'].includes(value);
                    }
                    return false;
                }),

        // timeline: yup.string().oneOf(['less_than_6_months', '1year', '1_2years'])
    }),
    name: yup.string().required(),
    contact_mode: yup.array()
});

export const dietAttributesValidation = yup.object().shape({
    eating: yup.string().oneOf(['omnivore', 'Vegetarian/Vegan', "i'm_flexible"]).optional(),
    drinking: yup.string().oneOf(["non_drinker", "social_drinker"]).optional(),
    smoking: yup.string().oneOf(["non_smoker", "occasional_smoker"]).optional(),
})

export const locationValidation = yup.object({
    countryName: yup.string(),
    stateName: yup.string(),
    city: yup.string(),
    citizenship: yup.string()
})

export const physicalAttributesValidation = yup.object().shape({
    height: yup.number().min(140, "Invalid Entry").max(250, "Invalid Entry"),
    weight: yup.number().min(40, "Invalid Entry").max(150, "Invalid Entry"),
    physical_status: yup.string(),
})

export const educationValidation = yup.object().shape({
    education: yup.string(),
    college: yup.string(),
    profession: yup.string(),
})

export const familyDetailsValidation = yup.object().shape({
    parentName: yup.string(),
    relationShip: yup.string().oneOf(["father", "mother", "brother", "sister"]),
    contact_number: yup.string().max(10, "Invalid Mobile No").min(10, "Invalid Mobile No")
})