import { auth, noAuth } from "../";


export const getHandpickedMatches = async () => {
    const res = await auth({
        method: 'GET',
        url: '/matches/handpicked-matches',
    });

    return res?.data;
}

// NEW
export const updateLocation = async (data) => {
    const res = await auth({
        method: "POST",
        url: "/users/location",
        data
    });
    return res
};

export const personalizedMatches = async () => {
    const res = await auth({
        method: "GET",
        url: `/matches/personalized-matches`,
    });

    return res
};

export const sendConnectionRequest = async (id) => {
    const res = await auth({
        method: "POST",
        url: `/connections/sent/${id}`,
    });

    return res

};
export const skipTheUser = async (id) => {
    const res = await auth({
        method: "POST",
        url: `/skip/user/${id}?type=user`,
    });

    return res
};

export const fetchMutualConnections = async (id) => {
    const res = await auth({
        method: "GET",
        url: `/contacts/mutual/${id}`,
    });

    return res
};


export const lookingForBasedConnectionRequest = async (id, data) => {
    const res = await auth({
        method: 'POST',
        url: `/connections/sent/looking-for/${id}`,
        data,
    });
    return res;
}

export const interestBasedConnectionRequest = async (id) => {
    const res = await auth({
        method: 'POST',
        url: `/connections/sent/interest/${id}`,
    });
    return res;
}

//  HandPicked
export const typeBasedConnectionRequest = async (id, type) => {
    const res = await auth({
        method: 'POST',
        url: `/connections/sent/${id}?type=${type}`,
    });
    return res;
}
export const handpickedBasedSkipRequest = async (id) => {
    const res = await auth({
        method: 'POST',
        url: `/skip/user/${id}?type=user&&match_type=handpick`,
    });
    return res;
}

// Cupid Feature
export const getInvitationLink = async (id) => {
    const res = await auth({
        method: 'POST',
        url: `/matches/match-maker/${id}`,
    });
    return res;
}

export const getMatchForGuest = async (data) => {
    const res = await noAuth({
        method: 'POST',
        url: `/matches/guest-match-maker`,
        data
    });
    return res;
}

export const likeByGuest = async (id) => {
    const res = await noAuth({
        method: 'POST',
        url: `/matches/guest-like/${id}`,
    });
    return res;
}


// Event based connection
export const sendEventBasedConnectionRequest = async (id, type) => {
    const res = await auth({
        method: "POST",
        url: `/connections/sent/${id}?type=${type}`,
    });
    return res
};
export const getEventBasedConnectionRequest = async (id) => {
    const res = await auth({
        method: "GET",
        url: `/events/auth/connections/${id}`,
    });
    return res
};
export const getFriendProfilePreview = async (id) => {
    const res = await auth({
        method: "GET",
        url: `/shortlist/profile/preview/${id}`,
    });
    return res
};

export const getConnectionRequestedProfilePreview = async (id) => {
    const res = await auth({
        method: "GET",
        url: `/connections/preview/profile/${id}`,
    });
    return res
};

export const getQrConnectionProfilePreview = async (id) => {
    const res = await auth({
        method: "GET",
        url: `/users/profile/preview/qr?encryptedId=${id}`,
    });
    return res
};

export const getMutualConnectionProfilePreview = async (id, phone) => {
    const res = await auth({
        method: "GET",
        url: `/contacts/mutual/preview/${id}?phone=${phone}`,
    });
    return res
};