import React, { useEffect, useState } from 'react'
import { walletHistory } from '../../../apis/wallet'
import History from "../components/history"

function Index() {

    const [walletHistoryData, setWalletHistoryData] = useState()
    const [pagination, setPagination] = useState({
        next_page: null,
        limit: 20,
    })

    const getWalletHistory = async () => {
        let res;
        if (pagination.next_page) {
            res = await walletHistory(pagination.next_page, pagination.limit);
        } else {
            res = await walletHistory(1, 20);
        }
        setPagination(res?.pagination);
        setWalletHistoryData(prev => {

            if (prev && Array.isArray(prev)) {
                return [...prev, ...res?.data];
            }
            return res?.data || [];
        });
    }


    useEffect(() => {
        getWalletHistory()
    }, [])

    return (
        <div className="flex flex-col h-full bg-[#f9f9f9]">

            <div className="py-12">
                <History data={walletHistoryData} />
            </div>
            {pagination.next_page &&
                <p className='text-center text-blue-500 cursor-pointer mb-7' onClick={() => getWalletHistory()}>see more</p>
            }

        </div>
    )
}

export default Index