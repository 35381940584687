import React from 'react'
import { formatDate } from 'shared/dates'

function Index({ data }) {
    return (
        <div>
            <div className="w-full border border-gray-300">
                <p className="flex items-center w-full h-16 text-base font-semibold text-black border-2 ps-14 border-gary-300">History</p>

                {data?.map((item) => (
                    <>
                        <div className="flex justify-between w-full pb-2 mt-6 text-black border-b">
                            <div className="flex">
                                {item.photo &&
                                    <img src={item.photo} alt="" className="w-14 h-14" />
                                }
                                <div>
                                    {item.name &&
                                        <h1 className="text-sm">{item.name}</h1>
                                    }
                                    {item.message &&
                                        <h1 className="text-gray-500 ms-3">
                                            <span className={`${item.type === "debit" ? 'text-red-500' : 'text-green-500'} me-2`}>
                                                {item.type === "debit" ? '-' : '+'}
                                            </span>
                                            {item.message}
                                        </h1>
                                    }
                                </div>
                            </div>
                            <div className="me-10">
                                {item.createdAt &&
                                    <h1 className="text-sm ">{formatDate(item.createdAt)}</h1>
                                }
                                {item.created_at &&
                                    <h1 className="text-sm ">{formatDate(item.created_at)}</h1>
                                }
                            </div>
                        </div>
                    </>
                ))}
            </div>
        </div>
    )
}

export default React.memo(Index)
