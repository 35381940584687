import React, { useRef } from 'react';
import { FixedCropper, ImageRestriction, CircleStencil, RectangleStencil } from 'react-advanced-cropper';
import 'react-advanced-cropper/dist/style.css';
import popup_close from 'assets/icons/popup_close.svg';

const Index = ({ image, setPopup, onCropComplete, circularCrop, ratio }) => { //file,boolean,func,false 16/1

    const cropperRef = useRef(null);

    const onCrop = async () => {
        const cropper = cropperRef.current;
        if (cropper) {
            const canvas = cropper.getCanvas();

            // Convert the canvas to a data URL
            const dataUrl = canvas.toDataURL();

            // Convert the data URL to a Blob object
            const blob = await fetch(dataUrl).then((res) => res.blob());

            // Create a File object from the Blob
            const timestamp = new Date().toISOString().replace(/:/g, '-');
            const fileName = `croppedImage_${timestamp}.png`;
            const file = new File([blob], fileName, { type: 'image/png' });
            // Now you can use the 'file' object as needed
            onCropComplete(file);
            setPopup()
        }
    };


    return (
        <div className="w-full">
            <div className='w-full'>
                <div className={` p-3 w-full flex flex-col gap-3`}>
                    <div className="flex items-center justify-between md:hidden">
                        <button className='p-2 font-semibold bg-gray-400 rounded-md shadow-2xl w-fit text-md text-brandRed shadow-gray-300' type='button' onClick={onCrop}>Save</button>
                        <img src={popup_close} alt="close" onClick={() => setPopup(false)} />
                    </div>
                    <FixedCropper
                        ref={cropperRef}
                        src={image}
                        stencilSize={{
                            width: 300,
                            height: 300
                        }}
                        stencilProps={{
                            handlers: false,
                            lines: false,
                            movable: false,
                            resizable: false,
                            grid: true,
                            ...(!circularCrop && ratio && { aspectRatio: ratio }),
                        }}
                        imageRestriction={ImageRestriction.stencil}
                        stencilComponent={circularCrop ? CircleStencil : RectangleStencil}
                    />
                </div>
                <div className="flex-col items-center justify-start hidden gap-2 md:flex">
                    <div className="absolute w-32 h-32 cursor-pointer top-1 -right-20" onClick={() => setPopup(false)}>
                        <img src={popup_close} alt="close" />
                    </div>
                    <div className="flex justify-center gap-3 px-2 py-3 bg-white rounded-full shadow-2xl min-h-max h-fit w-fit drop-shadow-2xl shadow-gray-300">
                        <button className='w-full font-semibold text-md text-brandRed' type='button' onClick={onCrop}>Save</button>
                        <button className='w-full text-gray-700 text-md' type='button' onClick={() => setPopup(false)}>Cancel</button>
                    </div>

                </div>
            </div>
        </div>
    )
};

export default Index;