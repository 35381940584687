import { memo } from 'react';

function Index({ selectedOptions, availableOptions, handleOnclick }) {
    const displaySelectedOptions = Array.isArray(selectedOptions) ? selectedOptions : [selectedOptions];

    const handleOptionClick = (value) => {
        if (Array.isArray(selectedOptions)) {
            if (displaySelectedOptions.includes(value)) {
                const updatedOptions = displaySelectedOptions.filter(option => option !== value);
                handleOnclick(updatedOptions);
            } else {
                const updatedOptions = [...displaySelectedOptions, value];
                handleOnclick(updatedOptions);
            }
        } else {
            const updatedOption = selectedOptions === value ? null : value;
            handleOnclick(updatedOption);
        }
    };

    return (
        <div className="w-full mx-auto mb-5 lg:mb-0">
            <div className="mt-10 w-full flex flex-col space-y-3">
                {availableOptions.map((label, index) => (
                    <div
                        key={index}
                        onClick={() => handleOptionClick(label.value)}
                        className={`flex justify-between items-center p-3 w-full text-black border border-gray-300 rounded-md cursor-pointer
                    ${displaySelectedOptions.includes(label.value) ? 'bg-brandRed text-white' : 'bg-white text-black'}`}
                    >
                        <span>{label.label}</span>
                        {displaySelectedOptions.includes(label.value) && (
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_2935_3871)">
                                    <path d="M1.59961 11.2008L8.79961 18.4008L22.3996 4.80078" stroke="#F83758" stroke-linecap="square" />
                                    <path d="M-0.400391 11.2008L6.79961 18.4008L20.3996 4.80078" stroke="white" stroke-linecap="square" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_2935_3871">
                                        <rect width="24" height="24" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>

                        )}
                    </div>
                ))}
            </div>
        </div>

    );
}

export default memo(Index);