import React from 'react'
import emptyImg from '../../../../assets/cards/emptyImg.png'

function Index() {
    return (
        <div className='w-full h-full bg-white flex flex-col justify-center items-center m-4'>
            <div className='mb-2 flex gap-3 px-3'>
                <img src={emptyImg} alt='limits' className='w-[120px] h-[120px]  cursor-pointer' />
                <img src={emptyImg} alt='limits' className='w-[120px] h-[120px]  cursor-pointer' />
                <img src={emptyImg} alt='limits' className='w-[120px] h-[120px]  cursor-pointer' />
            </div>
            <div className='mb-2 flex gap-3 px-3'>
                <img src={emptyImg} alt='limits' className='w-[120px] h-[120px]  cursor-pointer' />
                <img src={emptyImg} alt='limits' className='w-[120px] h-[120px]  cursor-pointer' />
                <img src={emptyImg} alt='limits' className='w-[120px] h-[120px]  cursor-pointer' />
            </div>
        </div>
    )
}

export default React.memo(Index)