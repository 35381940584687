function easeInOutQuad(t, b, c, d) {
    t /= d / 2;
    if (t < 1) return c / 2 * t * t + b;
    t--;
    return -c / 2 * (t * (t - 2) - 1) + b;
}

export function smoothScroll(container, scrollAmount, duration) {
    let start = container.scrollTop;
    let currentTime = 0;
    const increment = 20;

    const animateScroll = function () {
        currentTime += increment;
        const val = easeInOutQuad(currentTime, start, scrollAmount, duration);
        container.scrollTop = val;
        if (currentTime < duration) {
            setTimeout(animateScroll, increment);
        }
    };

    animateScroll();
}

export function smoothScrollXAxis(container, scrollAmount, duration) {
    let start = container.scrollLeft;
    let currentTime = 0;
    const increment = 20;

    const animateScroll = function () {
        currentTime += increment;
        const val = easeInOutQuad(currentTime, start, scrollAmount, duration);
        container.scrollLeft = val;
        if (currentTime < duration) {
            setTimeout(animateScroll, increment);
        }
    };

    animateScroll();
}
