import React, { useState } from 'react'

function Index({ handleClose, handleDelete }) {

    const [reason, setReason] = useState(null)
    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
            <div className='w-full md:w-[508px] h-[470px] border bg-white flex flex-col items-center justify-center'>
                <div className="">
                    <div className="flex items-center gap-1 mb-4 border-b-2 text-brandRed ms-2 border-b-brandRed w-fit">
                        <svg width="43" height="42" viewBox="0 0 43 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path opacity="0.05" d="M42.2407 20.9915C42.2407 32.5114 32.902 41.8501 21.3821 41.8501C9.86217 41.8501 0.523438 32.5114 0.523438 20.9915C0.523438 9.47154 9.86217 0.132812 21.3821 0.132812C32.902 0.132812 42.2407 9.47154 42.2407 20.9915Z" fill="#F83758" />
                            <path d="M12 30V29C12 26.7909 13.7909 25 16 25H20C22.2091 25 24 26.7909 24 29V30" stroke="#F83758" stroke-width="2" stroke-linecap="round" />
                            <path d="M18 22C16.3432 22 15 20.6569 15 19C15 17.3432 16.3432 16 18 16C19.6569 16 21 17.3432 21 19C21 20.6569 19.6569 22 18 22Z" stroke="#F83758" stroke-width="2" stroke-linecap="round" />
                            <path d="M24 15H30" stroke="#F83758" stroke-width="2" stroke-linecap="round" />
                        </svg>
                        <h1 className='text-lg'>Delete</h1>
                    </div>


                    <div className="border border-[#dce2f0] w-full md:w-[450px]">

                        <div className="w-full h-[50px] bg-brandRed text-lg font-medium text-white flex items-center justify-between">
                            <h1 className='text-base md:text-xl ms-3'>Confirm to Delete your account</h1>
                            <h1 className='mx-3 text-base cursor-pointer md:text-xl' onClick={handleClose}>&#x2715;</h1>
                        </div>

                        <div className='flex flex-col items-center justify-center'>
                            <textarea placeholder='Enter The Reason'
                                className='h-[180px] w-full md:w-[430px] border flex items-center mt-7 bg-white'
                                onChange={(e) => {
                                    setReason(e.target.value)
                                }}
                                value={reason}
                            >
                                {reason}
                            </textarea>
                        </div>

                        <div className='flex justify-center mb-1'>
                            <div className="w-full md:w-[430px] h-[58px] bg-brandRed flex justify-center items-center mt-10">
                                <p className='text-base font-medium text-white cursor-pointer md:text-xl' onClick={() => handleDelete(reason)}>Confirm Delete</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default React.memo(Index)