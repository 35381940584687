import React, { useEffect, useState } from 'react'

// Components
import TableOfContent from './components/tableOfContent'
import SocialMedia from './components/socialMedia'
import ArticleContent from './components/articleContent'

import { useNavigate, useParams } from 'react-router-dom';
import { fetchBlogDetailBySlug, fetchLatestBlogs } from 'apis/siteIntro/blog';
import { decodeFromBase64, encodeToBase64 } from 'utils/encodeDecode';
import { BLOG } from 'shared/router';
import { formatDateToDDMMYYYY } from 'shared/dates';
import { getTheApp } from 'utils/getTheApp';



function Index() {
    const { slug } = useParams();
    const navigate = useNavigate()
    const [blogData, setBlogData] = useState()
    const [toc, setToc] = useState([])
    const [latestBlog, setLatestBlog] = useState([])

    const extractHeadings = (htmlContent) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlContent, 'text/html');
        const headings = Array.from(doc.querySelectorAll('h2')).map((heading, index) => ({
            id: `heading-${index}`,
            text: heading.textContent,
        }));

        return headings;
    };

    const getBlogDetailBySlug = async () => {
        const res = await fetchBlogDetailBySlug(decodeFromBase64(slug))
        if (res?.success) {
            setBlogData(res?.data)
            const toc = extractHeadings(res?.data?.blog_content);
            setToc(toc);
        }
    }

    const getLatestBlogs = async () => {
        const res = await fetchLatestBlogs(5)
        if (res?.success) setLatestBlog(res?.data)
    }

    const handleOnClickLatestBlogTitle = (slug_url) => {
        const url = BLOG.BLOG_DETAILS.replace(':slug', encodeToBase64(slug_url));
        navigate(url)
    };

    const handleOnClickImage = (value) => {
        if (value === 'app_link') {
            getTheApp()
        } else {
            window.open(value, "_blank");
        }
    }


    useEffect(() => {
        getBlogDetailBySlug()
        getLatestBlogs()
    }, [slug])


    return (

        <div className="min-h-screen bg-white">

            <div className="flex flex-col lg:flex-row pt-[20%] md:pt-[7%] p-8  w-[90%] mx-auto">
                {/* Left Sidebar */}
                <div className="w-full lg:w-1/4 lg:pr-4">
                    <TableOfContent toc={toc} />
                </div>

                {/* Main Content */}
                <div className="w-full lg:w-2/4 lg:px-4">
                    <ArticleContent blogData={blogData} />
                </div>

                {/* Right Sidebar */}
                <div className="w-full lg:w-1/4 lg:pl-4">
                    <div className="w-full p-4 mt-5 text-black">
                        <h3 className="mb-4 text-xl font-bold">Blog Latest</h3>
                        <div className="space-y-4">
                            {latestBlog?.map((blog, idx) => (
                                <div key={idx} className="flex">
                                    <div className='mt-5'>
                                        <h4
                                            className="text-base cursor-pointer"
                                            onClick={() => handleOnClickLatestBlogTitle(blog?.slug_url)}
                                        >
                                            {blog?.title}
                                        </h4>
                                        <p className="mt-2 text-xs text-subText">
                                            {formatDateToDDMMYYYY(blog?.createdAt)}
                                        </p>
                                    </div>
                                </div>
                            ))}
                        </div>

                        <div className="mt-10">
                            <img
                                src={blogData?.advertisement_right_side?.image_url}
                                alt={blogData?.advertisement_right_side?.alt_text}
                                onClick={() => handleOnClickImage(blogData?.advertisement_right_side?.link)}
                                className='w-full lg:w-[400px] lg:h-[560px] object-cover cursor-pointer'
                            />
                        </div>
                    </div>

                    <SocialMedia />
                </div>
            </div>
        </div>

    );

}

export default Index;
