// Library imports
import React, { useState, useEffect, Suspense, lazy } from 'react'
import { useSelector } from 'react-redux'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

// APIS
import { checkPlanExpiry, expiryCoin, getEventByTimeline, getReferLink, getSavedEvent } from 'apis/home'
import { getOffers } from 'apis/offers'
import { getAllCategories } from 'apis/events'

// Components
import { ConditionalSaveOptionEventCard } from 'components/Cards/event'
import { LazyImage } from 'components/image';

import refrelImg from 'assets/home/images/refrel.png'

// Other
import { capitalize } from 'shared/textFormats'
import { encodeToBase64 } from 'utils/encodeDecode'
import { EVENTS, OFFERS } from 'shared/router'

import Banner from './components/banner'
const PlanUpgrade = lazy(() => import('components/popUps').then(module => ({ default: module.PlanUpgrade })));
const EmptyEvents = lazy(() => import('components/Cards/emptyCards').then(module => ({ default: module.EmptyEvents })));
const ToVerifyAccount = lazy(() => import("components/popUps").then(module => ({ default: module.ToVerifyAccount })))
const ConnectPlanPopUp = lazy(() => import("components/popUps").then(module => ({ default: module.ConnectPlanPopUp })))


function Index() {
    const location = useLocation();
    const navigate = useNavigate()
    const userInfo = useSelector((state) => state.userProfile);
    const { currentStep, isGuide } = useSelector((state) => state.guide);
    const [planExpire, setPlanExpire] = useState(false)
    const [showPlan, setShowPlan] = useState(false)

    const [showVerificationPopUp, setShowVerificationPopUp] = useState(false)
    const [nameOf, setNameOf] = useState({
        tag: '',
        timeLIne: ''
    })

    const [category, setCategory] = useState()
    const [offers, setOffers] = useState([])
    const [pagination, setPagination] = useState({
        tagBasedEvent: 1,
        timeLineBasedEvent: 1
    })

    const events = [
        { label: 'Upcoming', value: 'upcoming' },
        { label: 'Ongoing', value: 'ongoing' },
        { label: 'Past', value: 'past' },
        { label: 'Saved', value: 'saved' },
    ]
    const [timeLineBasedEvents, setTimeLineBasedEvents] = useState()
    const [selectedTimeLine, setSelectedTimeLine] = useState(events[0].value)




    const fetchAllCategory = async () => {
        const res = await getAllCategories()
        setCategory(res)
    }

    const handleFetchEventByTimeline = async (page = 1, selectedTimeLine) => {
        if (page === 1) {
            setTimeLineBasedEvents([])
        }
        const res = selectedTimeLine === 'saved' ? await getSavedEvent(page) : await getEventByTimeline(page, selectedTimeLine)
        if (res?.success) {
            setTimeLineBasedEvents(prevEvents => [...prevEvents, ...res?.data]);
            setPagination(prev => ({ ...prev, timeLineBasedEvent: res?.pagination?.next_page }))
        }
    }



    const handlePlanExpire = async () => {
        const res = await expiryCoin()
        if (res?.data?.expired) {
            setPlanExpire(res?.data?.expired)
        }
    }



    const fetchOffers = async () => {
        const res = await getOffers(1, 10)
        if (res?.success) setOffers(res?.data)
    }

    const handleReferee = async () => {
        const res = await getReferLink()
        if (res?.data) {
            if (!navigator.clipboard) {
                toast.error("Clipboard API not supported in this browser.");
            } else {
                toast.success('Invitation link copied to clipboard')
                await navigator.clipboard.writeText(res?.data);
            }
        }
    }

    useEffect(() => {
        handleShowPlanSuggestionPopup();
        fetchAllCategory();
        checkPlanExpiry();
        handlePlanExpire();
        fetchOffers();
        handleFetchEventByTimeline(1, selectedTimeLine);
        handleShowVerificationPopUp();
    }, []);



    const navigateToFilter = (id) => {
        const filterParams = new URLSearchParams({
            categoryId: encodeToBase64(id)
        }).toString();

        navigate(`${EVENTS.EVENT_FILTER}?${filterParams}`);
    }

    const handleShowVerificationPopUp = () => {
        const prevPath = location.state?.from;
        if (prevPath === '/login') {
            if (userInfo?.verification?.status === 'pending') {
                setShowVerificationPopUp(true)
            }
        }
    }

    const handleShowPlanSuggestionPopup = () => {
        const prevPath = location.state?.from;
        if (prevPath === '/login') {
            if (userInfo?.wallet?.current_plan?.name === 'FREE') setShowPlan(true)
        }
    }

    const handleOnClickOffer = (id) => {
        const url = OFFERS.OFFER_DETAILS.replace(':id', encodeToBase64(id))
        navigate(url)
    }

    // currentStep
    return (
        <div className="flex flex-col h-full bg-white">

            {!isGuide &&
                <>
                    {planExpire &&
                        <div className="absolute inset-0 z-50 flex items-center justify-center w-full h-full bg-black bg-opacity-75">
                            <Suspense children={<PlanUpgrade closeBtn={() => setPlanExpire(false)} />} fallback={<div>loading...</div>} />
                        </div>
                    }
                    {showVerificationPopUp &&
                        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75">
                            <div className="relative">
                                <Suspense children={<ToVerifyAccount closeBtn={() => setShowVerificationPopUp(false)} />} fallback={<div>loading...</div>} />
                            </div>
                        </div>
                    }
                    {showPlan &&
                        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75">
                            <div className="relative w-full md:w-[60%] rounded-3xl">
                                <Suspense children={<ConnectPlanPopUp close={() => setShowPlan(false)} />} fallback={<div>loading...</div>} />
                            </div>
                        </div>
                    }
                </>
            }


            <div className="md:w-[85%] w-[98%] mx-auto">
                <Banner />
            </div>

            {offers.length > 0 &&
                <div className="w-10/12 p-6 mx-auto">
                    <div className="flex items-center justify-between text-black">
                        <div className='text-[26px] font-semibold'>Offers</div>
                        <Link to={OFFERS.OFFERS} className='cursor-pointer hover:underline'>see more</Link>
                    </div>
                    <div className="flex gap-3 overflow-x-scroll scrollbar-hide">
                        {offers?.map((item, index) => (
                            <div key={index} onClick={() => handleOnClickOffer(item?.id)} className='cursor-pointer'>
                                <LazyImage src={item?.image} className={'w-auto h-auto'} />
                                {/* max-w-[400px] max-h-[200px] min-w-[400px] min-h-[200px] */}
                            </div>
                        ))}
                    </div>
                </div>
            }

            {/* Time Line Based Events */}
            <div className="flex flex-col items-center w-full bg-[#f3f3f3] my-5">
                <div className='w-10/12 h-full mt-3'>
                    <div className='flex flex-wrap gap-2 mb-10 mt-28'>
                        {events?.map((item, index) => (

                            <div key={index} className={`w-fit p-1 md:px-3 text-base md:text-2xl cursor-pointer ${selectedTimeLine === item.value ? 'text-red-500 border-red-500 font-semibold border-b-2' : 'text-black'}`}
                                onClick={() => {
                                    setSelectedTimeLine(item.value)
                                    handleFetchEventByTimeline(1, item.value)
                                    setNameOf(prev => ({ ...prev, timeLIne: item?.label }))
                                }}
                            >
                                {item.label}
                            </div>
                        ))}
                    </div>

                    <div className="flex flex-wrap gap-4 mt-5 mb-20 ">
                        {timeLineBasedEvents?.length > 0 ?
                            <>
                                {timeLineBasedEvents?.map((item) => (
                                    <div key={item.id} className='min-h-[315px] max-h-[315px]'>
                                        <ConditionalSaveOptionEventCard
                                            data={item}
                                            saveEvent={false}
                                            onClickNavigate={`/event/details/${item?.slug}`}
                                        />
                                    </div>
                                ))}
                            </>
                            :
                            <Suspense children={<EmptyEvents mainText={`No ${nameOf.timeLIne} Events`} subText={`You Have No ${nameOf.timeLIne} Events`} />} fallback={<div>loading...</div>} />
                        }
                    </div>
                    {pagination.timeLineBasedEvent !== null && (
                        <p className='text-center text-black cursor-pointer' onClick={() => handleFetchEventByTimeline(pagination.timeLineBasedEvent, selectedTimeLine)}>See more</p>
                    )}
                </div>
            </div>

            {/* category Based Events */}
            <div className="flex flex-col items-center justify-center  bg-white">
                <div className="w-[84%] h-full mb-14">
                    {/* Heading */}
                    <div className="flex items-center justify-between w-full">
                        <div className="flex-grow mb-5 text-xl font-medium text-center text-black sm:text-3xl md:text-4xl lg:text-5xl">
                            <h1>The people platform <br />Where interests become friendships</h1>
                        </div>
                    </div>
                    {/* Category Card */}
                    <div className="flex flex-wrap gap-4 justify-center">
                        {category?.map((item) => (
                            <div key={item?.id} className="flex flex-col items-center justify-center">
                                <LazyImage
                                    src={item?.image}
                                    alt={`${item?.name}-category`}
                                    className="w-auto h-auto cursor-pointer"
                                    onClick={() => navigateToFilter(item?.id)}
                                />
                                <div className="text-black mt-2 text-center text-lg max-w-[300px]">
                                    {capitalize(item?.name)}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>


            {/* Invitation Section */}
            <div className="flex flex-col items-center w-full bg-white">
                <div className='w-[84%] ms-[34px] h-full mb-16 relative'> {/* Add relative here */}
                    {/* Responsive Absolute positioned Invite Button */}
                    <button className="z-50 absolute top-[48%] left-[4%] md:top-[48%] md:left-[4%] lg:top-[185px] lg:left-[55px] px-1 py-1 sm:px-2 sm:py-1 md:px-5 md:py-2 lg:px-5 lg:py-2 bg-brandRed text-white text-xs md:text-md lg:text-lg" onClick={handleReferee}>
                        Invite
                    </button>

                    <LazyImage src={refrelImg} className="w-full h-full" />
                </div>
            </div>

        </div >
    )
}

export default Index