import React from 'react'
import gift from 'assets/cards/icons/gift.png'
import { WALLET } from 'shared/router'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'

function Index({ closeBtn }) {
    const userData = useSelector((state) => state.userProfile)
    const currentPlanName = userData?.wallet?.current_plan?.name || 'FREE';
    // FREE , CONNECT , EXPLORE , DISCOVER
    const cardTypes = [
        { currentPlan: 'FREE', bgColor: 'bg-[#1aa684]', btnBgColor: 'bg-[#202635]', title: 'Upgrade to Connect plan', description: 'Why pay when you can have it all? Unlock exclusive access to events with the Connect Plan—upgrade now!”' },
        { currentPlan: 'CONNECT', bgColor: 'bg-[#6161f8]', btnBgColor: 'bg-[#202635]', title: 'Upgrade to Explore plan', description: 'Why pay when you can have it all? Unlock exclusive access to events with the Explore Plan—upgrade now!”' },
        { currentPlan: 'EXPLORE', bgColor: 'bg-[#070707]', btnBgColor: 'bg-[#f83758]', title: 'Upgrade to Discover plan', description: 'Why pay when you can have it all? Unlock exclusive access to events with the Discover Plan—upgrade now!”' },
    ]

    const selectedCard = cardTypes.find(card => currentPlanName.startsWith(card.currentPlan)) || {};

    return (
        <div className={`w-[350px] h-[450px] ${selectedCard?.bgColor} flex flex-col text-white justify-center items-center m-4 rounded-xl relative`}>
            {closeBtn &&
                <button
                    onClick={closeBtn}
                    style={{ top: '10px', right: '10px' }}
                    className='absolute text-white  focus:outline-none font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center'
                >
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </button>
            }

            <div>
                <img src={gift} alt='limits' className=' mb-2 cursor-pointer' />
            </div>

            <div className='flex flex-col items-center justify-center gap-2'>
                <p className='font-semibold text-[18px]'>{selectedCard?.title}</p>
                <p className='text-[14px] font-normal my-4 text-center w-[90%]'>{selectedCard?.description}</p>
            </div>

            <div className='flex items-center  mt-5'>
                <button className={`w-[280px] h-[48px] ${selectedCard?.btnBgColor} cursor-pointer rounded-lg`}>
                    <Link to={WALLET.PLANES} className='font-normal text-[16px]'>Upgrade</Link>
                </button>
            </div>
        </div>
    )
}

export default React.memo(Index)
