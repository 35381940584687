// Un Authorized Routes
export const AUTH = {
    SIGNUP: '/signup',
    LOGIN: "/login",
    WELCOME: '/welcome'
}

// Authorized Routes
export const MAIN_NAV = {
    HOME: '/home',
    DISCOVER: '/discover',
    CHAT: '/chat',
    CONNECTION: '/connection'
}

// On Boarding
export const ON_BOARDING_PAGES = {
    FIRST: '/on-boarding-1',
    SECOND: '/on-boarding-2',
    THIRD: '/on-boarding-3',
    FOURTH: '/on-boarding-4',
    FIFTH: '/on-boarding-5',
    SIXTH: '/on-boarding-6',
    SEVENTH: '/on-boarding-7'
}

export const PROFILE_EDIT = {
    BASIC: '/profile/edit/basic',
    PHYSICAL: "/profile/edit/physical",
    DIET: "/profile/edit/diet",
    LOCATION: "/profile/edit/location",
    FAMILY_DETAILS: '/profile/edit/family-details',
    PHOTO: '/profile/edit/photo',
    LANGUAGES: '/profile/edit/languages',
    INTERESTS: '/profile/edit/interest',
    EDUCATION: '/profile/edit/education',
    CONNECTED_ACCOUNTS: '/profile/edit/connected-accounts'

}

export const CONNECTIONS = {
    FRIENDS: MAIN_NAV.CONNECTION,
    SUGGESTIONS: '/connection/suggestion',
    HANDPICK: '/connection/handpick',
    EVENTS: "/connection/events",
    REQUESTS: "/connection/requested",
    FRIEND_PREVIEW: "/connection/friend/preview/:id",
    REQUESTED_CONNECTION_PROFILE_PREVIEW: "/connection/requested/preview/:id",
    CONNECTION_FRIEND_PREVIEW: "/connection/preview/:id",
}

export const WALLET = {
    WALLET: "/wallet",
    CARD: "/wallet/card",
    RECHARGE: "/wallet/recharge",
    PLANES: "/plans",
    HISTORY: '/wallet/history',
    SCAN: '/wallet/scan'
}

export const SETTINGS = {
    SETTINGS: '/settings',
    PROFILE_PREVIEW: '/profile/prev',
    PARTNER_PREFERENCE: '/settings/partner-Preference',
    EVENT_PREFERENCE: '/settings/event-preference',
    PRIVACY_SETTINGS: '/settings/privacy',
    ACCOUNT_SETTINGS: "/settings/account",
    WALLET: "/settings/wallet",
    CUSTOMER_SUPPORT: "/customer-support",
    LOGOUT: "/logout",
}

export const EVENTS = {
    EVENT_DETAILS: '/event/details/:slug',
    EVENT_FILTER: '/event/filter',
}

export const BLOG = {
    BLOG: "/blog",
    BLOG_DETAILS: "/blog/detail/:slug",
}

export const OFFERS = {
    OFFERS: '/offers',
    OFFER_DETAILS: '/offer/:id'
}

export const GPS = {
    GPS: '/gps',
    HISTORY: '/gps/history'
}

export const SITE_INTO = {
    LANDING: "/",
    ABOUT: "/aboutUs",
    EVENT_HOME: "/event",
    HOST_WITH_US: "/host-with-us",
    DATRIMONY: "/Datrimony",
    BLOG: BLOG.BLOG,
    APP_DOWNLOAD: '/app-download',
}


export const LICENSE = {
    TERMS_AND_CONDITIONS: '/terms-and-conditions',
    PRIVACY_POLICIES: '/privacy-policies',
}

export const ERROR = {
    SERVER_ERROR: '/server-error',
}

export const POPUPS = '/popups'
export const EMPTY_CARDS = '/empty-cards'
export const MEETINGS = '/meetings'
export const CUPID = '/cupid/:id'
export const BOOKING_PAYMENT = '/booking/:id'