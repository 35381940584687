import { Link, useNavigate } from 'react-router-dom'
import { LICENSE, SITE_INTO } from 'shared/router'
import React from 'react'
import { LazyImage } from 'components/image';

// Assets
import csLogo from 'assets/footer/images/csLogoWhite.webp'
import phone from 'assets/footer/icons/phone.webp'
import email from 'assets/footer/icons/email.webp'
import location from 'assets/footer/icons/location.webp'

// Social Media icons
import facebookIcon from 'assets/socialMediaIcons/white/facebook.png'
import instagramIcon from 'assets/socialMediaIcons/white/instagram.png'
import linkedInIcon from 'assets/socialMediaIcons/white/linkedIn.png'
import mediumIcon from 'assets/socialMediaIcons/white/medium.png'
import pinterestIcon from 'assets/socialMediaIcons/white/pinterest.png'
import twitterIcon from 'assets/socialMediaIcons/white/twitter.png'
import youTubeIcon from 'assets/socialMediaIcons/white/youTube.png'

function Index() {

    const socialMedia = [
        { icon: linkedInIcon, url: 'https://www.linkedin.com/company/couplesquad/' },
        { icon: facebookIcon, url: 'https://www.facebook.com/wearecouplesquad' },
        { icon: instagramIcon, url: 'https://www.instagram.com/wearecouplesquad/' },
        { icon: youTubeIcon, url: 'https://www.youtube.com/@wearecouplesquad' },
        { icon: twitterIcon, url: 'https://twitter.com/wecouplesquad' },
        { icon: mediumIcon, url: 'https://medium.com/@wearecouplesquad' },
        { icon: pinterestIcon, url: 'https://in.pinterest.com/couplesquad/' },
    ];

    return (
        <div className="flex justify-center w-full bg-black">
            <div className="w-[88%]">
                <footer className="flex p-10 text-white footer">
                    <aside>
                        <LazyImage src={csLogo} alt="CoupleSquadLogo" className="w-auto max-h-18" />
                        <p className='max-w-[540px] leading-6'>Couple Squad is the first event-based social community for singles and couples, connecting Single Indian Professionals through friendly events. Our events range from single mixers to professional networking, ensuring there's something for everyone. We also prioritize mental well-being, offering relationship coaching as a key service.</p>
                        <div className="grid items-center grid-flow-col gap-10 mt-5">
                            {socialMedia?.map((item, index) => (
                                <Link to={item.url}>
                                    <LazyImage key={index} src={item?.icon} className={'w-[90px] h-[40px] cursor-pointer'} />
                                </Link>
                            ))}
                        </div>
                    </aside>

                    <div className="flex flex-wrap justify-around w-full text-white">
                        <nav className="flex flex-col space-y-5">
                            <h6 className="text-lg font-semibold text-white">Navigation</h6>
                            <Link to={SITE_INTO.LANDING} className="text-sm link link-hover">Home</Link>
                            <Link to={SITE_INTO.ABOUT} className="text-sm link link-hover">About Us</Link>
                            <Link to={SITE_INTO.DATRIMONY} className="text-sm link link-hover">Datrimony™</Link>
                            <Link to={SITE_INTO.EVENT_HOME} className="text-sm link link-hover">Events</Link>
                            <Link to={SITE_INTO.HOST_WITH_US} className="text-sm link link-hover">Host With Us</Link>
                        </nav>
                        <nav className="flex flex-col space-y-5">
                            <h6 className="text-lg font-semibold text-white">License</h6>
                            <Link to={LICENSE.PRIVACY_POLICIES} className="text-sm link link-hover">Privacy Policy</Link>
                            <Link to={LICENSE.TERMS_AND_CONDITIONS} className="text-sm link link-hover">Terms & Conditions</Link>
                        </nav>
                        <nav className="flex flex-col items-start justify-start space-y-5 text-start">
                            <h6 className="text-lg font-semibold text-white">Contact</h6>
                            <button className="flex items-center text-sm">
                                <LazyImage loading='lazy' src={phone} alt="coupleSquad" className='me-2' />
                                Toll-Free: 1800 203 9717
                            </button>
                            <button className="flex items-center text-sm">
                                <LazyImage loading='lazy' src={email} alt="coupleSquad" className='me-2' />
                                hello@couplesquad.com</button>
                            <button className="flex text-sm text-start">
                                <LazyImage loading='lazy' src={location} alt="coupleSquad" className='me-2' />
                                No B28, Centerspace Co-working Space, <br /> Kottayam, <br /> Kerala 686001</button>
                        </nav>
                    </div>
                </footer>
                <div className="mb-5 text-center text-white">
                    <p>Copyright © 2024 Couple Squad ®. All rights reserved.</p>
                </div>
            </div>
        </div>
    )
}

export default React.memo(Index)
