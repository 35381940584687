import React, { memo, useEffect, useState } from 'react';


const Index = ({ imgUrl, name, profession, actionHandler, expiry }) => {

    const [timeLeft, setTimeLeft] = useState('');

    useEffect(() => {
        const updateTimer = () => {
            const now = new Date();
            const expiryDate = new Date(expiry);
            const difference = expiryDate - now;

            if (difference <= 0) {
                setTimeLeft('Expired');
                return;
            }

            const totalHours = Math.floor(difference / (1000 * 60 * 60));
            let minutes = Math.floor((difference / 1000 / 60) % 60);
            let seconds = Math.floor((difference / 1000) % 60);


            setTimeLeft(`${totalHours}h ${minutes}m ${seconds}s`);
        };

        const interval = setInterval(updateTimer, 1000);

        return () => clearInterval(interval);
    }, [expiry]);

    return (
        <div className="event-card w-full relative bg-[#ffffff] max-w-[235px] mb-8 flex flex-col items-center">
            <div onClick={() => actionHandler('profilePrev')} className="w-full h-0 pb-[100%] relative max-h-[190px]">
                {/* <img src={imgUrl} alt="userImg" className="absolute w-full h-full object-cover top-0 left-0" /> */}
                <img src={imgUrl} alt="userImg" className="absolute w-[300px] h-56 object-cover cursor-pointer" />
                <div className="absolute text-center bottom-0 left-0 w-full h-[40px] bg-black opacity-80 text-[#FFFFFF] py-2 px-4 rounded-br-lg">
                    <p>{timeLeft}</p>
                </div>
            </div>
            <div className="event-details ms-3 text-black">
                <h2 className="event-title text-lg text-center font-medium mt-3">{name}</h2>
                <h2 className="text-sm text-gray-500 mb-3 text-center">{profession}</h2>

                <div className="flex -ms-3">
                    <div className="w-6/12 flex items-center justify-center border cursor-pointer" onClick={() => actionHandler("reject")}>
                        <div className="flex items-center justify-center p-3 space-x-2">
                            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M18.5619 7.06942L17.1917 5.69922L11.7595 11.1314L6.32723 5.69922L4.95703 7.06942L10.3892 12.5016L4.95703 17.9339L6.32723 19.3041L11.7595 13.8718L17.1917 19.3041L18.5619 17.9339L13.1297 12.5016L18.5619 7.06942Z" fill="#5D6577" />
                            </svg>

                            <p className='text-[#5D6577] text-lg'>Reject</p>
                        </div>
                    </div>
                    <div className="w-6/12 flex items-center justify-center border cursor-pointer" onClick={() => actionHandler("accept")}>
                        <div className="flex items-center justify-center p-3 space-x-2">
                            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M20.7092 7.64364L9.04788 19.3049L3.70312 13.9602L5.07333 12.59L9.04788 16.5548L19.339 6.27344L20.7092 7.64364Z" fill="#5669FF" />
                            </svg>
                            <p className='text-[#5669FF] text-lg'>Accept</p>
                        </div>
                    </div>
                </div>


            </div>
        </div>
    );
};

export default memo(Index)