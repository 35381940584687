import * as Yup from "yup";

export const nameAndDOBValidation = Yup.object({
    name: Yup.string()
        .trim()
        .required("Name is required")
        .matches(/^[^0-9]*$/, "Name should not contain numbers"),

    dob: Yup.string()
        .required("Date of Birth is required")
        .test('isValidDate', 'Date of Birth is invalid', (value) => {
            if (!value) return false;
            const parts = value.split('-');
            if (parts.length !== 3) return false;
            const year = parseInt(parts[0], 10);
            const month = parseInt(parts[1], 10);
            const day = parseInt(parts[2], 10);
            if (year < 1950) return false;
            if (month < 1 || month > 12) return false;
            const date = new Date(year, month - 1, day);
            if (date.getFullYear() !== year || date.getMonth() + 1 !== month || date.getDate() !== day) return false;
            return true;
        })
        .test('is18OrOlder', 'You must be 18 years or older', (value) => {
            if (!value) return false;
            const today = new Date();
            const birthDate = new Date(value);
            let age = today.getFullYear() - birthDate.getFullYear();
            const m = today.getMonth() - birthDate.getMonth();
            if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                age--;
            }
            return age >= 18;
        }),
    religion_id: Yup.string().required('Religion is Required')
});


export const genderValidation = Yup.object({
    gender: Yup.string()
        .oneOf(['male', 'female'], "Gender must be either male or female")
        .required("Gender is required"),
});




export const relationShipValidation = Yup.object({
    relationshipStatus: Yup.string().oneOf(['single', 'couple']).required("Relationship is required"),
    martialStatus: Yup.string()
        .required("Marital status is required")
        .test(
            'martialStatus-conditional-validation',
            'Invalid marital status for the selected relationship status',
            function (value) {
                const { relationshipStatus } = this.parent;
                if (relationshipStatus === 'single') {
                    return ['unmarried', 'divorced', 'widowed'].includes(value);
                }
                if (relationshipStatus === 'couple') {
                    return ['in_a_relationship', 'engaged', 'married'].includes(value);
                }
                return false;
            }
        ),
});


export const lookingForValidation = Yup.object({
    lookingFor: Yup.string().required("Intrust is required"),
})


const allowedIntrusts = ['Reading', 'Photography', 'Gaming', 'Music', 'Travel', 'Painting', 'Politics', 'Charity', 'Cooking', 'Pets', 'Sports', 'Fashion'];
export const intrustsValidation = Yup.object({
    intrusts: Yup.array()
        .of(Yup.string().oneOf(allowedIntrusts, 'Invalid interest selected').required('Interest is required'))
        .required('You must select at least one interest')
        .min(1, 'Select at least one interest')
});


//  Validation for the API 

export const entireDataValidation = Yup.object({
    name: Yup.string().trim().required("Name is required"),
    dob: Yup.string()
        .required("Date of Birth is required")
        .test('isValidDate', 'Date of Birth is invalid', (value) => {
            if (!value) return false;
            const parts = value.split('-');
            if (parts.length !== 3) return false;
            const year = parseInt(parts[0], 10);
            const month = parseInt(parts[1], 10);
            const day = parseInt(parts[2], 10);
            if (year < 1950) return false;
            if (month < 1 || month > 12) return false;
            const date = new Date(year, month - 1, day);
            if (date.getFullYear() !== year || date.getMonth() + 1 !== month || date.getDate() !== day) return false;
            return true;
        })
        .test('is18OrOlder', 'You must be 18 years or older', (value) => {
            if (!value) return false;
            const today = new Date();
            const birthDate = new Date(value);
            let age = today.getFullYear() - birthDate.getFullYear();
            const m = today.getMonth() - birthDate.getMonth();
            if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                age--;
            }
            return age >= 18;
        }),
    gender: Yup.string()
        .oneOf(['male', 'female'], "Gender must be either male or female")
        .required("Gender is required"),
    relationship_status: Yup.string().oneOf(['single', 'couple']).required("Relationship is required"),
    marital_status: Yup.string()
        .required("Marital status is required")
        .test(
            'martialStatus-conditional-validation',
            'Invalid marital status for the selected relationship status',
            function (value) {
                const { relationship_status } = this.parent;
                if (relationship_status === 'single') {
                    return ['unmarried', 'divorced', 'widowed'].includes(value);
                }
                if (relationship_status === 'couple') {
                    return ['in_a_relationship', 'engaged', 'married'].includes(value);
                }
                return false;
            }
        ),
    looking_for: Yup.string().required("intrust is required"),
    interests: Yup.array()
        .of(Yup.string().oneOf(allowedIntrusts, 'Invalid interest selected').required('Interest is required'))
        .required('You must select at least one interest')
        .min(1, 'Select at least one interest'),
    detailed_bio: Yup.string().max(500, 'Text should not exceed 300 characters').required('About is required'),
})