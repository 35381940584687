import React from 'react';
import Navbar from 'components/NavbarAndTabs/siteIntro';
import NotFoundImage from 'assets/cards/error/cuate.png';

function NotFoundPage() {
    return (
        <div className="flex flex-col max-h-screen min-h-screen">
            <Navbar />
            <div className="flex flex-col items-center justify-center flex-grow p-4 bg-gray-100">
                <div className="text-center text-black">
                    <h1 className="mb-4 text-4xl font-bold">OOPS ..</h1>
                    <h2 className="mb-2 text-2xl">Page not found</h2>
                    <p className="mb-8 text-lg text-gray-600">
                        The page you are looking for doesn't exist or any <br /> other error occurred, go back to the home page.
                    </p>
                    <img src={NotFoundImage} alt="Server Error" className="w-full max-w-md mx-auto mb-8" />
                </div>
            </div>
        </div>
    );
}

export default React.memo(NotFoundPage);
