import React from 'react';
import bannerImg from 'assets/welcome/Frame 1321316031.png';
import circleImg from 'assets/welcome/circle.jpg';
import appStoreIcons from 'assets/siteIntro/datrimony/icons/appStore.png'
import googlePlayIcons from 'assets/siteIntro/datrimony/icons/googlePlay.png'
import { Link } from 'react-router-dom';
import { AUTH } from 'shared/router';

const LoveStoryComponent = () => {
    return (
        <div className="flex flex-col min-h-screen bg-white md:flex-row">
            {/* Left Section */}
            <div className="relative flex-col items-start justify-center hidden w-full p-10 text-white lg:flex lg:w-6/12">
                <img src={bannerImg} alt="bannerImg" className="absolute inset-0 object-cover w-full h-full" />

                {/* Overlay for text on top of the image */}
                <div className="relative z-10 w-[70%]">
                    <h1 className="mb-4 text-6xl font-bold">Write Your Long Lasting Love Story With Us</h1>
                    <p className="mb-6 text-lg">
                        Welcome to Malayalees Trusted Social Community Platform. Find us for Dating, Matrimony, Events,
                        Relationship Coaching, and much more. Create a life story filled with love and lasting memories.
                        Stay with us for a colorful life.
                    </p>
                </div>
            </div>

            {/* Right Section */}
            <div className="flex flex-col items-center justify-center w-full min-h-screen text-center text-black bg-white lg:w-6/12">
                <img src={circleImg} alt="circleImg" className='max-h-[80%]' />

                <h2 className="mb-4 text-3xl font-bold">Discover Love Where Your Story Begins.</h2>
                <p className="mb-6 text-center text-subText">
                    Interact with people with the same interest like you.
                </p>


                {/* Buttons */}
                <div className="flex flex-col w-[90%] md:w-[50%] text-center mb-6 gap-y-5">
                    <Link to={AUTH.LOGIN} className="w-full py-3 text-white bg-brandRed">Login</Link>
                    <Link to={AUTH.SIGNUP} className="w-full py-3 border-2 text-brandRed border-brandRed">Signup</Link>
                </div>

                {/* App Store and Google Play Badges */}
                <div className="flex flex-col items-center justify-start gap-4 my-8 md:flex-row">
                    <Link to={'https://apps.apple.com/us/app/couple-squad/id6624305594'}>
                        <img src={appStoreIcons} alt="appStoreIcons" className="h-12" />
                    </Link>
                    <Link to={"https://play.google.com/store/apps/details?id=com.couplesquad.app"}>
                        <img src={googlePlayIcons} alt="googlePlayIcons" className="h-12" />
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default LoveStoryComponent;
