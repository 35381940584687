import { noAuth, auth } from "..";

export const getEvents = async ({ page, limit, mode, type, audience }) => {
    const requestUrl =
        `/events?page=` +
        page +
        `&limit=` +
        limit +
        (mode !== "none" ? `&filters[mode]=` + mode : "") +
        (type !== "none" ? `&filters[type]=` + type : "") +
        (audience !== "none" ? `&filters[audience]=` + audience : "");
    const res = await noAuth({
        method: "GET",
        url: requestUrl,
    });
    const data = res?.data;
    const pagination = res?.pagination;
    return { data, pagination };
};

export const getSingleEvent = async ({ id }) => {
    const res = await noAuth({ method: "GET", url: `/events/${id}` });
    return res?.data;
};

export const getEventByCategoryName = async (name) => {
    const res = await noAuth({
        method: 'GET',
        url: `/events/category-name/${name}`
    });
    return res?.data;
}

//  NEW
export const getAllCategories = async () => {
    const res = await noAuth({
        method: 'GET',
        url: `/events/categories`
    });
    return res?.data;
}

export const getEventBySlug = async (slug) => {
    const res = await auth({
        method: 'GET',
        url: `/events/auth/slug/${slug}`
    });
    return res;
}
export const getUnAuthEventBySlug = async (slug) => {
    const res = await noAuth({
        method: 'GET',
        url: `/events/slug/${slug}`
    });
    return res;
}

export const saveAndUnSaveEvent = async (data) => {
    const res = await auth({
        method: 'POST',
        url: `/events/auth/save`,
        data
    });
    return res
}


// ========= Booking ==========
export const preBookTheEvent = async (id) => {
    const res = await auth({
        method: 'POST',
        url: `/events/auth/prebook/${id}`,
    });
    return res
}

export const getBookingDetails = async (id) => {
    const res = await auth({
        method: 'GET',
        url: `/events/auth/payment/${id}`,
    });
    return res
}

export const bookTheEvent = async (id, data) => {
    const res = await auth({
        method: 'POST',
        url: `/events/auth/book/${id}`,
        data,
    });
    return res
}

export const postComment = async (data) => {
    const res = await auth({
        method: 'POST',
        url: `/events/auth/comment`,
        data,
    });
    return res
}

