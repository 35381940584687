import React, { useState, useEffect } from 'react'
import 'react-toastify/dist/ReactToastify.css';

import { useParams } from 'react-router-dom';

import { getConnectionRequestedProfilePreview } from 'apis/matches';
import { decodeFromBase64 } from 'utils/encodeDecode';
import { UserBasicDetails, UserBasicDetailsResponsive, UserImgPreview } from 'components/discover';


// Assets

function Index() {
    const { id } = useParams();
    const [userData, setUserData] = useState()

    const fetchFriendsProfilePreview = async () => {
        const res = await getConnectionRequestedProfilePreview(decodeFromBase64(id))
        if (res?.data) {
            setUserData(res?.data)
        }
    }

    useEffect(() => {
        fetchFriendsProfilePreview()
    }, [])


    return (
        <div className="flex flex-col h-full min-h-screen bg-white">

            <div className='flex items-center justify-center flex-grow w-full bg-white mb-14'>
                <div className='flex flex-wrap items-start justify-center w-full gap-0 md:gap-x-10 '>
                    {/* ImgPreview Component */}
                    <div className="flex flex-col items-center">
                        <div className="h-[510px] w-full md:w-[360px] mx-auto flex items-center justify-center">
                            <UserImgPreview data={userData} />
                        </div>
                    </div>


                    <div className="hidden md:block h-[580px] w-[60%] overflow-y-auto">
                        <UserBasicDetails data={userData} />
                    </div>
                    <div className="block mt-4 overflow-y-auto md:hidden">
                        <UserBasicDetailsResponsive data={userData} />
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Index