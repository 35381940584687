import React, { useEffect, useState } from 'react';
import bannerImg from 'assets/siteIntro/blog/temp/mainBanner.png'
import { fetchLatestBlogs } from 'apis/siteIntro/blog';
import { formatDateToDDMMYYYY } from 'shared/dates';
import { BLOG } from 'shared/router';
import { encodeToBase64 } from 'utils/encodeDecode';
import { useNavigate } from 'react-router-dom';

const BlogPage = () => {
    const navigate = useNavigate()
    const [latestBlog, setLatestBlog] = useState([])

    const getLatestBlogs = async () => {
        const res = await fetchLatestBlogs(5)
        if (res?.success) setLatestBlog(res?.data)
    }

    const handleOnClickLatestBlogTitle = (slug_url) => {
        const url = BLOG.BLOG_DETAILS.replace(':slug', encodeToBase64(slug_url));
        navigate(url)
    };

    useEffect(() => {
        getLatestBlogs()
    }, [])


    return (
        <div className="container py-10 mx-auto text-black">
            <h1 className="mb-8 text-4xl font-bold">Blogs</h1>

            <div className="flex flex-col lg:flex-row">
                {/* Main Blog Section */}
                <div className="w-full p-4 lg:w-[66%]">
                    <div className="relative">
                        <img
                            src={bannerImg}
                            alt="Blog Post"
                            className="object-cover w-full h-auto rounded-lg"
                        />

                        <div className="absolute p-4 bg-white rounded-lg shadow-lg -bottom-8 left-4  w-full md:w-[60%] lg:w-[50%] hidden md:block">
                            {/* <span className="text-[10px] md:text-xs font-semibold text-blue-500">Dating</span> */}
                            <h2 className="mt-2 text-sm font-bold md:text-2xl">
                                {latestBlog[0]?.title}
                                {/* The Impact of Technology on the Workplace: How Technology is Changing */}
                            </h2>
                            {/* <div className="flex items-center mt-3 text-sm text-gray-500">
                                <img
                                    src="https://via.placeholder.com/40" // Replace with author image
                                    alt="Author"
                                    className="w-8 h-8 mr-2 rounded-full"
                                />
                                <span>Jason Francisco</span>
                                <span className="ml-2">August 20, 2022</span>
                            </div> */}
                        </div>
                    </div>
                </div>

                {/* Latest Blogs Sidebar */}
                <div className="w-full p-4 mt-5 lg:w-1/3 lg:mt-0">
                    <h3 className="mb-4 text-xl font-bold">The Latest</h3>
                    <div className="space-y-4">
                        {latestBlog?.map((blog, idx) => (
                            <div key={idx} className="flex">
                                <div className='mt-5'>
                                    <h4
                                        className="text-base cursor-pointer"
                                        onClick={() => handleOnClickLatestBlogTitle(blog?.slug_url)}
                                    >
                                        {blog?.title}
                                    </h4>
                                    <p className="mt-2 text-xs text-subText">
                                        {formatDateToDDMMYYYY(blog?.createdAt)}
                                    </p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BlogPage;
