import React, { memo, useEffect, useState } from 'react';


const Index = ({ imgUrl, name, profession, actionHandler, expiry }) => {

    const [timeLeft, setTimeLeft] = useState('');

    useEffect(() => {
        const updateTimer = () => {
            const now = new Date();
            const expiryDate = new Date(expiry);
            const difference = expiryDate - now;

            if (difference <= 0) {
                setTimeLeft('Expired');
                return;
            }

            const totalHours = Math.floor(difference / (1000 * 60 * 60));
            let minutes = Math.floor((difference / 1000 / 60) % 60);
            let seconds = Math.floor((difference / 1000) % 60);


            setTimeLeft(`${totalHours}h ${minutes}m ${seconds}s`);
        };

        const interval = setInterval(updateTimer, 1000);

        return () => clearInterval(interval);
    }, [expiry]);

    return (
        <div className="event-card w-full relative bg-[#ffffff] max-w-[235px] mb-8 flex flex-col items-center shadow-lg">
            <div onClick={() => actionHandler('profilePrev')} className="w-full pb-[100%] relative max-h-[190px]">
                {/* <LazyImage src={imgUrl} alt="userImg" className="absolute w-full h-190 object-cover top-0 left-0" /> */}
                <img src={imgUrl} alt="userImg" className="absolute w-[300px] h-56 object-cover cursor-pointer" />
                <div className="absolute text-center bottom-0 left-0 w-full h-[40px] bg-black opacity-80 text-[#FFFFFF] py-2 px-4 rounded-br-lg">
                    <p>{timeLeft}</p>
                </div>
            </div>
            <div className="flex flex-col items-center event-details text-black w-full px-3">
                <h2 className="event-title text-lg text-center font-medium mt-3">{name}</h2>
                {profession &&
                    <h2 className="text-sm text-gray-500 text-center mb-2">{profession}</h2>
                }

                <div className="flex border-t max-w-[235px] mx-auto cursor-pointer" onClick={() => actionHandler("remove")}>
                    <div className="flex items-center justify-center p-3 w-full">
                        <svg width="20" height="20" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="mx-2">
                            <path d="M19.5791 6.40892L18.1975 5.02734L12.7202 10.5046L7.2429 5.02734L5.86133 6.40892L11.3386 11.8862L5.86133 17.3635L7.2429 18.7451L12.7202 13.2678L18.1975 18.7451L19.5791 17.3635L14.1018 11.8862L19.5791 6.40892Z" fill="#F83758" />
                        </svg>
                        <p className='text-[#F83758] text-base'>Remove</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default memo(Index)