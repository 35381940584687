import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import arrowleft from '../../../assets/login/arrow-left.svg'
import arrowright from '../../../assets/login/arrow-right.svg'

import linkedin from '../../../assets/login/icon1.svg'
import instagram from '../../../assets/login/icon2.svg'
import twitter from '../../../assets/login/icon3.svg'
import facebook from '../../../assets/login/icon4.svg'

const Index = ({ slides, isArrow = true }) => {
    const [currentSlide, setCurrentSlide] = useState(0);

    const handleSlideChange = (direction) => {
        if (direction === 'left') {
            setCurrentSlide((prevSlide) => (prevSlide === 0 ? slides.length - 1 : prevSlide - 1));
        } else {
            setCurrentSlide((prevSlide) => (prevSlide === slides.length - 1 ? 0 : prevSlide + 1));
        }
    };

    return (

        <div className='flex flex-col min-h-screen'>
            <div className='flex flex-col justify-center flex-grow'>
                <div className='w-[470px] flex flex-col justify-center items-start gap-3 lg:ml-16'>
                    <div className='w-96'>
                        <p className='text-4xl font-extrabold text-white'>{slides[currentSlide].mainText}</p>
                    </div>
                    <div className='w-full text-sm font-normal leading-5'>
                        <p className='text-white'>{slides[currentSlide].text}</p>
                    </div>
                    <div className='flex items-start justify-start gap-2 mt-2 cursor-pointer md:mb-3 lg:mb-0'>
                        <Link to={'https://www.linkedin.com/company/couplesquad/'}>
                            <img src={linkedin} alt='linkedin' className='mb-1' />
                        </Link>
                        <Link to={'https://www.instagram.com/wearecouplesquad/'}>
                            <img src={instagram} alt='instagram' />
                        </Link>
                        <Link to={'https://twitter.com/wecouplesquad'}>
                            <img src={twitter} alt='twitter' />
                        </Link>
                        <Link to={'https://www.facebook.com/wearecouplesquad'}>
                            <img src={facebook} alt='facebook' />
                        </Link>
                    </div>
                </div>
            </div>
            <div className='flex items-center justify-center w-full cursor-pointer lg:justify-start lg:items-start bg-brandRed'>
                <div>
                    <img src={slides[currentSlide].src} alt='couplesquad' className='md:w-64 lg:w-[425px] overflow-hidden md:ml-[220px] lg:ml-0' />
                </div>
                {isArrow &&
                    <div className='flex flex-col justify-center items-center md:mt-0 lg:mt-[180px] ml-32 gap-8'>
                        {[0, 1, 2].map((index) => (
                            <div
                                key={index}
                                className={`w-4 h-4 rounded-full cursor-pointer ${index === currentSlide ? 'bg-white' : 'bg-white bg-opacity-50'}`}
                                onClick={() => setCurrentSlide(index)}
                            ></div>
                        ))}
                        <div className='flex gap-2'>
                            <div className='flex items-center justify-center border border-white rounded-full w-14 h-14' onClick={() => handleSlideChange('left')}>
                                <button>
                                    <img src={arrowleft} alt='couplesquad' />
                                </button>
                            </div>
                            <div className='flex items-center justify-center border border-white rounded-full w-14 h-14' onClick={() => handleSlideChange('right')}>
                                <button>
                                    <img src={arrowright} alt='couplesquad' />
                                </button>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>

    );
};

export default React.memo(Index);
