import { auth, noAuth } from "..";

export const getUserDetailsForHome = async () => {
    const res = await auth({
        method: 'GET',
        url: "/users/home"
    });
    return res;
}
export const getEventTags = async () => {
    const res = await noAuth({
        method: 'GET',
        url: `/events/tags`
    });
    return res;
}

export const getEventsByTags = async (page, data) => {
    const res = await auth({
        method: 'POST',
        url: `/events/auth/tags?page=${page}&limit=8`,
        data
    });
    return res;
}

export const getEventByTimeline = async (page, type) => {
    const res = await auth({
        method: 'GET',
        url: `/events/auth/history?page=${page}&limit=8&type=${type}`
    });
    return res;
}

export const getSavedEvent = async (page) => {
    const res = await auth({
        method: 'GET',
        url: `/events/auth/save?page=${page}&limit=8`
    });
    return res;
}

// Initial Call
export const checkPlanExpiry = async () => {
    const res = await auth({
        method: 'GET',
        url: `/wallet/plan/expiry`
    });
    return res;
}

export const expiryCoin = async () => {
    const res = await auth({
        method: 'POST',
        url: `/wallet/expiry-coins`
    });
    return res;
}

export const getEventsForHomeBanner = async () => {
    const res = await noAuth({
        method: 'GET',
        url: `/events/upcoming`
    });
    return res;
}

export const getReferLink = async () => {
    const res = await auth({
        method: 'GET',
        url: `/users/refer?type=web`
    });
    return res;
}