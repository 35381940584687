import React from "react";
import appStoreIcons from 'assets/siteIntro/datrimony/icons/appStore.png'
import googlePlayIcons from 'assets/siteIntro/datrimony/icons/googlePlay.png'
import banner from 'assets/siteIntro/appDownload/image/banner.png'
import { Link } from "react-router-dom";

const Index = () => {
    return (
        <div className="relative flex flex-col lg:flex-row min-h-screen h-full items-center justify-center lg:justify-between px-6 md:px-12 py-8 md:py-12 bg-gradient-to-r from-blue-100 to-pink-100 text-black">
            {/* Text Section */}
            <div className="flex flex-col items-center lg:items-start text-center lg:text-left max-w-lg">
                <p className="text-sm font-semibold uppercase mb-2">Explore</p>
                <h1 className="text-2xl sm:text-3xl lg:text-5xl font-bold leading-tight mb-4">
                    No More Matrimony. <br />
                    No More Dating. Let’s <span className="text-pink-500">Datrimony!</span>
                </h1>
                {/* App Buttons */}
                <div className="flex items-center justify-center lg:justify-start my-6 lg:my-8 space-x-4">
                    <Link to={"https://apps.apple.com/us/app/couple-squad/id6624305594"}>
                        <img src={appStoreIcons} alt="Datrimony" className="h-10 sm:h-12" />
                    </Link>
                    <Link to={"https://play.google.com/store/apps/details?id=com.couplesquad.app"}>
                        <img src={googlePlayIcons} alt="Datrimony" className="h-10 sm:h-12" />
                    </Link>
                </div>
            </div>

            {/* Image Section */}
            <div className="absolute bottom-0 right-0">
                <img
                    src={banner}
                    alt="App Preview"
                    className="w-fit md:w-[500px] lg:w-[600px] xl:w-[700px] h-auto"
                />
            </div>
        </div>

    );
};

export default Index;
