
import React from 'react';
import { MainFooter, MainFooterResponsive } from 'components/Footer';
import { MainNavbar, MainNavbarResponsive, BottomNavbarResponsive } from "components/NavbarAndTabs";
import { avoidOptions } from 'utils/constant/routeHandler';
import whatsApp from 'assets/icons/whatsApp.png';


function Index({ children, avoid = [] }) {
    // Default avoid to an empty array if it's not provided

    const isAvoiding = (option) => avoid?.includes(option); // Utility to check if a component should be avoided

    return (
        <div className="flex flex-col min-h-screen relative">
            {/* Conditionally render Navbar */}
            {!isAvoiding(avoidOptions.NAVBAR) && (
                <div className="text-white bg-white h-18">
                    <div className="hidden lg:block">
                        <MainNavbar />
                    </div>
                    <div className="block lg:hidden">
                        <MainNavbarResponsive />
                    </div>
                </div>
            )}

            {/* Conditionally render BottomNavbarResponsive */}
            {!isAvoiding(avoidOptions.BOTTOM_NAVBAR_RESPONSIVE) && (
                <BottomNavbarResponsive />
            )}

            {/* Page Content */}
            <div className="flex-grow">
                {children}
            </div>

            {/* WhatsApp Icon */}
            <a
                href="https://wa.me/9539143723"
                target="_blank"
                rel="noopener noreferrer"
                className="fixed bottom-4 right-4 z-50 mb-16 md:mb-0"
            >
                <img
                    src={whatsApp}
                    alt="WhatsApp"
                    className="w-20 h-20 rounded-full shadow-lg hover:scale-110 transition-transform duration-200"
                />
            </a>

            {/* Conditionally render Footer */}
            {!isAvoiding(avoidOptions.FOOTER) && (
                <>
                    <div className="hidden w-full bg-black lg:block">
                        <MainFooter />
                    </div>
                    <div className="block mb-10 bg-black lg:hidden">
                        <MainFooterResponsive />
                    </div>
                </>
            )}
        </div>
    );
}

export default Index;
