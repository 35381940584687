import React from 'react';

import LeftSection from '../leftSection'
import RightSection from '../rightSection'

function Index({ data, isPreview = true }) {


    return (
        <div className="w-full h-full p-4 bg-white">

            <LeftSection data={data} />
            <div className="mb-20">
                <RightSection data={data} />
            </div>

        </div>
    );
};

export default Index;
