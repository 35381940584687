import React, { useState } from 'react'
import closeslide from '../../../assets/discover/closeslide.svg'
import './custom.css';
import BasicFilter from '../basicFilter'
import AdvanceFilter from '../advanceFilter'



function Index({ handleShowFilter }) {

  const [selectedPreference, setSelectedPreference] = useState('Basic');



  return (
    <div className='flex justify-center bg-white z-[120]'>
      {/* preferences - advanced & basic */}
      <div className='flex w-[650px] h-screen overflow-hidden bg-white z-[120]'>
        <div className='w-[650px] flex flex-col justify-start  overflow-y-auto'>

          <div className='flex justify-end items-end z-[120]'>
            <div className='flex justify-center items-center mx-auto mt-10 px-4'>
              <div className='w-[179px] h-[29px] flex justify-center items-center'>
                <p className='font-medium text-[24px] text-black'>Narrow Search</p></div>
            </div>
            <div className='cursor-pointer' onClick={handleShowFilter} >
              <img src={closeslide} alt='close' className='w-[32px] h-[32px]' /></div>
          </div>
          {/* Basic and Advance Tab */}
          <div className="flex justify-center items-center w-full mx-auto my-8">
            <div className="w-4/5 flex justify-around items-center mx-auto">
              <div className="flex flex-col justify-center items-start cursor-pointer w-2/5 space-around" onClick={() => setSelectedPreference('Basic')}>
                <p className={`w-full text-center font-medium text-[14px] ${selectedPreference === 'Basic' ? 'text-brandRed' : 'text-text_gray'}`}>Preference</p>
                <p className={`w-full text-center font-semibold text-[19px] ${selectedPreference === 'Basic' ? 'text-brandRed border-brandRed border-b-2 pb-2' : 'text-text_gray'}`}>Basic</p>
              </div>
              <div className="flex flex-col justify-center items-start cursor-pointer w-2/5 space-around" onClick={() => setSelectedPreference('Advanced')}>
                <p className={`w-full text-center font-medium text-[14px] ${selectedPreference === 'Advanced' ? 'text-brandRed' : 'text-text_gray'}`}>Preference</p>
                <p className={`w-full text-center font-semibold text-[19px] ${selectedPreference === 'Advanced' ? 'text-brandRed border-brandRed border-b-2 pb-2' : 'text-text_gray'}`}>Advanced</p>
              </div>
            </div>
          </div>

          {selectedPreference === 'Basic' ?
            <div className='flex justify-center '>
              <div className='w-[85%] flex flex-col justify-start  bg-white p-4'>
                <BasicFilter closeSlide={handleShowFilter} />
              </div>
            </div>

            :
            <div className='flex justify-center '>
              <div className='w-[85%] flex flex-col justify-start  bg-white p-4'>
                <AdvanceFilter closeSlide={handleShowFilter} />
              </div>
            </div>
          }


        </div>
      </div>
    </div >
  )
}
export default React.memo(Index)