import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { WALLET } from 'shared/router';

function PlanCard({ name, currentPlan, data = [], handlePurchase }) {

    const [selectedDuration, setSelectedDuration] = useState()

    const handleSelectChange = (selectedId) => {
        const selectedPlanData = data.find(plan => plan.id === selectedId);
        setSelectedDuration(selectedPlanData);

    };

    useEffect(() => {
        if (data.length !== 0) {
            handleSelectChange(data[0].id)
        }
    }, [data])

    return (

        <div className={`p-4 w-[300px] md:w-[320px] h-full text-white`}>
            <div className={`${name === 'CONNECT' ? "bg-[#199778]" : (name === 'EXPLORE' ? 'bg-[#6161e5]' : 'bg-[#070707]')} h-full rounded-lg`}>
                <div className="w-[90%] mx-auto mb-10">
                    <div className="flex items-center">
                        <p className="pt-5 text-xl font-medium">{name}</p>

                        {name !== 'CONNECT' && data.length > 0 && (
                            <>

                                <div className={`flex items-center justify-center p-3 w-full`}>
                                    <select
                                        name="planOptions"
                                        id="planOptions"
                                        onChange={(e) => handleSelectChange(e.target.value)}
                                        className={`py-1 px-2 bg-white text-black border-none cursor-pointer border-4 text-base mt-2`}>
                                        {data.length > 0 ? (
                                            data.map((item, index) => (
                                                <option key={item.id} value={item.id}>
                                                    {item.duration} months
                                                </option>
                                            ))
                                        ) : null}
                                    </select>
                                </div>
                            </>
                        )}
                    </div>
                    <h1 className="my-2">
                        {name === 'CONNECT' ?
                            <>
                                <h1 className="mt-2 text-2xl font-medium">Starts @</h1>
                                <h1 className="mb-4 text-3xl font-bold">₹1000</h1>
                            </>
                            :
                            <>
                                <h1 className="text-3xl font-bold">₹{selectedDuration?.amount}</h1>
                                {/* <h1 className="text-[11px] font-medium mt-2">per user/month, billed annually</h1> */}
                            </>
                        }
                    </h1>

                    <div className="pb-5 m-2 flex items-center justify-between">
                        <svg width="44" height="53" viewBox="0 0 44 53" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.3926 10.715H14.8858V13.0105C15.9883 11.3589 17.8483 10.3831 19.8233 10.4231C23.5194 10.4231 27.0371 13.3424 27.0371 18.7372C27.0371 24.1321 23.5749 26.9954 19.8233 26.9954C17.8919 27.0314 16.0597 26.1396 14.8858 24.596V34.8177H10.3926V10.715ZM22.5438 18.6733C22.5438 16.0218 20.7671 14.2742 18.6692 14.2742C16.5713 14.2742 14.8264 16.0218 14.8264 18.7332C14.8264 21.4446 16.5713 23.1323 18.6692 23.1323C20.7671 23.1323 22.5438 21.4167 22.5438 18.6733Z" fill="white" />
                            <path d="M28.1825 10.7109V29.7028C27.0047 28.1591 25.1725 27.2713 23.2411 27.3033C19.4934 27.3033 16.0312 30.2187 16.0312 35.5495C16.0312 40.8803 19.5489 43.8677 23.2411 43.8677C25.2161 43.9077 27.08 42.9359 28.1825 41.2802V43.6117H32.6718V10.7109H28.1825ZM24.3952 40.0485C22.3012 40.0485 20.5324 38.2809 20.5324 35.6495C20.5324 33.0181 22.3012 31.1665 24.3952 31.1665C26.4891 31.1665 28.242 32.9341 28.242 35.5655C28.242 38.2809 26.505 40.0485 24.3952 40.0485Z" fill="white" />
                            <path d="M12.2869 39.7232C12.4812 39.5792 12.6596 39.4272 12.8579 39.3233C13.4726 38.9473 14.2698 39.1233 14.6703 39.7232C14.9519 40.1751 14.9519 40.751 14.6703 41.2029C14.5315 41.4348 14.3649 41.6468 14.1746 41.8347C13.6114 42.3986 13.0324 42.9465 12.4614 43.5024C12.3384 43.6223 12.2631 43.6223 12.1401 43.5024C11.5373 42.9185 10.9504 42.3426 10.3396 41.7507C10.0343 41.4628 9.83202 41.0829 9.76857 40.667C9.6139 39.9871 10.0303 39.3073 10.7045 39.1513C10.7759 39.1353 10.8473 39.1233 10.9187 39.1193C11.3946 39.0953 11.8586 39.2753 12.1956 39.6192L12.2869 39.7232Z" fill="white" />
                        </svg>
                        {selectedDuration?.coins !== 0 &&
                            <h1>{selectedDuration?.coins} coins</h1>
                        }

                    </div>
                </div>
            </div>

            {name === 'CONNECT' ?
                <Link to={WALLET.RECHARGE}>
                    <button className={`bg-brandRed w-full text-white text-base rounded-md p-3 font-semibold mt-5`}>
                        Recharge</button>
                </Link>
                :
                <>
                    <button className={`bg-brandRed w-full text-white text-base rounded-md p-3 font-semibold mt-5`}
                        onClick={() => {
                            if (!currentPlan) {
                                if (selectedDuration) {
                                    handlePurchase(selectedDuration);
                                } else {
                                    toast.error("Select valid duration")
                                }
                            }
                        }}
                    >{currentPlan ? "Current Plan" : "Purchase"}</button>
                </>
            }
        </div>
    );
}

export default React.memo(PlanCard);