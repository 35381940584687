import React, { useState } from "react";
import twinIcon from "assets/cards/icons/paymentCancellationReason.png";
import { RadioBtn } from "components/UI";
import { paymentCancellationReason } from "apis/payment";
import { resetPopup } from "../../../redux/slice/paymentCancellation";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

function Index() {
    const dispatch = useDispatch()
    const id = useSelector(state => state.paymentCancellationPopup.id);
    // console.log('id', id)

    const [selectedReason, setSelectedReason] = useState(false);

    const reasons = ["Too Expensive", "Technical Issues", "Deny to Answer", "Other Issues"];

    const handleSubmit = async () => {
        if (selectedReason) {
            const data = { reason: selectedReason }
            const res = await paymentCancellationReason(id, data)
            if (res?.success) dispatch(resetPopup())
        } else {
            toast.error('Select any one of the option')
        }
    }

    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50">
            <div className="relative w-[85%] md:w-[35%] p-6 bg-white rounded-lg shadow-md">
                {/* Close Button */}
                <button
                    className="absolute flex items-center justify-center w-8 h-8 text-lg text-gray-500 rounded-full top-4 right-4 hover:text-black focus:outline-none hover:bg-gray-200"
                    onClick={() => dispatch(resetPopup())}
                >
                    &times;
                </button>

                <h2 className="text-xl font-semibold text-center">Feedback</h2>

                {/* Image placeholder */}
                <div className="flex justify-center mb-4">
                    <img src={twinIcon} alt="Feedback Icon" className="w-auto h-auto" />
                </div>

                <p className="mt-2 text-center">
                    We noticed that your payment was canceled.
                </p>

                {/* Center the radio buttons */}
                <div className="flex flex-col items-center mt-6">
                    <div className="w-[50%] space-y-4 mb-5">
                        {reasons.map((item, index) => (
                            <RadioBtn
                                key={index}
                                item={item}
                                onChange={() => setSelectedReason(item)}
                                selected={selectedReason === item}
                                selectedTextColor="text-red-500"
                                selectedBtnColor="border-red-500"
                            />
                        ))}
                    </div>

                    <button
                        onClick={handleSubmit}
                        type="submit"
                        className="w-[50%] py-2 text-white bg-red-500 rounded-sm hover:bg-red-600 focus:outline-none focus:ring focus:ring-red-300"
                    >
                        Submit
                    </button>
                </div>
            </div>
        </div>

    );
}

export default Index;
