import { auth } from '..';

export const requestContactInfo = async (id, type) => {
    const res = await auth({
        method: 'POST',
        url: `/contacts/request/${id}?request_type=${type}`,
    });
    return res;
}

export const acceptContactInfo = async (id) => {
    const res = await auth({
        method: 'POST',
        url: `/contacts/accept/${id}`,
    });
    return res;
}

export const rejectContactInfo = async (id) => {
    const res = await auth({
        method: 'POST',
        url: `/contacts/reject/${id}`,
    });
    return res;
}

export const viewContactInfo = async (id) => {
    const res = await auth({
        method: 'GET',
        url: `/contacts/view/${id}`,
    });
    return res;
}