import { auth } from "..";


export const getOffers = async (page, limit) => {
    const res = await auth({
        method: 'GET',
        url: `/offers/offers?page=${page}&limit=${limit}`
    });
    return res;
}

export const getOfferById = async (id) => {
    const res = await auth({
        method: 'GET',
        url: `/offers/offer/${id}`
    });
    return res;
}