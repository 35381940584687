
// import { useEffect } from 'react';
// import { socket } from '../socket/SocketManager';

// export const useSocketListenerWithConnectedSocket = (eventName, handler) => {
//     useEffect(() => {

//         if (socket) {
//             socket.on(eventName, handler);
//             return () => {
//                 socket.off(eventName, handler);
//             };
//         }
//     }, [eventName, handler]);
// };

import { useEffect, useRef } from 'react';
import { socket } from '../socket/SocketManager';

export const useSocketListenerWithConnectedSocket = (eventHandlers) => {
    const eventHandlersRef = useRef(eventHandlers);

    useEffect(() => {
        eventHandlersRef.current = eventHandlers;
    }, [eventHandlers]);

    useEffect(() => {
        if (socket) {
            const handlers = Object.entries(eventHandlersRef.current);

            handlers?.forEach(([eventName, handler]) => {
                socket.on(eventName, handler);
            });

            return () => {
                handlers?.forEach(([eventName, handler]) => {
                    socket.off(eventName, handler);
                });
            };
        }
    }, []);
};