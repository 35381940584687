import React from 'react'
import ghostMode from 'assets/cards/ghostMode.png'


function Index({ closeModal }) {
    return (
        <div>
            <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                <div className="bg-white rounded-lg p-6 max-w-md mx-auto shadow-lg text-center">
                    {/* Icon */}
                    <div className="flex justify-center my-5">
                        <img src={ghostMode} alt="ghostMode" />
                    </div>
                    <div className="w-[80%] mx-auto">
                        {/* Title */}
                        <h2 className="text-2xl font-semibold mb-2">
                            Turn Off Ghost Mode for Connection request
                        </h2>

                        {/* Description */}
                        <p className="text-gray-600 mb-6">
                            To use the GPS feature, please switch off Ghost Mode and get back on track!
                        </p>

                        {/* Button */}
                        <button
                            className="bg-blue-500 w-full text-white px-4 py-2 rounded-md hover:bg-blue-600 transition"
                            onClick={closeModal}
                        >
                            DONE
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Index