import { useState, useCallback } from "react";
import SideBar from "../../../components/sidebars/settings"
import SelectableDropdown from "../../../components/profileEdit/DropDown"
import wineIcon from '../../../assets/profileEdit/diet/wineIcon.svg'
import smokingIcon from '../../../assets/profileEdit/diet/smokingIcon.svg'
import vegIcon from '../../../assets/profileEdit/diet/vegIcon.svg'
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { setUserProfileDetails } from '../../../redux/slice/userProfile';
import { updateUserProfile } from "../../../apis/editProfile";
import * as Yup from 'yup'
import { dietAttributesValidation } from "../../../utils/validations/editProfile";
import { dietDrinkingOptions, dietEatingOptions, dietSmokingOptions } from "utils/constant/user/profileInfo";
import { useDataForProfileEdit } from "../../../hooks/userProfileEdit";
import ProfileEditNav from '../../../components/NavbarAndTabs/profileEdit'


function Index() {

    const dispatch = useDispatch()
    const userInfo = useDataForProfileEdit()

    // Drinking
    const [userData, setUserData] = useState({
        eating: userInfo?.misc?.eating,
        drinking: userInfo?.misc?.drinking,
        smoking: userInfo?.misc?.smoking,
    })

    // Drinking
    const drinkingIcons = { Unknown: wineIcon }
    // Smoking
    const smokingIcons = { Unknown: smokingIcon }
    // Eating
    const eatingIcons = { eating: vegIcon }

    // Handle Change
    const handleChange = useCallback(async (inputKey, value) => {
        setUserData(prev => ({
            ...prev,
            [inputKey]: value
        }));
    }, []);

    // Handle save
    const handleSave = async () => {
        try {
            const toastId = toast.loading("updating profile")
            await dietAttributesValidation.validate(userData, { abortEarly: false });

            const updateData = {
                ...userInfo,
                misc: {
                    interests: userInfo?.misc?.interests,
                    smoking: userData.smoking,
                    drinking: userData.drinking,
                    eating: userData.eating,
                },
            }
            const res = await updateUserProfile(updateData)
            dispatch(setUserProfileDetails(res?.data))
            toast.dismiss(toastId)
        } catch (error) {
            toast.dismiss()
            if (error instanceof Yup.ValidationError) {
                toast.error(error.inner[0].message);
            } else {
                toast.error('Something went wrong');
            }
        }
    }

    return (
        <div>
            <div className="flex flex-col h-full min-h-screen bg-white">

                <div className="flex flex-1 overflow-hidden bg-white">
                    <div className="bg-[#edf0f7] w-3/12 hidden lg:block">
                        <SideBar />
                    </div>

                    <div className="flex flex-col w-full lg:w-10/12">
                        <div className="w-[98%] ms-2 ">
                            <ProfileEditNav />
                        </div>
                        <div className="flex-1 overflow-auto p-4 lg:w-[28%] md:w-[50%] w-full text-black m-10 ms-0 md:ms-14">
                            {/* Drinking */}
                            <SelectableDropdown
                                title="Drinking"
                                icons={drinkingIcons}
                                values={dietDrinkingOptions}
                                handleChangeFunction={handleChange}
                                inputKey='drinking'
                                selectedValue={userData.drinking}
                            />
                            {/* Smoking */}
                            <SelectableDropdown
                                title="Smoking"
                                icons={smokingIcons}
                                values={dietSmokingOptions}
                                handleChangeFunction={handleChange}
                                inputKey='smoking'
                                selectedValue={userData.smoking}
                            />
                            {/* Eating */}
                            <SelectableDropdown
                                title="Eating"
                                icons={eatingIcons}
                                values={dietEatingOptions}
                                handleChangeFunction={handleChange}
                                inputKey='eating'
                                selectedValue={userData.eating}
                            />

                            <div className="flex items-start justify-start w-full pt-4 mt-10">
                                <button className="block w-full p-3 text-white border rounded-sm ps-4 bg-brandRed" onClick={handleSave}>
                                    Save
                                </button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Index
