import React, { lazy, Suspense, useState } from 'react';
import { CONNECTIONS } from 'shared/router';
import { useNavigate } from 'react-router-dom';
import { capitalize } from 'shared/textFormats';
import GalleryDiscover from '../GalleryDiscover';

// Assets
import block from 'assets/discover/icons/block.png';
import unblock from 'assets/discover/icons/unblock.png';
import report from 'assets/discover/icons/report.png';
import timeLine from 'assets/discover/icons/timeLine.png';
import religionBlack from 'assets/discover/icons/religion-black.svg';
import parent from 'assets/discover/icons/parent.png'
import person from 'assets/discover/icons/inPerson.png'
import phone from 'assets/discover/icons/phone.png'
import wineIcon from 'assets/profileEdit/diet/wineIcon.svg'
import smokingIcon from 'assets/profileEdit/diet/smokingIcon.svg'
import { requestContactInfo, viewContactInfo } from 'apis/contact';

const BlockAndReportPopUp = lazy(() => import("components/popUps").then(module => ({ default: module.BlockAndReportUser })))

const Index = ({ data, isPreview = true }) => {
    const navigate = useNavigate();
    const [showPopUp, setShowPopUp] = useState(false);
    const [type, setType] = useState('');


    const [contactInfo, setContactInfo] = useState(
        {
            enabled: data?.preferred_contacts?.family_contact?.enabled,
            status: data?.preferred_contacts?.family_contact?.status,
            requestId: data?.preferred_contacts?.family_contact?.requestId,
            request_type: data?.preferred_contacts?.family_contact?.request_type,
            contactNumber: false,
            relation: data?.family?.relationship,
        },
    );

    const content = [
        { title: 'Timeline', text: [{ img: timeLine, text: capitalize(data?.personal?.timeline) }] },
        { title: "Drinking & Smoking", text: [{ img: wineIcon, text: capitalize(data?.misc?.drinking) }, { img: smokingIcon, text: capitalize(data?.misc?.smoking) }] },
    ];

    if (data?.personal?.relationship_status === 'single') {
        content.push(
            { title: 'Religion & caste', text: [{ img: religionBlack, text: capitalize(data?.personal?.religion_id?.name) }, { img: false, text: capitalize(data?.personal?.caste_id?.name) }] },
            { title: "Family", text: [{ img: person, text: capitalize(data?.family?.parent_name) }, { img: parent, text: capitalize(data?.family?.relationship) }] },
        )

    }
    const handleOnSuccess = () => navigate(CONNECTIONS.FRIENDS);

    const togglePopUp = (actionType) => {
        setType(actionType);
        setShowPopUp(true);
    };

    const handleRequestContactInfo = async (type) => {
        const res = await requestContactInfo(data?.id, type);
        if (res?.success) {
            setContactInfo((prevContactInfo) => ({
                ...prevContactInfo,
                status: res.data?.status
            }));
        }
    };

    const handleViewContactInfo = async (id) => {
        const res = await viewContactInfo(id)
        setContactInfo((prevContactInfo) => ({
            ...prevContactInfo,
            contactNumber: prevContactInfo.requestId === res?.data?.request_id ? res?.data?.contact : prevContactInfo.contactNumber,
        }));
    }

    const classState = [
        { title: 'request', style: 'bg-gray-200 border text-subText' },
        { title: 'accepted', style: 'text-white bg-blue-500' },
        { title: 'requested', style: 'text-white bg-brandRed' },
        { title: 'rejected', style: 'text-white bg-black' },
        { title: 'expired', style: 'text-white bg-subText' },
    ]

    const getStyleByStatus = (status) => {
        const state = classState.find((state) => state.title === status);
        return state ? state.style : '';
    };

    const handleOnclickContactInfo = (status) => {
        if (status === 'request') {
            handleRequestContactInfo(contactInfo?.request_type)
        } else if (status === 'accepted') {
            if (contactInfo?.contactNumber === false) handleViewContactInfo(contactInfo?.requestId)
        }
    }

    return (
        <div className="w-full">
            {showPopUp && (
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75">
                    <Suspense fallback={<div>Loading...</div>}>
                        <BlockAndReportPopUp
                            closeBtn={() => setShowPopUp(false)}
                            onSuccess={handleOnSuccess}
                            data={data}
                            type={type}
                        />
                    </Suspense>
                </div>
            )}


            {content.map(
                (item, index) =>
                    item?.text[0]?.text && (
                        <div key={index} className="flex flex-col w-full gap-3 mb-4">
                            <p className="font-medium text-[20px] text-text_gray">{item?.title}</p>
                            <div className="flex gap-2">
                                {item?.text.map(
                                    (info, idx) =>
                                        info?.text && (
                                            <div className='w-fit h-[46px]  flex justify-center items-center border-2 border-border_color rounded-full gap-2 p-3 px-6'>
                                                {info?.img && <img src={info.img} alt="icon" />}
                                                <p className='font-normal text-black text-[16px]'>{info.text}</p>
                                            </div>
                                        )
                                )}
                            </div>
                        </div>
                    )
            )}

            {/* Request Contact Info */}
            {contactInfo?.enabled &&
                <div className="flex items-center my-4">
                    <div className="w-full">
                        <h2 className="font-medium text-lg md:text-[20px] text-text_gray mb-2">
                            Family Contact Mode
                        </h2>
                        <div className="flex items-center justify-between w-full">
                            <button className="flex items-center gap-2 px-4 py-2 text-gray-600 border border-gray-300 rounded-full">
                                <img src={phone} alt="phone" />
                                Personal
                            </button>

                            <button className={`flex ms-20 items-center px-6 py-2 font-semibold transition rounded-lg ${getStyleByStatus(contactInfo?.status)}`}
                                onClick={() => handleOnclickContactInfo(contactInfo?.status)}
                            >
                                {contactInfo?.status === 'accepted' && contactInfo?.contactNumber === false ? 'View' : capitalize(contactInfo?.status)}
                            </button>
                        </div>
                    </div>
                </div>
            }

            {isPreview && <GalleryDiscover data={data} isPreview={isPreview} />}

            <div className="flex flex-wrap gap-3 mt-4">
                <div className="flex flex-col w-full gap-3">
                    <p className="font-medium text-[20px] text-text_gray">Block and Report</p>
                    <div className="flex items-center cursor-pointer" onClick={() => togglePopUp(data?.blocked ? 'unBlock' : 'block')}>
                        <img src={data?.blocked ? unblock : block} alt="action" className="me-2" />
                        <h1>{data?.blocked ? 'Unblock' : 'Block'}</h1>
                    </div>

                    <div className="flex items-center cursor-pointer" onClick={() => togglePopUp('report')}>
                        <img src={report} alt="report" className="me-2" />
                        <h1>Report</h1>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Index;
