import { toast } from "react-toastify";

export const getTheApp = () => {
    // Get the user agent string
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    const iosAppLink = 'https://apps.apple.com/us/app/couple-squad/id6624305594';
    const androidAndWindowsAppLink = 'https://play.google.com/store/apps/details?id=com.couplesquad.app';

    // Check for iOS devices (iPhone, iPad, iPod) or macOS devices
    if (/iPad|iPhone|iPod/.test(userAgent) || /Macintosh|Mac OS X|Mac/.test(userAgent)) {
        window.location.href = iosAppLink;
    }
    // Check for Android or Windows devices
    else if (/android/i.test(userAgent) || /Windows NT/i.test(userAgent)) {
        window.location.href = androidAndWindowsAppLink;
    }
    else {
        toast.error("Something went wrong")
    }
};
