import * as Yup from "yup";


export const phoneValidation = Yup.object().shape({
    phone: Yup.number()
        .min(10, "Phone number should include minimum 10 digits")
        .test('is-number', 'Please enter valid phone number', (value) => !isNaN(value))
        .typeError('Please enter a valid phone number')
        .required("Phone number is required"),
})

export const signupValidation = Yup.object().shape({
    phone: Yup.number()
        .min(10, "Phone number should include minimum 10 digits")
        .test('is-number', 'Please enter valid phone number', (value) => !isNaN(value))
        .typeError('Please enter a valid phone number')
        .required("Phone number is required"),
    email: Yup.string().email("Enter a valid email").required("Email is required"),
    otp: Yup.number().min(6, 'OTP must be at least 6 digits long').required('OTP is required'),
    ref_code: Yup.string().trim().optional(),
})