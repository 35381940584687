import React from 'react'
import img from "assets/settings/img/celebration.png"
import verificationTickMark from "assets/settings/icons/verify.svg"
import { Link } from 'react-router-dom'
import { SETTINGS } from 'shared/router'

function Index({ closeBtn }) {



    return (
        <div>
            <div className="flex flex-col max-h-[70%] bg-white">
                <div className="flex flex-1 overflow-hidden bg-[#f9f9f9]">
                    <button onClick={closeBtn} className="absolute top-0 right-0 p-2 mt-2 text-xl leading-none text-black me-3" >&#x2715;</button>
                    <div className="flex items-center justify-center flex-1 my-10">
                        <div className="w-[550px] flex flex-col items-center justify-center bg-white text-black rounded-lg">

                            <div className="flex mt-5">
                                <h1 className='mb-1 text-4xl font-semibold me-2'>Get Verified</h1>
                                <img src={verificationTickMark} alt="" className='w-auto h-auto' />
                            </div>
                            <h1 className='text-[20px] text-subText'>Earn 2000 coins as Reward</h1>

                            <img src={img} alt="" className="mt-7" />

                            <div className="flex justify-around w-full px-10 mt-10 text-center">
                                <Link to={SETTINGS.SETTINGS} className='w-full px-5 py-2 text-white transition-colors bg-blue-500 rounded hover:bg-blue-700'>Get Verified</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default React.memo(Index)