import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { PROFILE_EDIT, SETTINGS } from '../../../shared/router';

// Assets

import settingActive from 'assets/settings/icons/settings/active.svg'
import settingInactive from 'assets/settings/icons/settings/inactive.svg'
import profilePrevActive from 'assets/settings/icons/profilePrev/active.svg'
import profilePrevInactive from 'assets/settings/icons/profilePrev/inactive.svg'
import profileEditActive from 'assets/settings/icons/profileEdit/active.svg'
import profileEditInactive from 'assets/settings/icons/profileEdit/inactive.svg'
import partnerPreferenceActive from 'assets/settings/icons/partnerPreference/active.svg'
import partnerPreferenceInactive from 'assets/settings/icons/partnerPreference/inactive.svg'
import privacySettingsActive from 'assets/settings/icons/privacySettings/active.svg'
import privacySettingsInactive from 'assets/settings/icons/privacySettings/inactive.svg'
import accountActive from 'assets/settings/icons/account/active.svg'
import accountInactive from 'assets/settings/icons/account/inactive.svg'
import walletActive from 'assets/settings/icons/wallet/active.svg'
import walletInactive from 'assets/settings/icons/wallet/inactive.svg'
import logoutActive from 'assets/settings/icons/logout/active.svg'
import logoutInactive from 'assets/settings/icons/logout/inactive.svg'

function Index() {
    const location = useLocation();

    const navigationItems = [
        { title: 'Settings', img: [settingActive, settingInactive], action: SETTINGS.SETTINGS },
        { title: 'Profile Preview', img: [profilePrevActive, profilePrevInactive], action: SETTINGS.PROFILE_PREVIEW },
        { title: 'Profile Edit', img: [profileEditActive, profileEditInactive], action: PROFILE_EDIT.BASIC },
        { title: 'Partner Preference', img: [partnerPreferenceActive, partnerPreferenceInactive], action: SETTINGS.PARTNER_PREFERENCE },
        { title: 'Privacy Settings', img: [privacySettingsActive, privacySettingsInactive], action: SETTINGS.PRIVACY_SETTINGS },
        { title: 'Account Settings', img: [accountActive, accountInactive], action: SETTINGS.ACCOUNT_SETTINGS },
        { title: 'Wallet', img: [walletActive, walletInactive], action: SETTINGS.WALLET },
        { title: 'Logout', img: [logoutActive, logoutInactive], action: SETTINGS.LOGOUT },
    ];

    return (
        <div className="flex flex-col items-center w-full lg:h-full md:h-screen">
            <div className="w-[80%] mx-auto">
                {navigationItems?.map((item, index) => (
                    <Link to={item?.action} className="flex items-center w-full my-10 cursor-pointer">
                        <img src={location.pathname === item?.action ? item?.img[0] : item?.img[1]} alt="" />
                        <p className={`ms-2 text-lg text-[${location.pathname === item?.action ? "#F83758" : "#5D6577"}]`}>{item?.title}</p>
                    </Link>
                ))}
            </div>
        </div>

    );
}

export default React.memo(Index);