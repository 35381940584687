import { noAuth } from '..';

export const sendOtpToPhone = async (data) => {
    const res = await noAuth({
        method: 'POST',
        url: '/auth/send-otp/phone?type=login',
        data,
    });

    return res;
};
export const sendOtpToEmail = async (data) => {
    const res = await noAuth({
        method: 'POST',
        url: '/auth/send-otp/email?type=login',
        data,
    });

    return res;
};

export const loginWithPhone = async (data) => {
    const res = await noAuth({
        method: 'POST',
        url: '/auth/login/phone',
        data,
    });

    return res;
}

export const loginWithEmail = async (data) => {
    const res = await noAuth({
        method: 'POST',
        url: '/auth/login/email',
        data,
    });

    return res;
}