export const informationWeCollect = [
    {
        title: '1.2  Profile Information',
        description: 'We recommend that you carefully consider the information you choose to share on your profile. Avoid including sensitive details such as full names, addresses, phone numbers, or financial information. Remember that the information you share on your profile is visible to other users and may be accessible to the public',
    },
    {
        title: '1.3 Profile Verification Information',
        description: 'To ensure the safety and security of our users, we may ask you to verify your profile using your phone number or a photo. This verification helps us prevent fraudulent accounts and enhances your experience on the platform. Verification photos are not displayed on your profile but are stored for verification purposes.',
    },
    {
        title: '1.4 Transaction Information',
        description: 'If you purchase a subscription or any paid services, we will process your payment information. We retain this information securely to prevent fraud and for audit and tax purposes. We may use automated decision-making to prevent fraudulent payment transactions.',
    },
    {
        title: '1.5 Geolocation Information',
        description: 'If you allow us to access your location in your device settings, we may collect information about your location, such as WiFi access points and your device’s coordinates. This information helps us provide location-based features and show you relevant matches and events nearby.',
    },
    {
        title: '1.6 Device and Photos Information',
        description: 'We may collect information about your device, including its unique identifier, model, and operating system. With your permission, the App may also access your device’s address book to add contacts. We may use data about your photos to enhance your profile by arranging them in a way that is most favourable to you. You can opt out of this feature through the app settings.',
    },
    {
        title: '1.7 Usage and Preference Information',
        description: 'We collect information about how you interact with the Service, including your preferences and settings. This includes browsing history, search terms, and interactions with our ads and content. We may also collect metadata and other information associated with files stored on your device, such as photos and videos.',
    },
    {
        title: '1.8 Cookies and Similar Technologies',
        description: 'When you visit our website or use the App, we may collect information automatically using cookies and similar technologies. Cookies are small files stored on your device that help us recognize and remember you. For more details on how we use cookies and how you can manage them, please refer to our Cookie Policy.',
    },
    {
        title: '1.9 Messages',
        description: 'We may review the content of messages sent through the App to identify trends, sentiments, and compliance with our Terms of Service. We take steps to remove personally identifiable information from such messages before reviewing them.',
    },
    {
        title: '1.10 Event Information',
        description: 'When you register for and participate in live meet-ups and events organised by Couple Squad, we may collect additional information such as your RSVP status, attendance, and any preferences you provide for the event. We may also collect information about your interactions during the event, including messages and connections made.',
    },
    {
        title: '1.11 Linking Other Accounts',
        description: 'You may choose to link your Couple Squad account with other social media accounts (e.g., Facebook, Instagram). By doing so, you allow us to access certain information from those accounts, such as your profile photos and interests. You can manage or remove these connections at any time through your account settings.',
    },
]

export const mainPoints = [
    {
        title: '4. Your Privacy Rights',
        description: 'Depending on your location, you may have certain privacy rights regarding your personal information, such as the right to access, correct, or delete your data. If you wish to exercise any of these rights, please contact us at [insert contact email]. We may need to verify your identity before processing your request.',
    },
    {
        title: '5. Data Security',
        description: 'We take appropriate security measures to protect your information from unauthorised access, loss, misuse, or alteration. However, please be aware that no method of transmission over the internet or electronic storage is 100% secure. We encourage you to take precautions to protect your account, such as using a strong password and logging out after each session.',
    },
    {
        title: '6. Data Retention',
        description: 'We retain your personal information only for as long as necessary to fulfil the purposes for which it was collected, or as required by law. If you delete your account, we will begin the process of deleting your data from our systems. However, some information may be retained for legal, security, or business purposes.',
    },
    {
        title: '6.1 Disputes and Data Storage',
        description: `If you choose to visit the CoupleSquad platform, your visit and any dispute over privacy is subject to this Privacy Policy and the website’s Terms of Service. Any disputes arising under this Policy shall be governed by the laws of India.
                    Please note that the CoupleSquad platform stores your data with the cloud platform of Amazon Web Services(AWS), which may store this data on their servers located outside of India.AWS has implemented security measures to protect against the loss, misuse, and alteration of your information.For more details about these security measures, please refer to AWS’s official documentation at <a class="text-blue-500 underline" href="https://aws.amazon.com/"> AWS Security </a>.
                    The privacy policy adopted by AWS, detailing how they handle and protect your information, is available at <a class="text-blue-500 underline" href="https://aws.amazon.com/privacy/?nc1=f_pr">AWS Privacy Policy </a>.
                    If you have any questions or concerns regarding the security measures adopted by AWS, you can directly contact their data protection/ privacy team, legal team, or designated grievance officer.Contact details for these teams can be found in AWS’s privacy policy.`,
    },
    {
        title: '7. Children’s Privacy',
        description: 'Couple Squad is not intended for use by anyone under the age of 18. We do not knowingly collect information from children under 18. If we become aware that we have collected information from a child under 18, we will take steps to delete such information.',
    },
    {
        title: '8. International Data Transfers',
        description: 'Couple Squad is a global platform, and your information may be transferred to and processed in countries outside your country of residence. We take steps to ensure that your data is protected according to this Policy and applicable laws.',
    },
    {
        title: '9. Changes to This Policy',
        description: 'We may update this Privacy Policy from time to time. The most current version will be posted on our website and App, and we will notify you of any significant changes. Your continued use of the Service after the changes take effect constitutes your acceptance of the updated Policy.',
    },
    {
        title: '10. Contact Us',
        description: `If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at:
                    <strong> Couple Squad Private Limited </strong>
                    Centerspace, No B 28, Anjanasree Arcade,Annankunnu
                    Road,Kottayam,Kerala,686601
                    info@couplesquad.com
                    Thank you for trusting the Couple Squad with your personal information. We are committed to protecting your privacy and providing you with a safe and enjoyable experience on our platform.
                    or can also write to our Grievance Officer at the address provided above. If you have any queries relating to the processing or usage of information provided by you in connection with this Policy or other privacy concerns, you can email us at hello@couplesquad.com.`,
    },
] 