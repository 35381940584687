import { auth } from '..';

export const getPrivacySettings = async () => {
    const res = await auth({
        method: "GET",
        url: `/users/settings/privacy`
    });
    return res
};

export const updatePrivacySettings = async (data) => {
    const res = await auth({
        method: "POST",
        url: "/users/settings/privacy",
        data
    });
    return res
};


export const fetchVerificationStatus = async () => {
    const res = await auth({
        method: "GET",
        url: "/verification/status",
    });
    return res
};

export const submitVerificationData = async (data) => {
    const options = {
        headers: {
            "Content-Type": "multipart/form-data"
        }
    }
    const res = await auth({
        method: "POST",
        url: "/verification/request",
        data,
        options
    });
    return res
};


export const sendOtpToEmail = async (data) => {
    const res = await auth({
        method: "POST",
        url: "/auth/send-otp/email?type=reset",
        data,
    });
    return res
};

export const sendOtpToPhone = async (data) => {
    const res = await auth({
        method: "POST",
        url: "/auth/send-otp/phone?type=reset",
        data,
    });
    return res
};

export const updateEmailId = async (data) => {
    const res = await auth({
        method: "PUT",
        url: "/users/change-email",
        data,
    });
    return res
};

export const updatePhoneNo = async (data) => {
    const res = await auth({
        method: "PUT",
        url: "/users/change-phone",
        data,
    });
    return res
};

export const userLogout = async (data) => {
    const res = await auth({
        method: "POST",
        url: "/users/logout",
        data,
    });
    return res
};

export const deleteAccount = async (data) => {
    const res = await auth({
        method: "POST",
        url: "/users/delete",
        data
    });
    return res
};

export const getUserProfilePreview = async () => {
    const res = await auth({
        method: "GET",
        url: "/users/profile/preview",
    });
    return res
};