import React, { memo, useState } from 'react';

import tick from '../../../assets/discover/tick.svg';
import law from '../../../assets/discover/law.svg';
import slide from '../../../assets/discover/slide.svg';
import { getInvitationLink } from '../../../apis/matches';
import { toast } from 'react-toastify';
import { LazyImage } from 'components/image';

// Assets
import religion from 'assets/discover/icons/religion-white.svg';
import { capitalize } from 'shared/textFormats';

function Index({ data, page, profilePreview = false }) {

    const [isInvitePopup, setIsInvitePopup] = useState(false)

    const handleInvite = async () => {
        const id = page === 'friendsProfilePrev' ? data?.id : data?.matchId
        const res = await getInvitationLink(id)
        if (res.success) {
            toast.success('Link copied to clipboard')
            await navigator.clipboard.writeText(res.data.link);
            setIsInvitePopup(false)
        } else {
            toast.error('Failed to get link. Please try again later.');
        }
    }

    return (
        <div className='relative w-full h-full'>
            <div className='relative w-full h-full'>
                <LazyImage
                    src={data?.photo}
                    alt='ProfileImage'
                    className="h-[510px] w-[360px]"
                />
                <div className={`absolute ${isInvitePopup ? 'bottom-[3.90rem]' : 'bottom-0'} left-0 w-full h-[140px] bg-black bg-opacity-75 backdrop-blur-sm text-white flex flex-row justify-between items-start gap-7 p-4`} >
                    {/* details */}
                    <div className='flex items-center justify-between w-full'>
                        <div className='flex flex-col'>
                            <div className='flex items-center gap-3 mt-2'>
                                <p className='text-xl font-bold'>{data?.name},</p>
                                <p className='text-xl font-bold'>{data?.personal?.age}</p>
                                {data?.verification?.status === "verified" &&
                                    <img src={tick} alt='Verified' className='w-6 h-6' />
                                }
                            </div>
                            <p className='text-lg font-medium'>
                                {data?.address?.state &&
                                    <>
                                        {capitalize(data?.address?.state)}
                                        ,
                                    </>
                                }
                                {capitalize(data?.address?.country)}
                                {parseInt(data?.distance) > 0 && (
                                    <>
                                        <span className='text-3xl font-medium'> .</span>
                                        <span> {parseInt(data?.distance)} km away</span>
                                    </>
                                )}
                            </p>
                        </div>
                        {!profilePreview &&
                            <img src={slide} alt='More Info' className={`rounded-3xl mt-7 self-center cursor-pointer transition-transform transform ${isInvitePopup ? 'rotate-90' : 'rotate-0'}`} onClick={() => setIsInvitePopup(!isInvitePopup)} />
                        }
                    </div>
                </div>
                {!profilePreview && isInvitePopup &&
                    <div className='absolute bottom-0 left-0 w-full h-[64px]  bg-black bg-opacity-75 backdrop-blur-sm text-white flex items-center p-4 justify-between'>
                        <p className='ms-2 text-[12px] flex-grow'>Share this profile to see if your friends or family approve</p>
                        <button className='bg-white text-black p-2 w-[80px] text-center' onClick={handleInvite}>Invite</button>
                    </div>
                }
            </div>
        </div>
    );
}

export default memo(Index);
