import React from 'react'
import { Slider } from 'antd';

function Index({ minMaxRange, selectedMinMaxValue, onRangeChange, }) {

    const sliderStyle = {
        trackStyle: {
            backgroundColor: 'black',
            dotBorderColor: 'black'
        }
    };

    return (
        <div>
            <Slider
                range
                value={selectedMinMaxValue}
                min={minMaxRange[0]}
                max={minMaxRange[1]}
                onChange={onRangeChange}
                trackStyle={sliderStyle.trackStyle}
                handleStyle={[sliderStyle.handleStyle, sliderStyle.trackStyle]}
            />
        </div>
    )
}

export default React.memo(Index)