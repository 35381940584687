import React, { useState, useEffect, lazy, Suspense } from 'react'
import 'react-toastify/dist/ReactToastify.css';

import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { getMutualConnectionProfilePreview, getQrConnectionProfilePreview, skipTheUser, typeBasedConnectionRequest } from 'apis/matches';
import { decodeFromBase64 } from 'utils/encodeDecode';

import { UserBasicDetails, UserBasicDetailsResponsive, UserImgPreview } from 'components/discover';
import { ConnectionActions } from 'components/discover';
import { toast } from 'react-toastify';
import { MAIN_NAV, WALLET } from 'shared/router';


const LocationError = lazy(() => import("components/popUps").then(module => ({ default: module.LocationTurnoff })))
const LimitReached = lazy(() => import("components/popUps").then(module => ({ default: module.LimitReached })))
const InsufficientCoins = lazy(() => import("components/popUps").then(module => ({ default: module.InsufficientCoins })))
const NoAccess = lazy(() => import("components/popUps").then(module => ({ default: module.NodataAccess })))
const SomethingWentWrong = lazy(() => import("components/popUps").then(module => ({ default: module.SomethingWentWrong })))
// Assets

function Index() {
    const { id } = useParams();
    const navigate = useNavigate()
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);


    const [userData, setUserData] = useState()
    const [errorType, setErrorType] = useState(null)
    const [phoneNo, setPhoneNo] = useState(false)

    const fetchFriendsProfilePreview = async (phone = false) => {
        let res
        if (phone) {
            res = await getMutualConnectionProfilePreview(decodeFromBase64(id), phone)
        } else {
            res = await getQrConnectionProfilePreview(decodeFromBase64(id))
        }
        if (res?.data) {
            setUserData(res?.data)
        }
    }

    useEffect(() => {
        const phone = queryParams.get('phone');
        if (phone) setPhoneNo(phone)
        fetchFriendsProfilePreview(phone ? phone : false)
    }, [])

    // Send Connection Request

    const handleConnect = async () => {
        const res = await typeBasedConnectionRequest(userData?.matchId, phoneNo ? 'mutual_contacts' : 'qr')
        if (res?.success) {
            toast.success('Request sended')
            phoneNo ? navigate(MAIN_NAV.DISCOVER) : navigate(WALLET.WALLET)
        }
    }

    const handleSkip = async () => {
        const res = await skipTheUser(userData?.matchId)
        if (res?.success) {
            toast.success('Skipped the user')
            phoneNo ? navigate(MAIN_NAV.DISCOVER) : navigate(WALLET.WALLET)
        }
    }



    const ErrorComponent = errorType === 'Please update your location to continue' ? LocationError
        : (errorType === 'DAILY_CONNECTION_LIMIT' ? LimitReached
            : (errorType === 'INSUFFICIENT_COINS' ? InsufficientCoins
                : (errorType === 'NO_ACCESS' ? NoAccess
                    : SomethingWentWrong)))



    return (
        <div className="flex flex-col h-full min-h-screen bg-white">

            {errorType && (
                <div className="fixed top-0 bottom-0 left-0 right-0 z-50 flex items-center justify-center w-full bg-black bg-opacity-75">
                    <Suspense fallback={<div>Loading...</div>}>
                        <ErrorComponent closeBtn={() => setErrorType(null)} />
                    </Suspense>
                </div>
            )}

            <div className='flex items-center justify-center flex-grow w-full bg-white'>

                <div className='flex flex-wrap items-start justify-center w-full gap-0 md:gap-x-10 '>
                    {/* ImgPreview Component */}
                    <div className="flex flex-col items-center">
                        <div className="h-[510px] w-full md:w-[360px] mx-auto flex items-center justify-center">
                            <UserImgPreview data={userData} />
                        </div>
                        <div className="flex items-center justify-center mt-4 gap-9">
                            <ConnectionActions
                                handleSkip={handleSkip}
                                handleConnect={handleConnect}
                            />
                        </div>
                    </div>


                    <div className="hidden md:block h-[580px] w-[60%] overflow-y-auto">
                        <UserBasicDetails data={userData} />
                    </div>
                    <div className="block mt-4 overflow-y-auto md:hidden">
                        <UserBasicDetailsResponsive data={userData} />
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Index