import React, { useEffect, useState } from "react";
import { capitalize } from "../../../shared/textFormats";
import { formatDateForCarousel, formatDateToDDMMYYYY } from "../../../shared/dates";
import { Link } from "react-router-dom";

function Index({ assets }) {
    const [currentIndex, setCurrentIndex] = useState(0);
    const filteredData = assets.filter(item => Object.keys(item).length !== 0);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentIndex(currentIndex => (currentIndex + 1) % filteredData.length);
        }, 5000);

        return () => clearInterval(intervalId);
    }, [filteredData.length]);

    return (
        <div className="carousel w-full h-[360px] overflow-hidden">
            {filteredData.map((item, index) => (
                <Link to={`/event/details/${item.slug}`}
                    id={`item${index}`}
                    className={`carousel-item w-full h-full cursor-pointer relative ${index === currentIndex ? '' : 'hidden'}`}
                    key={index}
                >
                    <div className="relative w-full h-full">
                        <img src={item?.image} className="w-full h-full object-cover" alt={item?.title} />
                        <div className="absolute inset-0 bg-gradient-to-t from-black to-transparent"></div>
                    </div>

                    <div className="absolute top-0 left-0 right-0 bottom-0 flex flex-col justify-center items-center text-center text-white">
                        <div className='max-w-[630px] mt-14'>
                            <p className="text-xl sm:text-2xl md:text-3xl lg:text-4xl xl:text-5xl p-2" title={capitalize(item?.title)}>{capitalize(item?.title)}</p>
                            <p className="text-lg sm:text-xl md:text-2xl p-2">{formatDateForCarousel({ start_date: item?.start_date, end_date: item?.end_date })}</p>
                        </div>
                    </div>
                </Link>
            ))}

        </div>
    );
}

export default React.memo(Index)
