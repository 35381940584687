
import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const LazyImage = ({ src, alt = 'CoupleSquad', className, ...props }) => {
    return (
        <LazyLoadImage
            alt={alt}
            src={src}
            loading='lazy'
            effect="blur"
            className={className}
            {...props}
        />
    );
};

export default LazyImage;
